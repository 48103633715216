import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { ReactFormGenerator, ElementStore } from '../FormBuilderLibrary';
import {
	setCopy,
	setFormSchema,
	setFormHasChanged,
	setFormMetaInfo,
	setFormReadOnly
} from '../FormBuilderLibrary/slice';
import {
	useCreateFormMutation,
	usePostFormSchemaMutation,
	useUpdateFormMutation
} from '../../services/endpoints/formBuilderEndpoints';
import {
	checkForEmptyForms,
	checkMissingGroupings,
	capitalizeFirstLetter,
	calculateTotalPoints
} from './utils';
import { getLocalAuth } from '../../utils/environmentUtils';
import { useBuilderPreviewButton } from '../../hooks/useBuilderPreviewButton';

import { Box, Modal, IconButton, Button, Alert, Collapse } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
	FORM_EDIT_PUBLISHED_WARNING_MESSAGE,
	FORM_EDIT_READY_TO_PUBLISH_WARNING_MESSAGE,
	FORM_EDIT_PUBLISHED_WARNING_TITLE,
	FORM_EDIT_READY_TO_PUBLISH_WARNING_TITLE,
	FORM_EDIT_WARNING_LIST,
	FORM_EDIT_WARNING_CONFIRMATION_MESSAGE
} from '../../constants/appProcessEditWarningMessage';
import WarningModal from '../WarningModal/WarningModal';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import PasteIcon from '../../assets/customIcons/pasteIcon';
import LastEdited from '../LastEdited/LastEdited';

const Demobar = (props) => {
	const dispatch = useDispatch();
	const _ = require('lodash');
	const [searchParams, setSearchParams] = useSearchParams();

	const [postForm, { isLoading }] = usePostFormSchemaMutation();
	const [createForm] = useCreateFormMutation();
	const [updateForm] = useUpdateFormMutation();

	const { button: PreviewButton } = useBuilderPreviewButton();

	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const reduxFormCopy = useSelector((state) => state?.form?.copy);
	const entireFormSchema = useSelector((state) => state.form.formSchema);
	const formMetaInfo = useSelector((state) => state.form.formMetaInfo);
	const formSchema = useSelector((state) => state.form.formSchema[props.formID]);
	const formIsDraft = useSelector((state) => state.form.hasChanged);
	const isReadOnly = useSelector((state) => state.form.isReadOnly);
	const [alertBalloon, setAlertBalloon] = useState(false);
	const [data, setData] = useState(props.data);
	const [previewVisible, setPreviewVisible] = useState();
	const [shortPreviewVisible, setShortPreviewVisible] = useState();
	const [roPreviewVisible, setRoPreviewVisible] = useState();
	const [warningModal, setWarningModal] = useState(false);

	const hasEditPermissions = props?.hasEditPermissions;
	const isReadyToPublish = props?.isReadyToPublish;
	const warningModalTitle =
		props.programStatus === 'Published'
			? FORM_EDIT_PUBLISHED_WARNING_TITLE
			: FORM_EDIT_READY_TO_PUBLISH_WARNING_TITLE;
	const warningModalContent =
		props.programStatus === 'Published'
			? FORM_EDIT_PUBLISHED_WARNING_MESSAGE
			: FORM_EDIT_READY_TO_PUBLISH_WARNING_MESSAGE;
	ElementStore.subscribe(() => setData(data));

	useEffect(() => {
		setData(props.data);
	}, [props.data, data]);

	const created = searchParams.get('created') || false;

	useEffect(() => {
		if (created) {
			const timeoutId = setTimeout(() => {
				searchParams.delete('created');
				setSearchParams(new URLSearchParams(searchParams));
			}, 3000);

			// Cleanup the timeout to avoid potential memory leaks
			return () => clearTimeout(timeoutId);
		}
	}, [created]);

	const closePreview = () => {
		setPreviewVisible(false);
		setShortPreviewVisible(false);
		setRoPreviewVisible(false);
	};
	const handleAlert = (message, status = 'error') => {
		setAlertBalloon({ isOpen: true, message, status });
		setTimeout(() => {
			setAlertBalloon((prevAlertBalloon) => ({
				...prevAlertBalloon,
				isOpen: false
			}));
		}, 3000);
	};

	const postFormSchema = async (formId) => {
		const noNullForms = entireFormSchema.filter((e) => e !== null && e !== undefined);

		let finalFormObject = {
			multistep_field: noNullForms
		};

		let options = {
			id: formId,
			payload: finalFormObject?.multistep_field?.[0],
			apiToken: apiToken
		};

		if (noNullForms.length > 0) {
			postForm(options)
				.unwrap()
				.then(() => {
					'';
				})
				.catch(() => '');
		}
	};

	const handleEdit = () => {
		if (isReadyToPublish) {
			setWarningModal(true);
		} else {
			dispatch(setFormReadOnly(false));
		}
	};

	const handleConfirmWarning = () => {
		dispatch(setFormReadOnly(false));
		setWarningModal(false);
	};

	const handleCloseWarningModal = () => {
		setWarningModal(false);
	};

	const handleSave = async () => {
		const validateContentLength = (entireFormSchema) => {
			return entireFormSchema[0].some((item) => item.content && item.content.length > 8008);
		};
		const noNullForms = entireFormSchema.filter((e) => e !== null && e !== undefined);
		const hasExceedContentLength = validateContentLength(entireFormSchema);
		const formId = searchParams.get('formId');
		const totalPoints = calculateTotalPoints(noNullForms[0]);
		let createFormOptions = {
			payload: {
				...formMetaInfo,
				order: props?.totalForms + 1,
				totalPoints: searchParams.get('formBuilder') === 'review' ? totalPoints : null,
				form_type: searchParams.get('formBuilder') === 'application' ? 'application' : 'review'
			},

			apiToken: apiToken
		};

		let updateFormOptions = {
			payload: {
				...formMetaInfo,
				stage: formMetaInfo?.stage?.stage_id,
				program: formMetaInfo?.program?.id && {
					programId: formMetaInfo?.program?.id
				},
				totalPoints: searchParams.get('formBuilder') === 'review' ? totalPoints : null,
				form_type: searchParams.get('formBuilder') === 'application' ? 'application' : 'review'
			},

			apiToken: apiToken,
			id: formId
		};

		const hasMissingGrouping = checkMissingGroupings(noNullForms);
		if (hasMissingGrouping) {
			handleAlert('Groupings must have a start and close element.');
			return;
		} else if (hasExceedContentLength) {
			handleAlert('Content field cannot exceed 8000 characters.');
			return;
		} else if (noNullForms?.length === 0) {
			handleAlert('Forms must not be empty.');
			return;
		}

		if (props?.isTemplate) {
			createFormOptions.payload.program = { programId: -1 };
			createFormOptions.payload.is_template = true;
		}
		if (searchParams.get('isAdHoc')) {
			createFormOptions.payload.is_adhoc = true;
		}
		if (!formId || formId === 'undefined') {
			formMetaInfo &&
				createForm(createFormOptions)
					.unwrap()
					.then(async (res) => {
						dispatch(setFormMetaInfo(res));
						searchParams.set('formId', res?.form_id);
						searchParams.set('created', true);
						setSearchParams(searchParams);
						await postFormSchema(res?.form_id);
						props?.setMetaInfoChanged && props?.setMetaInfoChanged(false);
						dispatch(setFormReadOnly(true));
					})
					.catch(() => '');
		} else {
			await updateForm(updateFormOptions)
				.unwrap()
				.then(async (res) => {
					dispatch(setFormHasChanged(false));
					searchParams.set('formId', res?.form_id);
					setSearchParams(searchParams);
					props?.setMetaInfoChanged && props?.setMetaInfoChanged(false);
					handleAlert('Form was successfully updated', 'success');
					await postFormSchema(res?.form_id);
					dispatch(setFormReadOnly(true));
				})
				.catch(() => '');
		}
	};

	const cloneCopiedElement = (element) => {
		let clonedCopy = _.cloneDeep(element);
		delete clonedCopy.parent_id;
		delete clonedCopy.parent_index;
		delete clonedCopy.component;
		ElementStore.dispatch('create', clonedCopy);
		return clonedCopy;
	};

	const handlePasteClick = () => {
		let response = formSchema ? Object.values(formSchema) : [];
		let clonedResponse = _.cloneDeep(response);
		const finalElements = Array.isArray(reduxFormCopy[0])
			? reduxFormCopy[0]?.map((e) => cloneCopiedElement(e))
			: [cloneCopiedElement(reduxFormCopy[0])];

		dispatch(setFormSchema([props.formID, [...clonedResponse, ...finalElements]]));
		dispatch(setCopy(null));
		props.forceRefresh();
	};

	const handleSaveClick = () => {
		const noNullForms = entireFormSchema.filter((e) => e !== null);
		let hasEmptyForms = checkForEmptyForms(noNullForms);
		const hasMissingGrouping = checkMissingGroupings(noNullForms);

		const noLabel = !formMetaInfo?.label || formMetaInfo?.label === '';
		const noDescription = searchParams.get('stageId')
			? false
			: !formMetaInfo?.description || formMetaInfo?.description === '';
		const noInstructions = !formMetaInfo?.instructions || formMetaInfo?.instructions === '';
		if (noLabel || noDescription || noInstructions) {
			const form = document.getElementsByClassName('meta-info-form')[0];

			form.click();
		}

		!hasEmptyForms && !hasMissingGrouping
			? handleSave()
			: hasMissingGrouping
			? handleAlert('Groupings must have a start and close element.')
			: handleAlert('Forms must not be empty.');
	};

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		bgcolor: 'background.paper',
		border: '1px solid gray',
		borderRadius: '10px',
		boxShadow: 24,
		p: 4,
		width: '90vw',
		height: '90vh',
		overflow: 'scroll'
	};

	let shortModalClass = 'modal short-modal';
	if (shortPreviewVisible) {
		shortModalClass += ' show d-block';
	}

	let roModalClass = 'modal ro-modal';
	if (roPreviewVisible) {
		roModalClass += ' show d-block';
	}

	let innerPasteIcon = document?.getElementById('inner-form-paste-icon');
	if (reduxFormCopy?.length > 0 && innerPasteIcon) {
		innerPasteIcon.style.display = 'block';
	} else if (!reduxFormCopy?.length && innerPasteIcon) {
		let innerPasteIcon = document?.getElementById('inner-form-paste-icon');
		innerPasteIcon.style.display = 'none';
	}

	return (
		<>
			<div
				style={{
					margin: '-15px 0px 10px 0px ',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between'
				}}
			>
				<LastEdited
					data={{
						...formMetaInfo?.updated_by_user,
						time: formMetaInfo?.last_updated_on
					}}
				/>

				<div
					style={{
						width: 'fit-content',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end',
						gap: '15px'
					}}
				>
					{reduxFormCopy?.length > 0 && hasEditPermissions && (
						<IconButton
							id="formBuilder-paste-button"
							style={{ visibility: 'hidden' }}
							onClick={handlePasteClick}
							aria-label="paste"
						>
							<PasteIcon />
						</IconButton>
					)}

					{isReadOnly && hasEditPermissions ? (
						<Button
							data-testid={'formBuilder-editButton'}
							variant="outlined"
							onClick={handleEdit}
							startIcon={<EditIcon />}
						>
							Edit
						</Button>
					) : (
						hasEditPermissions && (
							<>
								<LoadingButton
									data-testid={'formBuilder-saveForm'}
									disabled={props?.is_archived}
									loading={isLoading}
									variant="contained"
									onClick={handleSaveClick}
								>
									Save
								</LoadingButton>
								<Button variant="outlined" onClick={() => dispatch(setFormReadOnly(true))}>
									Cancel
								</Button>
							</>
						)
					)}

					{!props?.hidePreviewButton && PreviewButton}

					{isReadyToPublish && (
						<WarningModal
							title={warningModalTitle}
							content={warningModalContent}
							listItems={FORM_EDIT_WARNING_LIST}
							confirmationMessage={FORM_EDIT_WARNING_CONFIRMATION_MESSAGE}
							open={warningModal}
							handleClose={handleCloseWarningModal}
							handleConfirm={handleConfirmWarning}
						/>
					)}
					{previewVisible && (
						<Modal
							open={previewVisible}
							aria-labelledby="modal-modal-title"
							aria-describedby="modal-modal-description"
						>
							<Box sx={style}>
								<ReactFormGenerator
									download_path=""
									back_action={''}
									back_name="Back"
									answer_data={{}}
									action_name="Save"
									form_action="/"
									form_method="POST"
									variables={props.variables}
									data={data}
								/>

								<div className="modal-footer">
									<button
										type="button"
										className="btn btn-default"
										data-dismiss="modal"
										onClick={() => {
											setPreviewVisible(false);
										}}
									>
										Close
									</button>
								</div>
							</Box>
						</Modal>
					)}

					{roPreviewVisible && (
						<div className={roModalClass}>
							<div className="modal-dialog modal-lg">
								<div className="modal-content">
									<ReactFormGenerator
										download_path=""
										back_action="/"
										back_name="Back"
										answer_data={{}}
										action_name="Save"
										form_action="/"
										form_method="POST"
										read_only={true}
										variables={props.variables}
										hide_actions={true}
										data={data}
									/>

									<div className="modal-footer">
										<button
											type="button"
											className="btn btn-default"
											data-dismiss="modal"
											onClick={closePreview.bind(this)}
										>
											Close
										</button>
									</div>
								</div>
							</div>
						</div>
					)}

					{shortPreviewVisible && (
						<div className={shortModalClass}>
							<div className="modal-dialog modal-lg">
								<div className="modal-content">
									<ReactFormGenerator
										download_path=""
										back_action=""
										answer_data={{}}
										form_action="/"
										form_method="POST"
										data={data}
										display_short={true}
										variables={props.variables}
										hide_actions={false}
									/>

									<div className="modal-footer">
										<button
											type="button"
											className="btn btn-default"
											data-dismiss="modal"
											onClick={closePreview.bind(this)}
										>
											Close
										</button>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>

			<Collapse in={created}>
				<Alert
					severity={'success'}
					action={
						<IconButton
							aria-label="close"
							color={'success'}
							size="small"
							onClick={() => {
								setAlertBalloon(false);
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
					sx={{ mb: 2 }}
				>
					{formMetaInfo.form_type &&
						`${capitalizeFirstLetter(formMetaInfo.form_type)} form  ${
							formMetaInfo?.is_template == true ? 'template' : ''
						} was successfully created.`}{' '}
					{/* {`The ${formMetaInfo.form_type} form ${
						formMetaInfo.form_type == 'application' ? 'afm' : 'efm'
					}-${formMetaInfo.unique_identifier} was successfully created`} */}
				</Alert>
			</Collapse>

			<Collapse in={alertBalloon.isOpen}>
				<Alert
					severity={alertBalloon.status}
					action={
						<IconButton
							aria-label="close"
							color={alertBalloon.status}
							size="small"
							onClick={() => {
								setAlertBalloon((prevAlertBalloon) => ({
									...prevAlertBalloon,
									isOpen: false
								}));
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
					sx={{ mb: 2 }}
				>
					{alertBalloon.status == 'error'
						? alertBalloon?.message
						: formMetaInfo.form_type &&
						  `${capitalizeFirstLetter(formMetaInfo.form_type)} form ${
								formMetaInfo?.is_template == true ? 'template' : ''
						  } was successfully saved.`}
				</Alert>
			</Collapse>
		</>
	);
};

export default Demobar;

import { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import StyledWrapper from './style';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import ProjectTeam from './subPages/ProjectTeam';
import ApplicationOverview from './subPages/ApplicationOverview';
import ApplicationFormContent from './subPages/ApplicationFormContent';
import SubmissionStageOverview from './subPages/SubmissionStageOverview';

import ReviewDetails from '../IndividualReview/subPages/ReviewDetails';
import ReviewFormContainer from '../IndividualReview/subPages/ReviewFormContainer';
import ReviewStageOverview from '../IndividualReview/subPages/ReviewStageOverview';

import { TabPanel } from '../../components/TabPanel';
import VerticalNavigationNE from '../../components/VerticalNavigationNewExperience/VerticalNavigationNE';
import NoApplicantProtection from '../../components/ProtectedRoute/NoApplicantProtection';

import { useGetAppProcessNav } from '../../hooks/useGetAppProcessNav';
import { getLocalAuth } from '../../utils/environmentUtils';
import { useUpdateFormStatusMutation } from '../../services/endpoints/formBuilderEndpoints';
import { VerticalNavigation } from '../../components/VerticalNavigation';

const IndividualApplication = ({ appId }) => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const location = useLocation();
	let { programID } = useParams();
	programID = programID?.replace('p-', '');
	const userAccountTypeID = useSelector((state) => state.login?.user?.userAccountTypeID);
	const isReviewer = useSelector((state) => state.login?.user?.isReviewer);
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;

	const [value, setValue] = useState(0);
	const [chosenView, setChosenView] = useState(<></>);
	const [alertBalloon, setAlertBalloon] = useState(false);
	const [subTitle, setSubTitle] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [initNav, setInitNav] = useState(false);

	const [updateFormStatus] = useUpdateFormStatusMutation();

	const {
		data: navData,
		projectTitle,
		isLoading,
		activeStageComplete: isSubmittable,
		activeStageCompletedForms: completedFormIds,
		refetch,
		isFetching,
		isNewExperience,
		allStagesSubmitted
	} = useGetAppProcessNav(appId);

	const pathArray = location?.pathname?.split('/');
	const baseURL = `/programs/p-${programID}/applications`;
	const mainPath = baseURL || pathArray?.slice(0, -1);

	const innerTabs = ['compare-reviews', 'layout', 'details', 'overview', 'content'];
	const currentPage = pathArray[pathArray.length - 1];
	let subPage = innerTabs.includes(currentPage) ? pathArray[pathArray.length - 2] : currentPage;

	const url = `${`/programs/p-${programID}/applications`}/${appId}/project%20team?${searchParams.toString()}
		${searchParams?.get('formType') === 'application' ? '&formtype=application' : ''}
		${searchParams?.get('iFrame') === 'true' ? '&iFrame=true' : ''}
	`;

	const handleAlert = (message, status) => {
		setAlertBalloon({ isOpen: true, message, status });
		setTimeout(() => {
			setAlertBalloon(false);
		}, 2000);
	};

	const overviewPageSelect = useCallback(() => {
		if (subPage?.includes('esi-')) {
			return userAccountTypeID ? (
				<ReviewStageOverview appId={appId} subPage={subPage} handleAlert={handleAlert} />
			) : (
				<NoApplicantProtection />
			);
		}
		if (subPage?.includes('rfi-')) {
			return isReviewer || userAccountTypeID ? (
				<ReviewDetails formID={subPage} navData={navData} />
			) : (
				<NoApplicantProtection />
			);
		}
		if (subPage?.includes('afi-')) {
			return <ApplicationFormContent formID={subPage} isNewExperience={isNewExperience} />;
		}
		if (subPage?.includes('rfm-')) {
			return userAccountTypeID ? (
				<ReviewFormContainer appId={appId} handleAlert={handleAlert} />
			) : (
				<NoApplicantProtection />
			);
		}
		if (subPage?.includes('ssi-')) {
			return (
				<SubmissionStageOverview
					appId={appId}
					subPage={subPage}
					handleAlert={handleAlert}
					isNewExperience={isNewExperience}
				/>
			);
		}
		if (subPage?.includes('api-')) {
			return <ApplicationOverview appId={appId} handleAlert={handleAlert} />;
		}
		if (subPage?.includes('project%20team')) {
			return <ProjectTeam isNewExperience={isNewExperience} />;
		} else {
			return <></>;
		}
	}, [appId, isReviewer, navData, subPage, userAccountTypeID]);

	const selectView = useCallback(
		(view) => {
			switch (view) {
				case 0:
					return overviewPageSelect();
				case 1:
					if (appId?.includes('arr-') || appId?.includes('rvf-')) {
						return <ReviewDetails />;
					} else {
						return <ApplicationFormContent />;
					}
				default:
					return <></>;
			}
		},
		[appId, overviewPageSelect]
	);

	useEffect(() => {
		if (!initNav && isNewExperience) {
			setInitNav(true);
			navigate(url, { replace: true });
		}
		setChosenView(selectView(value));
	}, [value, location, selectView]);

	const handleNavigation = (page) => {
		if (page?.includes('http')) {
			window.open(page, '_blank');
		} else {
			navigate(`${baseURL || mainPath?.join('/')}/${page.toLowerCase()}${location?.search}`);
		}
	};

	useEffect(() => {
		// trying to figure out how to show spinner only
		// after submitting to prevent spinner showing when
		// forms are submitted.
		if (!isFetching && isSubmitting) {
			setIsSubmitting(false);
		}
	}, [isFetching]);

	const handleSubmitApplication = () => {
		setIsSubmitting(true);
		let promises = completedFormIds.map((formId) => {
			const options = {
				id: formId,
				status: 'Completed',
				apiToken: apiToken
			};
			return updateFormStatus(options).unwrap();
		});

		Promise.all(promises)
			.then(async () => {
				refetch();
				handleAlert('Stage Submitted Successfully!', 'success');
			})
			.catch(() => {
				refetch();
				handleAlert('Stage Submission Failed!', 'error');
			});
	};

	return (
		<StyledWrapper.OutermostContainer id={'IndividualApplication-outermostContainer'}>
			{!isLoading && (
				<StyledWrapper.ApplicationTitle data-testid={`application-title`}>
					{projectTitle && `${projectTitle} Application [${appId}]`}
				</StyledWrapper.ApplicationTitle>
			)}

			<StyledWrapper.InnerContainer id={'IndividualApplication-innercontainer'}>
				{(isLoading || isSubmitting) && (
					<Stack justifyContent="center" alignItems="center" sx={{ width: 1, height: 1 }}>
						<CircularProgress />
					</Stack>
				)}
				{!isLoading && !isSubmitting && isNewExperience && navData && (
					<StyledWrapper.NavigationContainer id={'IndividualApplication-navigationcontainer'}>
						<VerticalNavigationNE
							customInnerWidth={'290px'}
							data={navData}
							navigateTo={handleNavigation}
							setSubTitle={setSubTitle}
						/>
						<Divider sx={{ marginY: '17px' }} />
						<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
							<StyledWrapper.SubmitApplicationButton
								loading={isSubmitting}
								variant="contained"
								disabled={allStagesSubmitted || !isSubmittable}
								onClick={handleSubmitApplication}
							>
								Submit Application
							</StyledWrapper.SubmitApplicationButton>
							{!allStagesSubmitted && !isSubmittable && (
								<Alert
									variant="outlined"
									severity="info"
									sx={{ width: '85%', marginX: 'auto', marginY: '17px', wordBreak: 'normal' }}
								>
									All forms must be completed in order to submit the application.
								</Alert>
							)}
						</div>
					</StyledWrapper.NavigationContainer>
				)}
				{!isLoading && !isNewExperience && navData && (
					<VerticalNavigation
						customInnerWidth={'290px'}
						data={navData}
						baseURL={`/programs/p-${programID}/applications`}
					/>
				)}
				{!isLoading && !isSubmitting && (
					<StyledWrapper.FormContainer>
						<Box sx={{ width: '100%' }}>
							<StyledWrapper.ApplicationTitle data-testid={`application-title`}>
								{subTitle}
							</StyledWrapper.ApplicationTitle>
							<Collapse in={alertBalloon}>
								<Alert
									severity={alertBalloon?.status}
									action={
										<IconButton
											aria-label="close"
											color={alertBalloon?.status}
											size="small"
											onClick={() => {
												setAlertBalloon(false);
											}}
										>
											<CloseIcon fontSize="inherit" />
										</IconButton>
									}
									sx={{ mb: 2, mt: 2 }}
								>
									{alertBalloon?.message}
								</Alert>
							</Collapse>
							<TabPanel data-testid={`individualReview-tab-panel`} value={0} index={0}>
								{chosenView}
							</TabPanel>
						</Box>
					</StyledWrapper.FormContainer>
				)}
			</StyledWrapper.InnerContainer>
		</StyledWrapper.OutermostContainer>
	);
};

export default IndividualApplication;

import { useState, useEffect } from 'react';

import StyledWrapper from './style';

import { getTimeRemaining, twoDatesPercentage } from '../../../utils/dateUtils';

const ProgressBar = ({ data, testTitle }) => {
	const [progressPercentage, setProgressPercentage] = useState(100);
	const [lowRemainingTime, setLowRemainingTime] = useState(30);
	const [timeRemaining, setTimeRemaining] = useState('');

	const getDatesPercentage = () => {
		let startDate = data?.startDate;
		let endDate = data?.endDate;

		const percentage = twoDatesPercentage(endDate, startDate);
		setProgressPercentage(percentage);
	};

	const getCountdownPercentage = () => {
		let endDate = new Date(data?.endDate).valueOf();
		const { days, timeRemaining } = getTimeRemaining(endDate);

		days < 20 && setLowRemainingTime(15);
		days < 10 && setLowRemainingTime(5);
		setTimeRemaining(timeRemaining);
	};

	useEffect(() => {
		if (data?.startDate && data?.endDate) {
			data?.isCountdown && getCountdownPercentage();
			getDatesPercentage();
		}
	}, [data]);

	return (
		<StyledWrapper.OutermostContainer data-testid={`progressBar-${testTitle}-container`}>
			<StyledWrapper.TextContainer
				data-testid={`progressBar-${testTitle}-text-container`}
				isCountdown={data?.isCountdown}
			>
				{data?.isCountdown ? (
					timeRemaining
				) : (
					<>
						<StyledWrapper.Text data-testid={`progressBar-${testTitle}-text-1`}>
							{data?.startDate?.toLocaleDateString('en-US')} -{' '}
							{data?.endDate?.toLocaleDateString('en-US')}
						</StyledWrapper.Text>
					</>
				)}
			</StyledWrapper.TextContainer>

			<StyledWrapper.BarContainer data-testid={`progressBar-${testTitle}-bar`}>
				<StyledWrapper.ProgressIndicator
					data-testid={`progressBar-${testTitle}-indicator`}
					isCountdown={data?.isCountdown}
					progress={Math.floor(progressPercentage)}
					lowRemainingTime={lowRemainingTime}
				/>
			</StyledWrapper.BarContainer>
		</StyledWrapper.OutermostContainer>
	);
};

export default ProgressBar;

import { useState, useRef, useEffect } from 'react';
import StyledWrapper from './style';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Tooltip from '@mui/material/Tooltip';
import EllipsisIcon from '../../assets/customIcons/ellipsis';
import GenerateEmailModal from '../GenerateEmailModal/GenerateEmailModal';

export default function TableActions(props) {
	const [options, setOptions] = useState([]);
	const [open, setOpen] = useState(false);
	const [hasNoTableRows, setHasNoTableRows] = useState(props?.rowCount === 0);
	const [generateEmailModal, setGenerateEmailModal] = useState(false);
	const anchorRef = useRef(null);
	const [selectedIndex, setSelectedIndex] = useState();

	const handleMenuItemClick = async (event, index) => {
		if (options[index].includes('Generate List')) {
			setGenerateEmailModal(true);
		} else if (options[index].includes('Export Table Data (.csv)')) {
			await props.exportTableToCSV();
		} else if (options[index].includes('Export Table to PDF')) {
			await props.exportTableToPDF();
		}

		setSelectedIndex();
		setOpen(false);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	useEffect(() => {
		let newOptions = [];
		props?.emailType && newOptions.push(`Generate List of ${props.emailType} Emails`);
		newOptions.push(`Export Table Data (.csv)`);
		newOptions.push(`Export Table to PDF`);
		setOptions(newOptions);
	}, []);

	useEffect(() => {
		setHasNoTableRows(props?.rowCount === 0);
	}, [props]);

	return (
		<StyledWrapper.TableActionsContainer
			width={props?.width}
			marginLeft={props?.marginLeft}
			marginTop={props?.marginTop}
			marginBottom={props?.marginBottom}
			data-testid={`programs-reviewToolbar-actionButtons`}
		>
			<>
				<StyledWrapper.EllipsisButton
					ref={anchorRef}
					aria-label="actions-dropdown"
					onClick={handleToggle}
				>
					<EllipsisIcon />
				</StyledWrapper.EllipsisButton>
				<Popper
					sx={{
						zIndex: 1202
					}}
					open={open}
					anchorEl={anchorRef.current}
					role={undefined}
					transition
					disablePortal
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
							}}
						>
							<Paper>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList id="split-button-menu" autoFocusItem>
										{options.map((option, index) => {
											const isEmailListDisabled =
												hasNoTableRows && options[index].includes('Generate List');

											return (
												<Tooltip
													key={option}
													title={
														isEmailListDisabled
															? 'Nothing to generate. Please select at least one record in order to generate a dataset.'
															: ''
													}
													placement="top"
												>
													<span>
														<MenuItem
															key={option}
															disabled={
																(option === 'Export Table Data (.csv)' &&
																	!props.tableExportAvailable) ||
																(option === 'Export Table to PDF' && !props.exportTableToPDF) ||
																isEmailListDisabled
															}
															selected={index === selectedIndex}
															onClick={(event) => handleMenuItemClick(event, index)}
														>
															{option}
														</MenuItem>
													</span>
												</Tooltip>
											);
										})}
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</>

			{generateEmailModal && (
				<GenerateEmailModal
					isOpen={generateEmailModal}
					handleClose={() => setGenerateEmailModal(false)}
					emailType={props?.emailType}
					activeFilters={props?.activeFilters}
					emailEndpoint={props?.emailEndpoint}
				/>
			)}
		</StyledWrapper.TableActionsContainer>
	);
}

import styled from 'styled-components';
import Button from '@mui/material/Button';

const StyledButton = styled(Button)`
	min-width: 120px;
	variant: ${(props) => props.variant || 'outlined'};
`;

export default {
	Button
};

import React from 'react';
import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML } from '../utils';

import StyledWrapper from './style';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { getElementIcon } from '../utils';
import FormControl from '@mui/material/FormControl';
export const MUIDatePicker = React.forwardRef((props, ref) => {
	const { name, defaultValue, disabled, onChange, required, isFormBuilder } = props;
	const [value, setValue] = React.useState(defaultValue || null);

	const parsedLabel = React.useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<FormControl sx={{ width: '100%' }} variant="outlined">
				{props?.data?.showTimeSelect ? (
					props?.data?.showTimeSelectOnly ? (
						<>
							<StyledWrapper.CustomLabel disabled={disabled}>
								<>
									{isFormBuilder && getElementIcon(props.data?.key)}
									{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
									{isFormBuilder && (
										<div>
											{props.data?.unique_identifier
												? `(ff-${props.data?.unique_identifier})`
												: `(tmp-${props.data?.temp_order})`}
										</div>
									)}
									{(required || props?.data?.required) && (
										<StyledWrapper.AsteriskSpan> *</StyledWrapper.AsteriskSpan>
									)}
								</>
							</StyledWrapper.CustomLabel>
							<StyledWrapper.CustomTimePicker
								name={name}
								value={value}
								disabled={disabled}
								onChange={(newValue) => {
									setValue(newValue);
									onChange && onChange(newValue);
								}}
								inputRef={ref}
								renderInput={(params) => (
									<TextField
										error={props?.data?.error}
										InputLabelProps={{ shrink: isFormBuilder || undefined }}
										required={required}
										name={name}
										style={{ marginTop: '16px' }}
										data-testid={`datepicker-textfield`}
										{...params}
									/>
								)}
							/>
						</>
					) : (
						<>
							<StyledWrapper.CustomLabel disabled={disabled}>
								<>
									{isFormBuilder && getElementIcon(props.data?.key)}
									{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
									{isFormBuilder && (
										<div>
											{props.data?.unique_identifier
												? `(ff-${props.data?.unique_identifier})`
												: `(tmp-${props.data?.temp_order})`}
										</div>
									)}
									{(required || props?.data?.required) && (
										<StyledWrapper.AsteriskSpan> *</StyledWrapper.AsteriskSpan>
									)}
								</>
							</StyledWrapper.CustomLabel>
							<StyledWrapper.CustomDateTimePicker
								name={name}
								minDate={props?.data?.min_date || props?.data?.min_value}
								maxDate={props?.data?.max_date || props?.data?.max_value}
								disabled={disabled}
								value={value}
								onChange={(newValue) => {
									setValue(newValue);
									onChange && onChange(newValue);
								}}
								inputRef={ref}
								renderInput={(params) => (
									<TextField
										error={props?.data?.error}
										InputLabelProps={{ shrink: isFormBuilder || undefined }}
										required={required}
										name={name}
										data-testid={`datepicker-textfield`}
										style={{ width: '100%', marginTop: '16px' }}
										{...params}
									/>
								)}
							/>
						</>
					)
				) : (
					<>
						<StyledWrapper.CustomLabel disabled={disabled}>
							<>
								{isFormBuilder && getElementIcon(props.data?.key)}
								{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
								{isFormBuilder && (
									<div>
										{props.data?.unique_identifier
											? `(ff-${props.data?.unique_identifier})`
											: `(tmp-${props.data?.temp_order})`}
									</div>
								)}
								{(required || props?.data?.required) && (
									<StyledWrapper.AsteriskSpan> *</StyledWrapper.AsteriskSpan>
								)}
							</>
						</StyledWrapper.CustomLabel>
						<StyledWrapper.CustomDatePicker
							minDate={props?.data?.min_date || props?.data?.min_value}
							maxDate={props?.data?.max_date || props?.data?.max_value}
							value={value}
							disabled={disabled}
							onChange={(newValue) => {
								setValue(newValue);
								onChange && onChange(newValue);
							}}
							className={isFormBuilder ? 'removeMarginTop noBorder' : ''}
							inputRef={ref}
							renderInput={(params) => (
								<TextField
									error={props?.data?.error}
									InputLabelProps={{ shrink: isFormBuilder || undefined }}
									required={required}
									name={name}
									data-testid={`datepicker-textfield`}
									style={{ width: '100%', marginTop: '16px' }}
									{...params}
								/>
							)}
						/>
					</>
				)}
			</FormControl>
		</LocalizationProvider>
	);
});

MUIDatePicker.displayName = 'MUI_DatePicker';
Registry.register('MUI_DatePicker', MUIDatePicker);

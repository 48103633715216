import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetEvalStagesQuery } from '../services/endpoints/reviewEndpoints';
import { getEntityPrefix } from '../utils/utilFunctions';
import usePaginatedCall from './usePaginatedCall';

export const useGetAppToRevTable = () => {
	const [searchParams] = useSearchParams();

	const pagination = usePaginatedCall(useGetEvalStagesQuery, {
		filterBaseName: 'BULK_EVAL'
	});
	const allEvalStages = pagination.rows;
	const [rowData, setRowData] = useState([]);

	function shouldFiltrateItem(filters, item) {
		let ret = false;

		filters.forEach((filter) => {
			const operator = filter.operatorValue;
			const columnValue = item[filter.columnField];

			if (
				operator === 'isNotEmpty' &&
				(columnValue === '--' || columnValue === 'N/A' || !columnValue)
			) {
				ret = true;
			} else if (operator === 'isEmpty' && columnValue) {
				ret = true;
			} else if (operator === 'equals' && columnValue !== filter.value) {
				ret = true;
			} else if (
				operator === 'contains' &&
				((typeof columnValue === 'number' && columnValue !== parseInt(filter.value)) ||
					!columnValue.toLowerCase().includes(filter.value.toLowerCase()))
			) {
				ret = true;
			} else if (operator === '>=' && columnValue < parseInt(filter.value)) {
				ret = true;
			} else if (operator === '<=' && columnValue > parseInt(filter.value)) {
				ret = true;
			}
		});

		return ret;
	}

	useEffect(() => {
		let copiedRowData = [];
		if (allEvalStages?.results) {
			allEvalStages?.results?.forEach((app) => {
				const ApplicationPrefix = getEntityPrefix({
					type: 'application-process',
					isInstance: true
				});

				app?.stages?.forEach((stage) => {
					const item = {
						id: `${ApplicationPrefix}${app?.unique_identifier}-${copiedRowData?.length + 1}`,
						submitter: app?.submitter,
						title: app?.project_title || 'N/A',
						reviewers: '----',
						totalReviewers: 0,
						appStatus: app?.status
					};

					const StagePrefix = getEntityPrefix({
						type: 'evaluation-stage',
						isInstance: true
					});

					const hierarchy = [];
					hierarchy.push(`${ApplicationPrefix}${app?.unique_identifier}`);
					hierarchy.push(`${StagePrefix}${stage?.unique_identifier}`);

					item['hierarchy'] = hierarchy;
					item['stageName'] =
						stage?.stage_name + ' [' + `${StagePrefix}${stage?.unique_identifier}` + ']';
					item['stageId'] = `${StagePrefix}${stage?.unique_identifier}`;
					item['status'] = stage?.status;
					item['id'] = `${StagePrefix}${stage?.unique_identifier}-${copiedRowData?.length + 1}`;
					item['is_current_stage'] = stage?.stage_id === app?.form_stage_id;

					stage?.master_forms?.forEach((master_form) => {
						const filteredReviewForms = stage?.review_forms?.filter(
							(e) => e?.form_id === master_form?.form_id
						);

						item['totalReviewers'] = filteredReviewForms?.length;
						item['reviewers'] = '';

						filteredReviewForms?.forEach((reviewForm) => {
							item['reviewers'] += `${reviewForm?.first_name} ${reviewForm?.last_name}, `;
						});
					});

					item['reviewers'] = item['reviewers'].substring(0, item['reviewers'].length - 2);

					if (!shouldFiltrateItem(pagination.filterModel, item)) {
						copiedRowData.push(item);
					}
				});
			});
		}

		setRowData(copiedRowData);
	}, [allEvalStages, searchParams]);

	useEffect(() => {
		pagination?.refreshCall();
	}, [searchParams]);

	return {
		data: rowData,
		isLoading: pagination?.loading,
		refetch: pagination?.refreshCall,
		pagination
	};
};

import styled from 'styled-components';
import LoadingButton from '@mui/lab/LoadingButton';

const OutermostContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	margin: auto;
	word-break: break-all;
`;

const InnerContainer = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: no-wrap;
	gap: 15px;
	align-items: flex-start;
	justify-content: center;
`;

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const FormHeader = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
`;

const HeaderAlertText = styled.span`
	color: var(--Light-Info-Shades-160p, #0288d1);
	font-feature-settings: 'liga' off, 'clig' off;

	/* Typography/Body 2 */
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 143%; /* 20.02px */
	letter-spacing: 0.17px;
`;

const LastEdited = styled.h6`
	margin: 0px;
`;

const LastEditedActions = styled.div`
	display: flex;
	gap: 5px;
	margin-top: 8px;
	margin-bottom: 8px;
	margin-left: auto;
`;

const CardRowInfo = styled.h6`
	display: ${(props) => props?.display || 'block'};
	align-items: center;
	justify-content: ${(props) => props?.justifyContent || 'space-between'};
	gap: 20px;
	width: ${(props) => props?.width || '55%'};
	text-align: ${(props) => props?.align || 'start'};
	color: ${(props) => (props?.status ? 'rgba(0, 0, 0, 0.38)' : 'black')};
	pointer-events: ${(props) => props?.status && 'none'};
	text-overflow: ellipsis;
	max-width: ${(props) => props?.maxWidth || '33ch'};
	white-space: nowrap;
	overflow: hidden;
	margin: 0px;
`;

const ApplicationTitle = styled.h5`
	color: var(--main-text-color, rgba(0, 0, 0, 0.87));

	/* Typography/H5 */
	font-family: Roboto;
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 133.4%;

	margin-block-start: 15px;
	margin-block-end: 15px;
`;

const NavigationContainer = styled.div`
	width: 300px;
`;

const SubmitApplicationButton = styled(LoadingButton)`
	margin: 13px auto;
`;

export default {
	OutermostContainer,
	InnerContainer,
	FormContainer,
	FormHeader,
	HeaderAlertText,
	LastEdited,
	LastEditedActions,
	CardRowInfo,
	ApplicationTitle,
	NavigationContainer,
	SubmitApplicationButton
};

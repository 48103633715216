import { useState, useEffect } from 'react';

import ReviewColumns from './reviewColumns';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import { Table } from '../../components/Table';
import StyledWrapper from '../IndividualProgram/style';
import { useGetUserReviewsQuery } from '../../services/endpoints/programsEndpoints';
import usePaginatedCall from '../../hooks/usePaginatedCall';

export const UserReviews = () => {
	// useStates
	const initialColumns = ReviewColumns();
	const [tableColumns, setTableColumns] = useState(initialColumns);
	const [apiStatusAlert, setApiStatusAlert] = useState();

	const pagination = usePaginatedCall(useGetUserReviewsQuery, { filterBaseName: 'USER_REVIEWS' });

	const theme = createTheme({
		palette: {
			secondary: {
				main: '#9C27B0'
			}
		}
	});

	useEffect(() => {
		if (
			(!pagination.loading && !pagination?.rows?.results) ||
			pagination?.rows?.results?.length === 0
		) {
			setApiStatusAlert({
				message: `There are no reviews assigned to you`,
				status: 'info'
			});
		} else {
			setApiStatusAlert();
		}
	}, [pagination]);

	return (
		<>
			<ThemeProvider theme={theme}>
				<Stack sx={{ width: '100%' }} spacing={2}>
					{apiStatusAlert && (
						<Alert variant="outlined" severity={apiStatusAlert?.status}>
							{apiStatusAlert?.message}
						</Alert>
					)}
					<br />
				</Stack>

				{!apiStatusAlert && (
					<StyledWrapper.TableContainer>
						<h4
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								paddingBottom: '30px'
							}}
						>
							Reviews
						</h4>
						<Table
							getRowId={(cell) => cell?.application_id}
							columns={tableColumns}
							rowHeight={52}
							enableAutoPageSize
							rows={pagination?.rows?.results || []}
							rowCount={pagination?.rowCount}
							pagination={pagination?.pagination}
							pageSize={pagination?.pageSize}
							paginationMode={pagination?.paginationMode}
							onPageChange={(e) => pagination?.onPageChange(e)}
							onPageSizeChange={(e) => pagination?.onPageSizeChange(e)}
							page={pagination?.page}
							loading={pagination?.loading}
							sortingMode={pagination?.sortingMode}
							onSortModelChange={(e) => pagination?.onSortModelChange(e)}
							onFilterModelChange={(e) => pagination?.onFilterModelChange(e)}
							filterModel={pagination?.filterModel}
							sortModel={pagination?.sortModel}
							hideFilters={true}
						/>
					</StyledWrapper.TableContainer>
				)}
			</ThemeProvider>
		</>
	);
};

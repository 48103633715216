import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
import { SectionCard } from '../../../../components/SectionCard';
import { MultiFormViewer } from '../../../../components/MultiFormViewer';
import StyledWrapper from '../../style';
import { FormBuilder } from '../../../../components/FormBuilder';
import { useSearchParams } from 'react-router-dom';

const ReviewForm = ({
	reviewer,
	readOnly,
	formID,
	isMasterLayout,
	staticInstructions,
	apiFormData,
	setReadOnly,
	disableActionButtons,
	isAppSetup,
	setReadOnlyTest = {}
}) => {
	const formSchema = useSelector((state) => state?.form?.formSchema);
	const formAnswers = useSelector((state) => state?.form?.form);
	const pathArray = location?.pathname?.split('/');
	let subPage = pathArray[pathArray.length - 1];

	const [numerator, setNumerator] = useState(0);
	const [denominator, setDenominator] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [finalScore, setFinalScore] = useState();
	const [searchParams, setSearchParams] = useSearchParams();

	const field_values = apiFormData?.field_values?.length > 0 && [[], apiFormData?.field_values];

	useEffect(() => {
		let denominator = 0;
		const formSchemaData = {};
		try {
			formSchema?.[0]?.forEach((e) => {
				if (e && 'description' in e && e.description === 'total_score') {
					formSchemaData[e.field_name] = 1;
					if (e.key === 'MUI_NumberInput' && e.max_value) {
						denominator += parseInt(e.max_value);
					} else if (e.key === 'MUI_Checkbox') {
						e.options?.forEach((option) => {
							let value = parseInt(option.value);
							if (!isNaN(value)) {
								denominator += value;
							}
						});
					} else if (['MUI_RadioButton', 'StatePicker', 'MUI_Dropdown'].includes(e.key)) {
						let maxValue = 0;
						e.options?.forEach((option) => {
							let value = parseInt(option.value);
							if (!isNaN(value)) {
								maxValue = Math.max(maxValue, value);
							}
						});
						denominator += maxValue;
					}
				}
			});
		} catch (error) {
			console.log(error);
		}
		setDenominator(denominator);

		let numerator = 0;
		try {
			formAnswers?.[1]?.forEach((e) => {
				if (e && formSchemaData.hasOwnProperty(e.name)) {
					if (e.name.includes('mui_number_')) {
						let value = parseInt(e.value);
						if (!isNaN(value)) {
							numerator += value;
						}
					} else if (e.name.includes('mui_radiobutton_')) {
						const el = document.getElementsByName(`${e?.name}`)?.[0];
						if (el) {
							const elem = ReactDOM.findDOMNode(el);
							// fixed issue: updates only after second click
							for (let child of elem.children[1].children[0].children[0].children) {
								if ([...child.children[0].children[0].classList].includes('Mui-checked')) {
									let value = child.children[0].children[0].children[0].getAttribute('data-value');
									value = parseInt(value);
									if (!isNaN(value)) {
										numerator += value;
									}
								}
							}
						} else {
							field_values?.[1]?.forEach((e) => {
								if (e.name.includes('mui_radiobutton_')) {
									let fSchema = formSchema?.[0]?.find((x) => x?.field_name === e?.name);
									let value = fSchema?.options?.find(
										(option) => option?.key === e?.value[0]
									)?.value;
									value = parseInt(value);
									if (!isNaN(value)) {
										numerator += value;
									}
								}
							});
						}
					} else if (e.name.includes('mui_checkbox_')) {
						let fSchema = formSchema?.[0]?.find((x) => x?.field_name === e?.name);
						e?.value?.forEach((x) => {
							let value = fSchema?.options?.find((i) => i?.key === x)?.value;
							value = parseInt(value);
							if (!isNaN(value)) {
								numerator += value;
							}
						});
					} else if (e.name.includes('mui_dropdown_')) {
						let fSchema = formSchema?.[0]?.find((x) => x?.field_name === e?.name);
						let value = fSchema?.options?.find((i) => i?.key === e?.value)?.value;
						value = parseInt(value);
						if (!isNaN(value)) {
							numerator += value;
						}
					} else if (e.name.includes('mui_statepicker_')) {
						let value = parseInt(e.value);
						if (!isNaN(value)) {
							numerator += value;
						}
					}
				}
			});
		} catch (error) {
			console.log(error);
		}
		setNumerator(numerator);
	}, [formSchema, formAnswers, apiFormData]);

	return (
		<>
			{isMasterLayout ? (
				<FormBuilder
					isMasterLayout={isMasterLayout}
					readOnly={readOnly}
					// data={schemaState}
					formInfo={{ instructions: 'test' }}
					formId={formID}
					hidePreviewBar={true}
					hideToolbox={true}
					formTitle={`Review Form Layout [${subPage}]`}
					onlyInstructions={true}
					staticInstructions={staticInstructions}
				/>
			) : (
				<SectionCard width={'50%'} height={'fit-content'} noscroll={true} padding={'0px'}>
					<StyledWrapper.DetailTitleContainer data-testid={`reviewForm-title-container`}>
						<StyledWrapper.DetailTitle>
							{reviewer && `Reviewed by: ${reviewer}`}
							<p style={{ margin: '0px' }}>{`${apiFormData?.completion || 0}% completed`}</p>
						</StyledWrapper.DetailTitle>
					</StyledWrapper.DetailTitleContainer>
					{!isLoading && (
						<StyledWrapper.CardFormContainer readOnly={readOnly}>
							<MultiFormViewer
								readOnly={isAppSetup ? false : readOnly}
								isSimulating={searchParams.get('viewType') === 'preview'}
								setReadOnly={setReadOnly}
								formID={formID}
								disableFooterButtons
								hideActionButtons
								disableActionButtons={disableActionButtons}
								isEmbedded={true}
								formAnswers={field_values || formAnswers || []}
								form={apiFormData?.form_schema ? [apiFormData?.form_schema] : formSchema || []}
								setReadOnlyTest={setReadOnlyTest}
							/>

							<StyledWrapper.ScoreOuterContainer>
								<p style={{ marginBottom: '0px' }}>Total Score</p>
								<StyledWrapper.ScoreContainer>
									<StyledWrapper.Score>{numerator}</StyledWrapper.Score>
									<StyledWrapper.ScoreOverall>{`/(out of ${denominator})`}</StyledWrapper.ScoreOverall>
								</StyledWrapper.ScoreContainer>
							</StyledWrapper.ScoreOuterContainer>
						</StyledWrapper.CardFormContainer>
					)}
				</SectionCard>
			)}
		</>
	);
};

export default ReviewForm;

import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Overview from './Overview/Overview';
import ManageApplication from './ManageApplication/ManageApplication';
import ManageReviews from './ManageReviews/ManageReviews';

import { TabPanel, a11yProps } from '../../components/TabPanel';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import SettingsIcon from '@mui/icons-material/Settings';
import Tooltip from '@mui/material/Tooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';

import useIDToTitle from '../../hooks/useIDToTitle';

import { useGetIndividualProgramsQuery } from '../../services/endpoints/programsEndpoints';
import { ProgramSetup } from '../ProgramSetup';
import { saveVariable } from '../../components/BaseLayout/slice';
import { getLocalAuth } from '../../utils/environmentUtils';

const IndividualProgram = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const userAccountTypeID = useSelector((state) => state.login?.user?.userAccountTypeID);
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	let { programID, reviewID } = useParams();
	programID = programID?.replace('p-', '');

	const programTitle = useIDToTitle('program');

	const tabs = ['Overview', 'Applications', 'Reviews', 'Application Setup'];

	// API CALL
	const { data, isUninitialized, isLoading } = useGetIndividualProgramsQuery(
		{ apiToken, id: programID },
		{ skip: !userAccountTypeID }
	);

	//UseStates
	const [tabView, setTabView] = useState(0);
	const [chosenTabView, setChosenTabView] = useState(<></>);
	const [toggleReviewModal, setToggleReviewModal] = useState(false);

	const handleTabClick = (tabName) => {
		let tabPath = tabName.toLowerCase().replaceAll(' ', '-');
		if (tabPath === 'reviews') tabPath += '/assign-by-reviewer';
		if (tabPath === 'application-setup') tabPath += '/application';
		navigate(`/programs/p-${programID}/${tabPath}`);
	};

	const selectedTabView = (view) => {
		switch (view) {
			case 0:
				return <Overview />;
			case 1:
				return <ManageApplication />;
			case 2:
				return <ManageReviews />;
			case 3:
				return <ProgramSetup />;
			default:
				return <></>;
		}
	};

	const toolTipText = (tabName) => {
		const isReadyToPublish = data?.app_process_is_ready_to_publish;
		if (tabName !== 'Application Setup' && !isReadyToPublish) {
			return 'Disabled until Application Process is finalized.';
		}

		return (
			tabName === 'Application Setup' &&
			!isReadyToPublish &&
			'Application Process Setup not completed'
		);
	};

	const isTabDisabled = (tabName) =>
		tabName !== 'Application Setup' && !data?.app_process_is_ready_to_publish;

	useEffect(() => {
		dispatch(
			saveVariable([
				'program',
				{
					id: data?.programId,
					title: data?.title,
					app_process_is_ready_to_publish: data?.app_process_is_ready_to_publish
				}
			])
		);
	}, [data]);

	useEffect(() => {
		if (data && !data?.app_process_is_ready_to_publish) {
			navigate(`/programs/p-${programID}/application-setup/application`);
		}
	}, [isUninitialized, isLoading]);

	useEffect(() => {
		setChosenTabView(selectedTabView(tabView));
	}, [tabView]);

	useEffect(() => {
		const pathArray = location?.pathname?.split('/');
		const conditionals = ['Review%20Overview', 'Details'];
		const openModal = conditionals.includes(pathArray[pathArray?.length - 1]);

		openModal && setToggleReviewModal(true);
	}, [location, reviewID]);

	useEffect(() => {
		const path = location?.pathname?.split('/');
		const selectedView = path[3];

		switch (selectedView) {
			case 'overview':
				setTabView(0);
				return;
			case 'applications':
				setTabView(1);
				return;
			case 'reviews':
				setTabView(2);
				return;
			case 'application-setup':
				setTabView(3);
				return;
			default:
				setTabView(0);
		}
	}, [location]);

	return (
		<>
			{userAccountTypeID && (
				<h4 data-testid={`programs-title`}>
					{programTitle && `Program: ${data?.title} [p-${programID}]`}
				</h4>
			)}

			{!userAccountTypeID && (
				<Tooltip key={`FormTab-Go-Back`} placement="top" title={'Go Back'} arrow>
					<IconButton
						aria-label="go-back"
						size="large"
						onClick={() => {
							window.history.back();
						}}
					>
						<ArrowBackIcon />
					</IconButton>
				</Tooltip>
			)}

			<Box sx={{ width: '100%' }}>
				{userAccountTypeID && (
					<Box data-testid={`individualprograms-box`}>
						<Tabs
							value={tabView}
							aria-label="basic tabs example"
							style={{ width: 'fit-content', height: '24px', marginTop: '30px' }}
							indicatorColor="secondary"
						>
							{tabs.map((e, index) => {
								return (
									<Tooltip key={`FormTab-${index}`} placement="top" title={toolTipText(e)} arrow>
										<Tab
											onClick={() => handleTabClick(e)}
											sx={{
												minHeight: '0px',
												padding: '0px 16px',
												height: '24px',
												'&.Mui-disabled': { pointerEvents: 'all' }
											}}
											icon={
												e === 'Application Setup' && (
													<SettingsIcon
														sx={{ fill: !data?.app_process_is_ready_to_publish && '#FF9800' }}
													/>
												)
											}
											iconPosition="start"
											data-testid={`individualprograms-tab-${index}`}
											label={`${e}`}
											disabled={isTabDisabled(e)}
											{...a11yProps(e + 1)}
										/>
									</Tooltip>
								);
							})}
						</Tabs>
					</Box>
				)}

				<TabPanel data-testid={`individualprograms-tab-panel`} value={tabView} index={tabView}>
					{chosenTabView}
				</TabPanel>
			</Box>
		</>
	);
};

export default IndividualProgram;

export const regularFilters = [
	{ value: 'contains', label: 'contains' },
	{ value: 'isEmpty', label: 'is empty' },
	{ value: 'isNotEmpty', label: 'is not empty' }
];

export const statusFilters = [
	{ value: 'contains', label: 'contains' },
	{ value: 'equals', label: 'status is' },
	{ value: 'isEmpty', label: 'is empty' },
	{ value: 'isNotEmpty', label: 'is not empty' }
];

export const dateFilters = [
	{ value: 'is', label: 'equals' },
	{ value: 'onOrAfter', label: 'is = or > than' },
	{ value: 'onOrBefore', label: 'is = or < than' },
	{ value: 'isEmpty', label: 'is empty' },
	{ value: 'isNotEmpty', label: 'is not empty' }
];

export const booleanFilters = [{ value: 'equals', label: 'equals' }];

export const numberFilters = [
	{ value: '=', label: 'equals' },
	{ value: '>=', label: 'is = or > than' },
	{ value: '<=', label: 'is = or < than' }
];

export const allStatuses = [
	{ value: 'Published', label: 'Published' },
	{ value: 'Finalized', label: 'Finalized' },
	{ value: 'Draft', label: 'Draft' },
	{ value: 'Under Review', label: 'Under Review' },
	{ value: 'Archived', label: 'Archived' },
	{ value: 'Closed', label: 'Closed' },
	{ value: 'Unassigned', label: 'Unassigned' },
	{ value: 'Not Started', label: 'Not Started' },
	{ value: 'In Progress', label: 'In Progress' },
	{ value: 'In Progress (paused)', label: 'In Progress (paused)' },
	{ value: 'In Progress (returned)', label: 'In Progress (returned)' },
	{ value: 'In Progress (reopened)', label: 'In Progress (reopened)' },
	{ value: 'Completed', label: 'Completed' },
	{ value: 'Submitted', label: 'Submitted' },
	{ value: 'Rec. Award', label: 'Decision Rec. Award' },
	{ value: 'On Hold', label: 'Decision On Hold' },
	{ value: 'Denied', label: 'Decision Denied' }
];

export const setValueAttributes = ['isEmpty', 'isNotEmpty'];

export const booleanFields = ['app_process_is_ready_to_publish', 'linked_count'];

export const numericFields = ['available_funds'];

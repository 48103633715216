import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

export default function CustomModal(props) {
	const [open, setOpen] = useState(false);

	const style = {
		width: props?.width || 400,
		height: props?.height,
		maxHeight: '100vh',
		overflow: 'scroll',
		bgcolor: 'background.paper',
		border: '0px solid #000',
		borderRadius: '5px',
		boxShadow: 24,
		p: 4
	};

	const handleClose = () => {
		props?.setClose && props?.setClose();
		setOpen(false);
	};

	useEffect(() => {
		setOpen(props?.isOpen);
	}, [props]);

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				data-testid={props?.id}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>{props.children}</Box>
			</Modal>
		</div>
	);
}

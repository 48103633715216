import React, { useState, useEffect } from 'react';

import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML, useFileUtils, getElementIcon } from '../utils';
import StyledWrapper from './style';

import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';

export const MUIFileUpload = React.forwardRef((props, _ref) => {
	let { defaultValue, disabled, isFormBuilder, name } = props;
	const { sendFileToServer, downloadFile } = useFileUtils();
	const [loading, setLoading] = React.useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [filePath, setFilePath] = useState(defaultValue);

	const handleFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const handleFileDownload = () => {
		downloadFile(filePath);
	};

	const handleDelete = () => {
		setFilePath('');
	};

	useEffect(() => {
		// JavaScript: Attach an event listener to the input button
		if (selectedFile) {
			setLoading(true);
			const reader = new FileReader();

			reader.onload = (loadEvent) => {
				const fileData = loadEvent.target.result;

				// Now you can send the file data to the server using an API call (e.g., fetch)
				sendFileToServer(selectedFile.name, fileData)
					.then((data) => {
						setFilePath(`${data.uuid}-${data.filename}`);
						setLoading(false);
					})
					.catch((error) => {
						console.error('Error:', error);
					});
			};
			reader.readAsArrayBuffer(selectedFile);
		}
	}, [selectedFile]);

	useEffect(() => {
		if (filePath) {
			const triggerBlur = document.getElementById('hiddenBlurTriggerInput');
			triggerBlur?.click();
		}
	}, [filePath]);

	const parsedLabel = React.useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = React.useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);

	return (
		<>
			<div className="inner-form-group addBorder" name={name}>
				<StyledWrapper.CustomLabel
					value={defaultValue?.length > 0}
					disabled={disabled ? true : false}
					required={props?.data?.required}
					htmlFor="outlined-adornment-password"
					shrink={true}
					className={'customLabel minus6MarginTop'}
				>
					{isFormBuilder && getElementIcon(props.data?.key)}
					{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
					{isFormBuilder && (
						<div>
							{props.data?.unique_identifier
								? `(ff-${props.data?.unique_identifier})`
								: `(tmp-${props.data?.temp_order})`}
						</div>
					)}
				</StyledWrapper.CustomLabel>
				<StyledWrapper.BorderOutline
					className={'customInputOutline'}
					disabled={disabled ? true : false}
				>
					{!filePath ? (
						<LoadingButton
							className="noMarginTop"
							disabled={disabled}
							loading={loading}
							variant="outlined"
							startIcon={<CloudUploadIcon />}
							component="label"
							sx={{
								fontWeight: '500!important',
								lineHeight: '1.75!important',
								fontSize: '13px!important',
								letterSpacing: '0.02857rem!important'
							}}
						>
							{'Upload File'}
							<input type="file" hidden onChange={handleFileChange} />
						</LoadingButton>
					) : (
						<div style={{ display: 'flex', gap: '10px' }}>
							<LoadingButton
								id={`${props?.data?.field_name}-download`}
								onClick={handleFileDownload}
								loading={loading}
								value={filePath}
								variant="contained"
								startIcon={<DownloadIcon />}
							>
								{'Download File'}
							</LoadingButton>
							{!disabled && (
								<IconButton onClick={handleDelete} aria-label="delete" color={'error'}>
									<CloseIcon />
								</IconButton>
							)}
						</div>
					)}
				</StyledWrapper.BorderOutline>
			</div>

			<FormHelperText error={props?.data?.error}>
				{props?.data?.error
					? props?.data?.error?.error
					: parsedHelperText?.[0]
					? parsedHelperText
					: props?.data?.help_message}
			</FormHelperText>
		</>
	);
});

MUIFileUpload.displayName = 'MUI_FileUpload';
Registry.register('MUI_FileUpload', MUIFileUpload);

import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { pdf } from '@react-pdf/renderer';

import { Table } from '../../../components/Table';
import { TableTitle } from '../../../components/TableTitle/TableTitle';
import { checkOverflow, createAndDownloadFile } from '../../../utils/utilFunctions';
import { formatTimestamp } from '../../../utils/dateUtils';
import { ActionButtons } from '../../../components/ActionButtons/ActionButtons';
import { IndividualApplication } from '../../IndividualApplication';

import StyledWrapper from '../style';

import Chip from '@mui/material/Chip';

import Backdrop from '@mui/material/Backdrop';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { usePermissions } from '../../../hooks/usePermissions';
import { DecisionChip } from '../../../components/core/DecisionChip/DecisionChip';
import usePaginatedCall from '../../../hooks/usePaginatedCall';
import useIDToTitle from '../../../hooks/useIDToTitle';
import {
	useGetProgramApplicationsQuery,
	useLazyGetProgramApplicationsQuery,
	useGetApplicantEmailsQuery
} from '../../../services/endpoints/programsEndpoints';
import { numberToMoney } from '../../../utils/numberUtils';
import { getChipColor, getChipTextColor, isDecisionChip } from '../../../utils/utilFunctions';
import { getLocalAuth } from '../../../utils/environmentUtils';
import {
	COMPLETED,
	DENIED,
	ON_HOLD,
	PENDING_DECISION,
	REC_AWARD,
	FULL_REVIEW,
	CLOSED
} from '../../../constants/common';
import ApplicationsTemplate from './ApplicationsTemplate';

import { getEntityPrefix } from '../../../utils/utilFunctions';

const STATUSES_WITHOUT_DATE_DISPLAYED = [
	COMPLETED,
	DENIED,
	ON_HOLD,
	PENDING_DECISION,
	REC_AWARD,
	FULL_REVIEW,
	CLOSED
];

const ManageApplication = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	let { programID, appID } = useParams();
	programID = programID?.replace('p-', '');
	const [finalData, setFinalData] = useState([]);
	const [noApplications, setNoApplications] = useState(false);

	const allApplications = usePaginatedCall(useGetProgramApplicationsQuery, {
		filterBaseName: 'PROGRAM_APPLICATIONS',
		iFrame: searchParams.get('iFrame')
	});
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const [getAllApplications, { isLoading: csvDataIsLoading, isFetching: csvDataIsFetching }] =
		useLazyGetProgramApplicationsQuery();
	const tableExportAvailable = allApplications?.rowCount > 0;

	useEffect(() => {
		let copiedRowData = [];
		if (allApplications.pageSize > 0) {
			if (allApplications?.rows?.results) {
				allApplications?.rows?.results?.forEach((e) => {
					let startDate, endDate;

					if (!STATUSES_WITHOUT_DATE_DISPLAYED.includes(e?.status?.toLowerCase())) {
						startDate =
							e?.form_stage_id?.start_date != null
								? formatTimestamp(e?.form_stage_id?.start_date)
								: formatTimestamp(e?.process_start_date);

						endDate =
							e?.form_stage_id?.end_date != null
								? formatTimestamp(e?.form_stage_id?.end_date)
								: formatTimestamp(e?.process_end_date);
					}

					const stagePrefix = getEntityPrefix({
						type:
							e?.form_stage_id?.stage_type === 'application'
								? 'submission-stage'
								: 'evaluation-stage',
						isInstance: true
					});

					copiedRowData.push({
						...e,
						title: e?.projectTitle,
						unique_identifier: `api-${e?.unique_identifier}`,
						status: e?.status,
						budgetWorksheet: e?.budgetWorksheet?.totalProposedCost,
						id: copiedRowData?.length + 1,
						completion: `${e?.completion}%`,
						'form_stage_id.name': e?.form_stage_id && `${e?.form_stage_id?.name}`,
						'form_stage_id.unique_identifier':
							e?.form_stage_id && `${stagePrefix}${e?.form_stage_id?.unique_identifier}`,
						'form_stage_id.start_date': startDate,
						'form_stage_id.end_date': endDate,
						original_stage_start: e?.form_stage_id?.start_date || e?.process_start_date,
						original_stage_end: e?.form_stage_id?.end_date || e?.process_end_date,
						submitter: e?.submitter
					});
				});
			}

			setFinalData(copiedRowData);
			setNoApplications(
				allApplications.rows && copiedRowData.length <= 0 && allApplications.filterModel.length == 0
			);
		}
	}, [allApplications?.rows?.results]);

	// useStates
	const [openTooltip, setOpenTooltip] = useState(0);
	const [isLoading, setLoading] = useState(false);
	const [alertBalloon, setAlertBalloon] = useState(false);
	const { hasEditPermissions } = usePermissions();
	const handleAlert = (message, status) => {
		setAlertBalloon({ isOpen: true, message, status });
		setTimeout(() => {
			setAlertBalloon(false);
		}, 2000);
	};

	useEffect(() => {
		if (hasEditPermissions) {
			setLoading(true);
			setTimeout(function () {
				setLoading(false);
			}, 200);
		}
	}, [hasEditPermissions]);

	const columns = [
		{
			field: 'project_title',
			headerName: 'Project Title',
			description: 'Project Title',
			renderCell: (cellValues) => {
				const { status, unique_identifier: displayId } = cellValues.row ?? {};
				const title = cellValues.formattedValue;
				const programId = cellValues.row.program.programId;
				const titleData = {
					displayId,
					title,
					status,
					entityType: 'Application Instance'
				};

				const handleCardClick = () => {
					navigate(
						`/programs/p-${programId}/applications/${displayId}?${searchParams.toString()}&formtype=application${
							searchParams?.get('iFrame') === 'true' ? '&iFrame=true' : ''
						}`
					);
				};
				return <TableTitle titleData={titleData} handleCardClick={handleCardClick} />;
			},
			flex: 1,
			minWidth: 400
		},
		{
			field: 'submitter',
			headerName: 'Submitter',
			description: 'Submitter',
			renderCell: (cellValues) => {
				return (
					<BasicCellText
						isLink={true}
						cellValues={cellValues}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
					/>
				);
			},
			flex: 1,
			minWidth: 100
		},
		/* -------------------------------------- */
		/* This code is temporarily commented out */
		/* -------------------------------------- */
		// {
		// 	field: 'completion',
		// 	headerName: 'Completion',
		// 	description: 'Completion',
		// 	renderCell: (cellValues) => {
		// 		return (
		// 			<BasicCellText
		// 				cellValues={cellValues}
		// 				openTooltip={openTooltip}
		// 				setOpenTooltip={setOpenTooltip}
		// 			/>
		// 		);
		// 	},
		// 	flex: 1,
		// 	minWidth: 50
		// },
		{
			field: 'status',
			headerName: 'Application Status',
			renderCell: (cellValues) => {
				return (
					<>
						<Tooltip
							open={openTooltip === `status-${cellValues?.id}`}
							onOpen={(e) => checkOverflow(e) && setOpenTooltip(`status-${cellValues?.id}`)}
							onClose={() => setOpenTooltip(0)}
							arrow
							placement="top"
							title={cellValues?.formattedValue}
						>
							{isDecisionChip(cellValues?.formattedValue) ? (
								<DecisionChip label={cellValues?.formattedValue} />
							) : (
								<Chip
									data-testid={`${cellValues?.field}-${cellValues?.id}`}
									label={cellValues?.formattedValue}
									sx={{
										backgroundColor: getChipColor(cellValues?.formattedValue),
										color: getChipTextColor(cellValues?.formattedValue)
									}}
									size="small"
								/>
							)}
						</Tooltip>
					</>
				);
			},
			flex: 1,
			headerAlign: 'right',
			align: 'right',
			minWidth: 150
		},
		/* -------------------------------------- */
		/* This code is temporarily commented out */
		/* -------------------------------------- */
		// {
		// 	field: 'form_stage_id.name',
		// 	headerName: 'Stage Name',
		// 	description: 'Stage Name',
		// 	renderCell: (cellValues) => {
		// 		return (
		// 			cellValues?.row &&
		// 			cellValues.row['form_stage_id.unique_identifier'] && (
		// 				<BasicCellText
		// 					cellValues={cellValues}
		// 					openTooltip={openTooltip}
		// 					setOpenTooltip={setOpenTooltip}
		// 				/>
		// 			)
		// 		);
		// 	},
		// 	flex: 1,
		// 	minWidth: 100
		// },
		// {
		// 	field: 'form_stage_id.unique_identifier',
		// 	headerName: 'Stage ID',
		// 	renderCell: (cellValues) => {
		// 		return (
		// 			cellValues?.row &&
		// 			cellValues.row['form_stage_id.unique_identifier'] && (
		// 				<>
		// 					<Tooltip
		// 						open={openTooltip === `stageID-${cellValues?.id}`}
		// 						onOpen={(e) => checkOverflow(e) && setOpenTooltip(`stageID-${cellValues?.id}`)}
		// 						onClose={() => setOpenTooltip(0)}
		// 						arrow
		// 						placement="top"
		// 						title={cellValues?.formattedValue}
		// 					>
		// 						<StyledWrapper.CardRowInfoButton
		// 							variant="outlined"
		// 							onClick={() =>
		// 								navigate(
		// 									`/programs/p-${programID}/applications/${
		// 										cellValues?.row?.unique_identifier
		// 									}/${cellValues?.value}?${searchParams.toString()}`
		// 								)
		// 							}
		// 							data-testid={`${cellValues?.field}-${cellValues?.id}`}
		// 							width={'135px'}
		// 						>
		// 							<SubmissionStageIcon isCurrentStage />
		// 							<p>{cellValues?.value || '--'}</p>
		// 						</StyledWrapper.CardRowInfoButton>
		// 					</Tooltip>
		// 				</>
		// 			)
		// 		);
		// 	},
		// 	flex: 2,
		// 	minWidth: 200,
		// 	maxWidth: 200
		// },
		{
			field: 'form_stage_id.start_date',
			type: 'date',
			headerName: 'Start Date',
			description: 'Start Date',
			renderCell: (cellValues) => {
				return (
					cellValues?.row &&
					cellValues.row['form_stage_id.unique_identifier'] && (
						<BasicCellText
							cellValues={cellValues}
							openTooltip={openTooltip}
							setOpenTooltip={setOpenTooltip}
							isDate
						/>
					)
				);
			},
			flex: 1,
			minWidth: 50
		},
		{
			field: 'form_stage_id.end_date',
			type: 'date',
			headerName: 'End Date',
			description: 'End Date',
			renderCell: (cellValues) => {
				return (
					cellValues?.row &&
					cellValues.row['form_stage_id.unique_identifier'] && (
						<BasicCellText
							cellValues={cellValues}
							openTooltip={openTooltip}
							setOpenTooltip={setOpenTooltip}
							isDate
						/>
					)
				);
			},
			flex: 1,
			minWidth: 50
		},
		/* -------------------------------------- */
		/* This code is temporarily commented out */
		/* -------------------------------------- */
		// {
		// 	field: 'remainingTime',
		// 	headerName: 'Remaining Time',
		// 	renderCell: (cellValues) => {
		// 		const startDate = cellValues?.row ? cellValues.row['original_stage_start'] : null;
		// 		const endDate = cellValues?.row ? cellValues.row['original_stage_end'] : null;
		// 		let remainingTimeData = {
		// 			startDate: startDate ? new Date(startDate) : new Date(),
		// 			endDate: endDate ? new Date(endDate) : new Date(),
		// 			isCountdown: true
		// 		};
		//
		// 		return (
		// 			cellValues?.row &&
		// 			cellValues.row['form_stage_id.unique_identifier'] && (
		// 				<ProgressBar testTitle={'manageApplication'} data={remainingTimeData} />
		// 			)
		// 		);
		// 	},
		// 	flex: 1,
		// 	minWidth: 50
		// },
		// {
		// 	field: 'overall_score',
		// 	headerName: 'Score R/M',
		// 	description: 'Score Rated/Maxiumum',
		// 	renderCell: (cellValues) => {
		// 		return (
		// 			<BasicCellText
		// 				isLink={true}
		// 				cellValues={cellValues}
		// 				openTooltip={openTooltip}
		// 				setOpenTooltip={setOpenTooltip}
		// 			/>
		// 		);
		// 	},
		// 	flex: 1,
		// 	minWidth: 50
		// },
		{
			field: 'budgetWorksheet',
			type: 'number',
			headerName: 'Budget',
			description: 'Budget',
			renderCell: (cellValues) => {
				cellValues.formattedValue = cellValues?.value
					? cellValues.formattedValue.replaceAll(',', '')
					: 0;
				cellValues.formattedValue = numberToMoney(parseInt(cellValues.formattedValue));

				return (
					<BasicCellText
						isLink={true}
						cellValues={cellValues}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
					/>
				);
			},
			flex: 1,
			minWidth: 50
		}
	];

	hasEditPermissions &&
		columns.push({
			field: 'action',
			headerName: 'Actions',
			description: 'Actions',
			renderCell: (cellValues) => {
				return (
					<div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
						<ActionButtons
							item={cellValues?.row}
							application_id={cellValues?.row?.unique_identifier?.replace('api-', '')}
							type={'application instance'}
							status={cellValues?.row?.status?.toLowerCase()}
							handleAlert={handleAlert}
						/>
					</div>
				);
			},
			flex: 2,
			minWidth: 450
		});

	const [orderedColumns, setOrderedColumns] = useState(columns);

	const updateOrderedColumns = (updatedOrderedColumns) => setOrderedColumns(updatedOrderedColumns);

	// adds functionality to grab nested object properties with a string
	// Example: accessNestedPropertyWithString(row, 'name.firstName')
	// Returns row.name.firstName
	const accessNestedPropertyWithString = (obj, str) => {
		str = str.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
		str = str.replace(/^\./, ''); // strip a leading dot
		const properties = str.split('.');

		for (let i = 0; i < properties.length; ++i) {
			const property = properties[i];

			if (property in obj) {
				obj = obj[property];
			} else {
				return;
			}
		}

		return obj;
	};

	const exportTableToCSV = async () => {
		let csv = '';

		csv += orderedColumns.map((column) => column.headerName).join(',') + '\n';

		try {
			const response = await getAllApplications({
				programID,
				pageSize: allApplications.rowCount,
				apiToken
			});

			if ('error' in response) {
				throw new Error('Error getting applications data:', response.error);
			}

			response.data.results.forEach((row) => {
				const rowData = [];

				orderedColumns.forEach((column) => {
					switch (column.field) {
						case 'project_title':
							rowData.push(row.project_title || 'n/a');
							break;
						case 'budgetWorksheet':
							rowData.push(row.budgetWorksheet.totalProposedCost || 0);
							break;
						case 'form_stage_id.start_date':
						case 'form_stage_id.end_date':
							rowData.push(
								row.form_stage_id ? accessNestedPropertyWithString(row, column.field) : ''
							);
							break;
						default:
							rowData.push(row[column.field]);
							break;
					}
				});

				csv += rowData.join(',') + '\n';
			});

			const blob = new Blob([csv], { type: 'text/csv' });
			const fileName = `p-${programID}-applications-${new Date().toLocaleString()}.csv`;

			createAndDownloadFile(blob, fileName);
		} catch (error) {
			console.error(error);
		}
	};

	const programTitle = useIDToTitle('program');

	const exportTableToPDF = async () => {
		const activeFilters = allApplications?.filterModel.map((filter) => {
			const targetColumn = columns.find((column) => column.field === filter.columnField);

			return {
				columnName: targetColumn.headerName,
				operator: filter.operatorValue,
				filterValue: filter.value
			};
		});

		const tableData = [];

		try {
			const response = await getAllApplications({
				programID,
				pageSize: allApplications.rowCount,
				apiToken
			});

			if ('error' in response) {
				throw new Error('Error getting applications data:', response.error);
			}

			response.data.results.forEach((row) => {
				const formattedRowData = {
					projectTitle: row.project_title || 'n/a',
					submitter: row.submitter,
					status: row.status,
					startDate: formatTimestamp(row.form_stage_id?.start_date),
					endDate: formatTimestamp(row.form_stage_id?.end_date),
					budget: row.budgetWorksheet.totalProposedCost || 0
				};

				tableData.push(formattedRowData);
			});

			const blob = await pdf(
				<ApplicationsTemplate
					programTitle={programTitle}
					activeFilters={activeFilters}
					tableData={tableData}
				/>
			).toBlob();
			const fileName = `p-${programID}-applications-${new Date().toLocaleString()}.pdf`;

			createAndDownloadFile(blob, fileName);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<>
			{noApplications ? (
				<Stack sx={{ width: '100%', marginTop: '20px' }} spacing={2}>
					<Alert variant="outlined" severity="info">
						No applications have been received for this program.
						<br />
						Once an application has been submitted, it will appear on this page.
					</Alert>
				</Stack>
			) : isLoading ? (
				<Box sx={{ display: 'flex', margin: '50px 0px', justifyContent: 'center' }}>
					<CircularProgress data-testid={'application-process-loading'} />
				</Box>
			) : appID ? (
				<IndividualApplication appId={appID} />
			) : (
				<>
					<Collapse in={alertBalloon}>
						<Alert
							severity={alertBalloon?.status}
							action={
								<IconButton
									aria-label="close"
									color={alertBalloon?.status}
									size="small"
									onClick={() => {
										setAlertBalloon(false);
									}}
								>
									<CloseIcon fontSize="inherit" />
								</IconButton>
							}
							sx={{ mb: 2, mt: 2 }}
						>
							{alertBalloon?.message}
						</Alert>
					</Collapse>
					<Backdrop
						sx={{ zIndex: (theme) => theme.zIndex.drawer + 5 }}
						open={csvDataIsLoading || csvDataIsFetching}
					>
						<CircularProgress />
					</Backdrop>
					<Table
						columns={columns}
						rowHeight={52}
						enableAutoPageSize
						rows={finalData || []}
						rowCount={allApplications?.rowCount}
						pagination={allApplications?.pagination}
						pageSize={
							allApplications?.pageSize > 0
								? allApplications?.pageSize
								: allApplications?.results?.length
						}
						paginationMode={allApplications?.paginationMode}
						onPageChange={(e) => allApplications?.onPageChange(e)}
						onPageSizeChange={(e) => allApplications?.onPageSizeChange(e)}
						page={allApplications?.page}
						loading={allApplications?.loading}
						sortingMode={allApplications?.sortingMode}
						onSortModelChange={(e) => allApplications?.onSortModelChange(e)}
						onFilterModelChange={(e) => allApplications?.onFilterModelChange(e)}
						filterModel={allApplications?.filterModel}
						sortModel={allApplications?.sortModel}
						emailType={'Application'}
						updateOrderedColumns={updateOrderedColumns}
						tableExportAvailable={tableExportAvailable}
						exportTableToCSV={exportTableToCSV}
						exportTableToPDF={exportTableToPDF}
						emailEndpoint={useGetApplicantEmailsQuery}
					/>
				</>
			)}
		</>
	);
};

export default ManageApplication;

export const BasicCellText = ({
	cellValues,
	openTooltip,
	setOpenTooltip,
	StartIcon,
	isLink,
	isDate
}) => {
	const formattedText = isDate
		? formatTimestamp(cellValues?.formattedValue)
		: cellValues?.formattedValue;

	return (
		<Tooltip
			open={openTooltip === `${cellValues?.field}-${cellValues?.id}`}
			onOpen={(e) => checkOverflow(e) && setOpenTooltip(`${cellValues?.field}-${cellValues?.id}`)}
			onClose={() => setOpenTooltip(0)}
			arrow
			placement="top"
			title={cellValues?.formattedValue ? formattedText : 'n/a'}
		>
			<StyledWrapper.CellContainer
				data-testid={`cellContainer-${cellValues?.field}-${cellValues?.id}`}
			>
				{cellValues?.formattedValue && StartIcon && <StartIcon />}
				{!isLink ? (
					<StyledWrapper.CardRowInfo
						width={'100%'}
						data-testid={`rowInfo-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue ? formattedText : 'n/a'}
					</StyledWrapper.CardRowInfo>
				) : (
					<StyledWrapper.CardRowInfoLink
						width={'100%'}
						data-testid={`rowLink-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue ? formattedText : 'n/a'}
					</StyledWrapper.CardRowInfoLink>
				)}
			</StyledWrapper.CellContainer>
		</Tooltip>
	);
};

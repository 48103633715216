import { useEffect, useState } from 'react';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import StyledWrapper from './style';

export function AppProcessWarnings({ data, isReadyToPublish, assigned = false }) {
	const [message, setMessage] = useState();
	const [infoMessage, setInfoMessage] = useState();

	useEffect(() => {
		let appProcessExists = false;
		let submissionStageExists = false;
		let formExists = false;
		let lastStage = null;
		let allStagesHaveForms = true;
		data?.map((e) => {
			if (e?.type === 'APPLICATION PROCESS' && e?.appId) {
				appProcessExists = true;
			} else if (e?.type === 'Submission Stage') {
				submissionStageExists = true;
			} else if (e?.type === 'Application Form' || e?.type === 'Review Form') {
				formExists = true;
			}

			if (e?.hasForms === false) {
				allStagesHaveForms = false;
			}

			if (e?.type?.includes('Stage')) {
				lastStage = e;
			}
		});

		if (isReadyToPublish) {
			setMessage('');
			setInfoMessage(
				<>
					<StyledWrapper.CustomText data-testid={'warning-title'}>
						The Application was successfully setup and ready to publish.
					</StyledWrapper.CustomText>
				</>
			);
		} else if (assigned) {
			setMessage('');
			setInfoMessage(
				<>
					<StyledWrapper.CustomText data-testid={'warning-title'}>
						If any of them are already assigned to any of the selected stages, they will remain in
						place.
						<br />
						Select stages from below
					</StyledWrapper.CustomText>
				</>
			);
		} else if (lastStage?.type === 'Submission Stage') {
			setMessage('');
			setInfoMessage(
				<>
					<StyledWrapper.CustomText warningText data-testid={'warning-footer'}>
						Stage Blocks need to be closed with an Evaluation Stage in order to publish the
						Application Process.
					</StyledWrapper.CustomText>
				</>
			);
		} else if (!allStagesHaveForms) {
			setMessage('');
			setInfoMessage(
				<>
					<StyledWrapper.CustomText warningText data-testid={'warning-footer'}>
						All Stages require at least 1 form.
					</StyledWrapper.CustomText>
				</>
			);
		} else if (formExists) {
			setMessage('');
			setInfoMessage(
				<>
					<StyledWrapper.CustomText data-testid={'warning-title'}>
						The minimal setup required to publish your solicitation has been achieved.{' '}
					</StyledWrapper.CustomText>
					<ul>
						<li>
							Please continue to add more stages and forms, and set the business logic as needed.
							When done, click the Ready to Publish button in the settings to complete the process.
						</li>
						<li>
							After marking as Ready to Publish, you will be able to publish your solicitation in
							Grants Network.
						</li>
					</ul>
					<StyledWrapper.CustomText warningText data-testid={'warning-footer'}>
						Therefore verify twice if everything is setup correctly before you publish the settings.
					</StyledWrapper.CustomText>
				</>
			);
		} else if (submissionStageExists) {
			setInfoMessage();
			setMessage(
				<StyledWrapper.CustomText warningText>
					The Application Process is not setup.
					<br />
					Add at least a form to the stage
				</StyledWrapper.CustomText>
			);
		} else if (!appProcessExists) {
			setInfoMessage();
			setMessage(
				<StyledWrapper.CustomText warningText>
					The Application Process is not setup.
					<br />
					Either use an Application Process Template, or Start From Scratch.
				</StyledWrapper.CustomText>
			);
		} else {
			setInfoMessage();
			setMessage(
				<StyledWrapper.CustomText warningText>
					The Application Process is not ready to publish
					<br />
					Add at least a SUBMISSION STAGE (the mandatory Core Information Form will be automatically
					attached to your first Submission Stage)
				</StyledWrapper.CustomText>
			);
		}
	}, [data]);

	return (
		(message || infoMessage) && (
			<Stack sx={{ width: '100%', marginBottom: '20px' }} spacing={2}>
				<Alert
					action={
						infoMessage && (
							<IconButton
								data-testid={'warning-close-button'}
								aria-label="close"
								color="inherit"
								size="small"
								onClick={() => {
									setMessage();
									setInfoMessage();
								}}
							>
								{/* <CloseIcon fontSize="inherit" /> */}
							</IconButton>
						)
					}
					variant="outlined"
					severity={infoMessage ? 'info' : 'warning'}
				>
					{infoMessage || message}
				</Alert>
			</Stack>
		)
	);
}

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import { GPM } from '../../services/gpmAPI';

import { formatDateWithTime } from '../../utils/dateUtils';
import { is_within_range } from '../../utils/utilFunctions';
import { getLocalAuth } from '../../utils/environmentUtils';

interface Application {
	[key: string]: unknown;
	startDate: Date | null;
	endDate: Date | null;
	start_date: Date | null;
	end_date: Date | null;
}

type Stage = Application;

interface UpdatedByUser {
	firstName: string;
	lastName: string;
}

export const checkInRange = (
	application: Application,
	stage: Stage,
	override: boolean
): boolean => {
	const stageOrApplicationDates = {
		start_date: stage?.start_date || stage?.startDate || application?.process_start_date,
		end_date: stage?.end_date || stage?.endDate || application?.process_end_date
	};
	return is_within_range(stageOrApplicationDates, override);
};

const gpm = GPM as typeof GPM & {
	endpoints: {
		// @ts-ignore: ignoring to use the endpoints from js file (without types)
		getIndividualApplication: typeof GPM.endpoints.getIndividualApplication;
	};
};

export const useIndividualApplicationData = () => {
	const accountSource = useSelector(
		(state: { login: { accountSource: string } }) => state?.login?.accountSource
	);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const { appID } = useParams();
	const [searchParams] = useSearchParams();
	const formType = searchParams.get('formtype') || '';
	const iFrame = searchParams.get('iFrame') || '';
	const uniqueID = appID?.split('-')?.[1];

	const queryParams = useMemo(
		() => ({
			uniqueID,
			apiToken,
			formType,
			iFrame
		}),
		[uniqueID, apiToken, formType, iFrame]
	);

	return gpm.endpoints.getIndividualApplication.useQueryState(queryParams);
};

export const toFullName = (user: UpdatedByUser): string | '' => {
	if (user.firstName === '' || user.lastName === '') return '';
	return `${user.firstName} ${user.lastName}`;
};

export const getLastEdited = (user: UpdatedByUser, createdOn: string, updatedOn?: string) => {
	const editedBy = toFullName(user);
	const createdOnLabel = formatDateWithTime(createdOn);
	const updatedOnLabel = updatedOn && formatDateWithTime(updatedOn);

	return {
		by: editedBy,
		on: updatedOnLabel || createdOnLabel
	};
};

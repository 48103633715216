import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Box, IconButton } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';

import { getChipColor } from '../../../utils/utilFunctions';
import { getLocalAuth } from '../../../utils/environmentUtils';
import { Table } from '../../../components/Table';
import StyledWrapper2 from '../../IndividualProgram/style';
import StyledWrapper from '../style';
import EmailActionIcons from '../../../assets/customIcons/EmailActionsIcons';
import {
	useInviteTeamMemberMutation,
	useResendInviteMutation,
	useDeleteInviteMutation,
	useRemoveMemberMutation
} from '../../../services/endpoints/projectTeamEndpoints';
import PersonIcon from '../../../assets/customIcons/personIcon';
import usePaginatedCall from '../../../hooks/usePaginatedCall';
import { useGetAllInvitationsQuery } from '../../../services/endpoints/projectTeamEndpoints';
import { formatTimestamp } from '../../../utils/dateUtils';
import { usePermissions } from '../../../hooks/usePermissions';

const ProjectTeam = ({ isNewExperience }) => {
	const path = window.location.pathname;
	const pathSegments = path.split('/');

	const invitationsPagination = usePaginatedCall(useGetAllInvitationsQuery, {
		filterBaseName: 'INVITATIONS'
	});
	const [sendInvite] = useInviteTeamMemberMutation();
	const [resendInvite] = useResendInviteMutation();
	const [deleteInvite] = useDeleteInviteMutation();
	const [removeMember] = useRemoveMemberMutation();

	const tableRef = useRef(null);
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const appID = pathSegments.find((segment) => segment.includes('api-'))?.replace('api-', '');
	const FormContainer = useRef(null);
	const emailInput = useRef(null);
	const is_current_app_creator = useSelector(
		(state) => state?.globalProgramVariables?.is_current_app_creator
	);
	const [allRows, setAllRows] = useState(invitationsPagination?.rows || []);
	const [editingRow, setEditingRow] = useState(false);
	const [sendingLoader, setSendingLoader] = useState(false);
	const [alertBalloon, setAlertBalloon] = useState(false);
	const [headerAlertBalloon, setHeaderAlertBalloon] = useState(true);
	const [isLoading, setLoading] = useState(false);
	const { hasEditPermissions } = usePermissions();
	const [hasTeamMembers, setHasTeamMembers] = useState(false);

	tableRef.current = allRows;

	// Constants
	const [openRows, setOpenRows] = useState([]);
	const [refresh, setRefresh] = useState(true);

	//Functions
	const toggleRow = (cellID) => {
		let findRow = openRows?.findIndex((e) => cellID === e);
		let finalClosedRows = openRows;
		findRow !== -1 ? openRows.splice(findRow, 1) : openRows.push(cellID);
		setOpenRows(finalClosedRows);
		setRefresh(!refresh);
	};

	const addRowCreation = () => {
		if (allRows?.length === 0 || allRows?.[0]?.role !== '') {
			const emptyRole = {
				role: '',
				description: '',
				team_invitation_id: -1
			};
			setAllRows([emptyRole, ...allRows]);
			toggleRow(allRows?.length + 1);
			setEditingRow(emptyRole);
			setHasTeamMembers(true);
		}
	};

	const clearEmptyRows = () => {
		const nonEditingRows = tableRef?.current?.filter((e) => e?.team_invitation_id !== -1);
		setAllRows(nonEditingRows || []);
		setHasTeamMembers(nonEditingRows.length > 0);
	};

	const handleAlert = (message, status) => {
		setAlertBalloon({ isOpen: true, message, status });
		if (status !== 'warning') {
			setTimeout(() => {
				setAlertBalloon(false);
			}, 3000);
		}
	};

	const handleInvitation = () => {
		if (emailInput?.current?.value) {
			const validateEmail = (email) =>
				email.match(
					// eslint-disable-next-line no-useless-escape
					/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				);
			const checkEmail = validateEmail(emailInput?.current?.value);
			if (!checkEmail) {
				handleAlert('Invalid Email Address!', 'error');
				return;
			}
		}
		setSendingLoader(true);
		sendInvite({
			applicationID: appID,
			payload: { email: emailInput?.current?.value },
			apiToken: apiToken
		})
			.unwrap()
			.then(async () => {
				setSendingLoader(false);
				setHasTeamMembers(true);
				handleAlert('Invitation Sent Successfully!', 'success');
			})
			.catch(() => {
				setSendingLoader(false);
				handleAlert('Invitation Sending Failed!', 'error');
			});
	};

	const handleResend = (inviteID) => {
		resendInvite({
			invitationID: inviteID,
			apiToken: apiToken
		})
			.unwrap()
			.then(async () => handleAlert('Invitation Sent Successfully!', 'success'))
			.catch(() => handleAlert('Invitation Sending Failed!', 'error'));
	};

	const handleDelete = (inviteID) => {
		deleteInvite({
			invitationID: inviteID,
			apiToken: apiToken
		})
			.unwrap()
			.then(async () => handleAlert('Invitation Deleted Successfully!', 'success'))
			.catch(() => handleAlert('Invitation Deletion Failed!', 'error'));
	};

	const handleRemoveMember = (memberEmail) => {
		removeMember({
			applicationID: appID,
			payload: { email: memberEmail },
			apiToken: apiToken
		})
			.unwrap()
			.then(async () => handleAlert('Member Removed Successfully!', 'success'))
			.catch(() => handleAlert('Member Removal Failed!', 'error'));
	};

	// This seems to solve a bug but causes flashing
	// Testing without this and no issues encountered
	// Leaving uncommented while split between old and new experiences
	useEffect(() => {
		if (hasEditPermissions === true) {
			setLoading(true);
			setTimeout(function () {
				setLoading(false);
			}, 200);
		}
	}, [hasEditPermissions]);

	useEffect(() => {
		const onlyArchived = invitationsPagination?.rows?.filter((e) => !e?.is_archived);
		setAllRows(onlyArchived || []);
		if (onlyArchived?.length === 0) {
			handleAlert('There are no members added to this project', 'warning');
		}
		setHasTeamMembers(
			invitationsPagination?.isUninitialized ||
				invitationsPagination?.loading ||
				invitationsPagination?.rowCount > 0 ||
				onlyArchived?.length > 0
		);
	}, [invitationsPagination?.rows]);

	// COLUMNS
	let columns = [
		{
			field: 'invitee_email',
			headerName: 'Team Member Email',
			renderCell: (cellValues) => {
				return (
					<StyledWrapper2.NotificationCellContainer
						data-testid={`${cellValues?.field}-${cellValues?.id}`}
						isOpen={openRows.includes(cellValues.id)}
					>
						<StyledWrapper2.ColumnFormat>
							<StyledWrapper.FormHeader readOnly={cellValues?.id !== editingRow?.id} margin={'0px'}>
								{cellValues?.row?.invitee_email ? (
									<Box>{cellValues.value}</Box>
								) : (
									<TextField
										onKeyDown={(event) => {
											event.stopPropagation();
										}}
										required
										inputRef={emailInput}
										id={`projectTeam-emailToInvite`}
										label="Invitee's Email Address"
										defaultValue={cellValues.value}
										color="warning"
										sx={{ width: '100%', backgroundColor: 'white' }}
										data-testid={'projectTeams-description-input'}
									/>
								)}
							</StyledWrapper.FormHeader>
						</StyledWrapper2.ColumnFormat>
					</StyledWrapper2.NotificationCellContainer>
				);
			},
			colSpan: ({ row }) => {
				if (!row?.invitee_email) {
					return 4;
				} else {
					return 1;
				}
			},
			flex: 1,
			editable: false
		},
		{
			field: 'status',
			headerName: 'Status',
			renderCell: (cellValues) => {
				const currentStatus = cellValues?.formattedValue?.toLowerCase();
				let status = `Invitation ${currentStatus[0].toUpperCase() + currentStatus.slice(1)}`;
				const isExpired = new Date() > new Date(cellValues?.row?.expiration);
				status = isExpired ? 'Expired' : status;
				return (
					<>
						{cellValues?.value && (
							<Chip
								data-testid={`${cellValues?.field}-${cellValues?.id}`}
								label={status}
								sx={{
									textTransform: 'capitalize',
									backgroundColor: getChipColor(cellValues?.formattedValue),
									color: cellValues?.formattedValue === 'SENT' ? 'black' : 'white'
								}}
								size="small"
							/>
						)}
					</>
				);
			},
			flex: 1
		},
		{
			field: 'created_at',
			headerName: 'Sent',
			description: 'Sent',
			renderCell: (cellValues) => {
				return (
					<>
						<StyledWrapper.CardRowInfo
							align={'center'}
							data-testid={`${cellValues?.field}-${cellValues?.id}`}
							status={cellValues?.row?.applicationStatus?.toLowerCase() === 'awarded'}
						>
							{cellValues?.formattedValue ? formatTimestamp(cellValues?.formattedValue) : '-'}
						</StyledWrapper.CardRowInfo>
					</>
				);
			},
			flex: 1
		},
		{
			field: 'accepted_at',
			headerName: 'Accepted',
			description: 'Accepted',
			renderCell: (cellValues) => {
				return (
					<>
						<StyledWrapper.CardRowInfo
							align={'center'}
							data-testid={`${cellValues?.field}-${cellValues?.id}`}
							status={cellValues?.row?.applicationStatus?.toLowerCase() === 'awarded'}
						>
							{cellValues?.formattedValue ? formatTimestamp(cellValues?.formattedValue) : '-'}
						</StyledWrapper.CardRowInfo>
					</>
				);
			},
			flex: 1
		}
	];

	(hasEditPermissions || is_current_app_creator) &&
		columns?.push({
			field: 'actions',
			headerName: 'Action',
			description: 'Action',
			renderCell: (cellValues) => {
				const status = cellValues?.row?.status?.toLowerCase();
				return (
					<>
						{!cellValues?.row?.invitee_email ? (
							<div style={{ display: 'flex', gap: '10px' }}>
								<LoadingButton
									loading={sendingLoader}
									data-testid={'projectTeams-saveRole-button'}
									variant="contained"
									onClick={handleInvitation}
								>
									SEND
								</LoadingButton>
								<Button
									variant="outlined"
									onClick={clearEmptyRows}
									data-testid={'projectTeams-cancel-button'}
								>
									CANCEL
								</Button>
							</div>
						) : ['sent', 'rejected', 'expired'].includes(status) ? (
							<>
								<IconButton
									data-testid={'warning-close-button'}
									aria-label="close"
									color="error"
									size="small"
									onClick={() => handleDelete(cellValues?.row?.team_invitation_id)}
								>
									<EmailActionIcons isDelete={true} />
								</IconButton>
								<IconButton
									data-testid={'warning-close-button'}
									aria-label="close"
									color="inherit"
									size="small"
									onClick={() => handleResend(cellValues?.row?.team_invitation_id)}
								>
									<EmailActionIcons isResend={true} />
								</IconButton>
							</>
						) : ['accepted'].includes(status) ? (
							<>
								<IconButton
									data-testid={'warning-close-button'}
									aria-label="close"
									color="inherit"
									size="small"
									onClick={() => handleRemoveMember(cellValues?.row?.invitee_email)}
								>
									<PersonIcon isDelete={true} />
								</IconButton>
							</>
						) : (
							<></>
						)}
					</>
				);
			},
			flex: 1,
			editable: false
		});

	return (
		<>
			{isLoading ? (
				<Box sx={{ display: 'flex', margin: '50px 0px', justifyContent: 'center' }}>
					<CircularProgress data-testid={'application-process-loading'} />
				</Box>
			) : (
				<StyledWrapper.FormContainer ref={FormContainer}>
					<StyledWrapper.FormHeader>
						{isNewExperience && (
							<Collapse in={headerAlertBalloon} sx={{ width: '100%' }}>
								<Alert
									severity={'info'}
									variant={'outlined'}
									action={
										<IconButton
											aria-label="close"
											color="info"
											size="small"
											onClick={() => {
												setHeaderAlertBalloon(false);
											}}
										>
											<CloseIcon fontSize="inherit" />
										</IconButton>
									}
								>
									<StyledWrapper.HeaderAlertText>
										{'Add as many team members as needed.'}
										<br />
										{
											"After that, proceed with the first stage (read stage instructions) and fill out and submit the stage's forms."
										}
									</StyledWrapper.HeaderAlertText>
								</Alert>
							</Collapse>
						)}
						{(hasEditPermissions || is_current_app_creator) && (
							<StyledWrapper.LastEditedActions>
								<Button
									disabled={invitationsPagination?.loading}
									variant="contained"
									onClick={addRowCreation}
									data-testid={'projectTeams-addRole-button'}
								>
									ADD TEAM MEMBER
								</Button>
							</StyledWrapper.LastEditedActions>
						)}
					</StyledWrapper.FormHeader>
					<Collapse in={alertBalloon}>
						<Alert
							severity={alertBalloon?.status}
							action={
								<IconButton
									aria-label="close"
									color={alertBalloon?.status}
									size="small"
									onClick={() => {
										setAlertBalloon(false);
									}}
								>
									<CloseIcon fontSize="inherit" />
								</IconButton>
							}
							sx={{ mb: 2, mt: 2 }}
						>
							{alertBalloon?.message}
						</Alert>
					</Collapse>
					{hasTeamMembers && (
						<Table
							columns={columns}
							enableAutoPageSize
							hideFilters
							disableSelectionOnClick
							customHeight={'50vh'}
							rows={allRows || []}
							getRowId={(cell) => cell?.team_invitation_id}
							rowCount={invitationsPagination?.rowCount}
							pagination={invitationsPagination?.pagination}
							pageSize={invitationsPagination?.pageSize}
							paginationMode={invitationsPagination?.paginationMode}
							onPageChange={(e) => invitationsPagination?.onPageChange(e)}
							onPageSizeChange={(e) => invitationsPagination?.onPageSizeChange(e)}
							page={invitationsPagination?.page}
							loading={invitationsPagination?.loading}
							sortingMode={invitationsPagination?.sortingMode}
							onSortModelChange={(e) => invitationsPagination?.onSortModelChange(e)}
							filterModel={invitationsPagination?.filterModel}
							sortModel={invitationsPagination?.sortModel}
						/>
					)}
				</StyledWrapper.FormContainer>
			)}
		</>
	);
};

export default ProjectTeam;

import StyledWrapper from '../style';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import AppStagesTable from '../components/AppStagesTable/AppStagesTable';
import { Instructions } from '../../../components/FormBuilder/customComponents/Instructions';
import { useGetIndividualAppTable } from '../../../hooks/useGetIndividualAppTable';

const SubmissionStageOverview = ({ appId, subPage, handleAlert, isNewExperience }) => {
	const { data, isLoading: isLoadingSetup } = useGetIndividualAppTable({ appId, subPage });
	const instructions = { content: data?.[0]?.instructions || '' };

	return (
		<>
			{isLoadingSetup ? (
				<>
					<Box
						sx={{
							display: 'flex',
							width: '100%',
							height: '100%',
							alignItems: 'center',
							justifyContent: 'center'
						}}
					>
						<CircularProgress />
					</Box>
				</>
			) : (
				<StyledWrapper.OutermostContainer style={{ width: '100%', gap: '15px' }}>
					<StyledWrapper.InnerContainer>
						<Instructions data={instructions || ''} hideLabel width={'100%'} />
					</StyledWrapper.InnerContainer>

					{!isNewExperience && <AppStagesTable data={data} handleAlert={handleAlert} />}
				</StyledWrapper.OutermostContainer>
			)}
		</>
	);
};

export default SubmissionStageOverview;

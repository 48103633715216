import React, { useState } from 'react';
import { Registry } from '../../FormBuilderLibrary';
import { getElementIcon, stringToHTML } from '../utils';

import StyledWrapper from './style';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

export const MUIDropdown = React.forwardRef((props, ref) => {
	const { name, defaultValue, disabled, isFormBuilder } = props;
	const [actualValue, setActualValue] = useState(
		props?.data?.options?.find((e) => e?.key === defaultValue)
	);

	const parsedLabel = React.useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = React.useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);

	const styleForNotFormBuilder = !isFormBuilder
		? { width: '100%', marginTop: '16px' }
		: { width: '100%' };

	return (
		<>
			<FormControl fullWidth>
				<StyledWrapper.CustomLabel
					value={parsedLabel?.[0] ? parsedLabel : props?.data?.label}
					disabled={disabled ? true : false}
					required={props?.data?.required}
					InputLabelProps={{ shrink: isFormBuilder || undefined }}
					shrink={isFormBuilder || undefined}
				>
					{isFormBuilder && getElementIcon(props.data?.key)}
					{props?.data?.label}
					{isFormBuilder && (
						<div>
							{props.data?.unique_identifier
								? `(ff-${props.data?.unique_identifier})`
								: `(tmp-${props.data?.temp_order})`}
						</div>
					)}
				</StyledWrapper.CustomLabel>
				<Select
					sx={styleForNotFormBuilder}
					error={props?.data?.error}
					inputProps={{ ref: ref, actualValue: actualValue?.value }}
					id={`DropdownID_${props?.field_name}`}
					disabled={disabled}
					defaultValue={defaultValue}
					onChange={(x) =>
						setActualValue(props?.data?.options?.find((e) => e?.key === x?.target?.value))
					}
					name={name}
				>
					{props?.data?.options?.map((e) => {
						return (
							<MenuItem key={e?.key} value={e?.key}>
								{e?.text}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
			<FormHelperText error={props?.data?.error}>
				{props?.data?.error
					? props?.data?.error?.error
					: parsedHelperText?.[0]
					? parsedHelperText
					: props?.data?.help_message}
			</FormHelperText>
		</>
	);
});

MUIDropdown.displayName = 'MUI_Dropdown';
Registry.register('MUI_Dropdown', MUIDropdown);

import { GPM } from '../gpmAPI';
import { composeSearchParams, getFinalQuery } from '../utils';

const programsApi = GPM.injectEndpoints({
	tagTypes: ['Stages', 'ApplicationProcess', 'IndividualForms'],
	endpoints: (builder) => ({
		getAllPrograms: builder.query({
			query: (props) => {
				const { finalQuery, page, pageSize: page_size } = props || {};
				const searchParams = composeSearchParams({
					page,
					page_size
				});

				return {
					url: `programs?${searchParams}${getFinalQuery(finalQuery)}`,
					method: 'GET',
					headers: {
						Authorization: `Bearer ${props?.apiToken}`,
						'Cache-Control': 'no-cache, no-store'
					}
				};
			}
		}),
		getIndividualPrograms: builder.query({
			query: (props) => ({
				url: `programs/${props.id}/`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			}),
			providesTags: ['Stages', 'ApplicationProcess', 'IndividualForms']
		}),
		getProgramApplications: builder.query({
			query: (props) => {
				const { finalQuery, page, pageSize: page_size } = props || {};
				const searchParams = composeSearchParams({
					page,
					page_size
				});

				return {
					url: `applications/program/${props.programID}/?${searchParams}${getFinalQuery(
						finalQuery
					)}`,
					method: 'GET',
					headers: {
						Authorization: `Bearer ${props?.apiToken}`
					}
				};
			},
			providesTags: ['Stages', 'ApplicationProcess', 'IndividualForms']
		}),
		getUserApplications: builder.query({
			query: (props) => ({
				url: `applications/user/?page=${props?.page}&page_size=${props?.pageSize}${
					props?.finalQuery || ''
				}`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			}),
			providesTags: ['Stages', 'ApplicationProcess', 'IndividualForms']
		}),
		getApplicantEmails: builder.query({
			query: (props) => ({
				url: `applications/program/${props?.id}/email_list?${props?.finalQuery || ''}`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			}),
			providesTags: ['Stages', 'ApplicationProcess', 'IndividualForms']
		}),
		getUserReviews: builder.query({
			query: (props) => ({
				url: `applications/user/reviews/?page=${props?.page}&page_size=${props?.pageSize}${
					props?.finalQuery || ''
				}`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			}),
			providesTags: ['Stages', 'ApplicationProcess', 'IndividualForms']
		}),
		getGoalsAndMetrics: builder.query({
			query: (props) => ({
				url: `programs/${props.id}/goals_and_metrics`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			})
		}),
		getIndividualApplication: builder.query({
			query: (props) => {
				const { formType, iFrame } = props || {};
				const searchParams = composeSearchParams({
					formtype: formType,
					iFrame
				});
				return {
					url: `/applications/by_unique_id/${props.uniqueID}/?${searchParams}`,
					method: 'GET',
					headers: {
						Authorization: `Bearer ${props?.apiToken}`
					}
				};
			},
			providesTags: ['Stages', 'ApplicationProcess', 'IndividualForms']
		}),
		getApplicationProcess: builder.query({
			query: (props) => ({
				url: `form/application-process/${props.programID}/`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			}),
			providesTags: ['Stages', 'ApplicationProcess', 'IndividualForms']
		}),
		createApplicationProcess: builder.mutation({
			query: (props) => ({
				url: `form/application-process/`,
				method: 'POST',
				body: props?.payload,
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			}),
			invalidatesTags: ['Stages', 'ApplicationProcess']
		}),
		updateApplicationProcess: builder.mutation({
			query: (props) => ({
				url: `form/application-process/${props.id}/`,
				method: 'PATCH',
				body: props?.payload,
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				invalidatesTags: ['Stages', 'ApplicationProcess']
			}),
			invalidatesTags: ['Stages', 'ApplicationProcess']
		}),
		deleteApplicationProcess: builder.mutation({
			query: (props) => ({
				url: `form/application-process/${props.id}/`,
				method: 'DELETE',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			}),
			invalidatesTags: ['Stages', 'ApplicationProcess']
		}),
		publishApplicationProcess: builder.mutation({
			query: (props) => ({
				url: `form/application-process/${props.id}/ready-to-publish/`,
				method: 'PATCH',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			}),
			invalidatesTags: ['Stages', 'ApplicationProcess']
		}),
		saveCoreInfo: builder.mutation({
			query: (props) => ({
				url: `applications/core-info/${props?.application_id}/save_core_info/`,
				method: 'POST',
				body: props?.payload,
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			})
		}),
		getApplicationsPieChart: builder.query({
			query: (props) => ({
				url: `/applications/status_counts/${props?.programID}/`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			}),
			providesTags: ['Stages', 'ApplicationProcess', 'IndividualForms']
		}),
		getReviewsPieChart: builder.query({
			query: (props) => ({
				url: `/applications/reviewers_status_counts/${props?.programID}/`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			}),
			providesTags: ['Stages', 'ApplicationProcess', 'IndividualForms']
		})
	}),
	overrideExisting: false
});

export const {
	useGetAllProgramsQuery,
	useLazyGetAllProgramsQuery,
	useGetIndividualProgramsQuery,
	useGetGoalsAndMetricsQuery,
	useGetProgramApplicationsQuery,
	useLazyGetProgramApplicationsQuery,
	useGetApplicantEmailsQuery,
	useGetApplicationProcessQuery,
	useGetApplicationProcessLazyQuery,
	useGetUserApplicationsQuery,
	useGetUserReviewsQuery,
	useCreateApplicationProcessMutation,
	useUpdateApplicationProcessMutation,
	useDeleteApplicationProcessMutation,
	usePublishApplicationProcessMutation,
	useGetIndividualApplicationQuery,
	useSaveCoreInfoMutation,
	useGetApplicationsPieChartQuery,
	useGetReviewsPieChartQuery
} = programsApi;

import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams, useParams } from 'react-router-dom';

import { ProcessColumns, groupingColDef } from './processColumns';
import { IconButton, CircularProgress, Box, Alert, Collapse } from '@mui/material';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import CloseIcon from '@mui/icons-material/Close';
import { STAGE_APPLICATION } from './constants';
import { clearMetaInfo } from '../../../../components/FormBuilderLibrary/slice';
import { Table } from '../../../../components/Table';
import StyledWrapper from '../style';
import { AppProcessWarnings } from '../../../../components/AppProcessWarnings/AppProcessWarnings';
import { FormBuilderModal } from '../../../FormBuilderModal/FormBuilderModal';
import StageCreation from '../../../StageCreationModal/StageCreation';
import { useApplicationSetup } from '../../../../hooks/useApplicationSetup';
import { DeleteDialog } from '../../../../components/DeleteDialog';
import { usePublishApplicationProcessMutation } from '../../../../services/endpoints/programsEndpoints';
import SaveDialog from '../../../../components/SaveDialog/SaveDialog';
import LastEdited from '../../../../components/LastEdited/LastEdited';
import { usePermissions } from '../../../../hooks/usePermissions';
import ChooseTemplateDialog from '../../../../components/ChooseTemplateDialog/ChooseTemplateDialog';
import { checkProcessValidDates } from '../../../../utils/utilFunctions';
import { getLocalAuth } from '../../../../utils/environmentUtils';
import { APP_TYPE_APPLICATION_PROCESS, STAGE_CREATION_PARAM, STAGE_PROCESS } from './constants';
import WarningModal from '../../../../components/WarningModal/WarningModal';
import {
	FORM_ADDITION_WARNING_LIST,
	FORM_ADDITION_PUBLISHED_WARNING_MESSAGE,
	FORM_ADDITION_READY_TO_PUBLISH_WARNING_MESSAGE,
	FORM_ADDITION_PUBLISHED_WARNING_TITLE,
	FORM_ADDITION_READY_TO_PUBLISH_WARNING_TITLE,
	FORM_ADDITION_CONFIRMATION_MESSAGE
} from '../../../../constants/appProcessEditWarningMessage';

const ApplicationProcess = () => {
	const FormContainer = useRef(null);
	const dispatch = useDispatch();
	let { programID } = useParams();
	programID = programID?.replace('p-', '');

	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const [searchParams, setSearchParams] = useSearchParams();
	const [columnWidth, setColumnWidth] = useState({});
	const [tableRef, setTableRef] = useState();
	const [warningModal, setWarningModal] = useState(false);
	const { data: setupRows, isLoading: isLoadingSetup, refetch } = useApplicationSetup();
	const programStatus = setupRows[0]?.status;
	const [publishProcess] = usePublishApplicationProcessMutation();

	const [outerLoading, setOuterLoading] = useState(false);
	const [allIndividualForms, setAllIndividualForms] = useState([]);
	const [hasAppProcess, setHasAppProcess] = useState(false);
	const [hasAppForms, setHasAppForms] = useState(false);
	const [allStagesHaveForms, setStagesHaveForms] = useState(false);
	const [editingRow] = useState(false);
	const [openFormModal, setOpenFormModal] = useState(false);
	const [openStageCreation, setOpenStageCreation] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [finalData, setFinalData] = useState(setupRows || []);
	const [formsHighestOrder, setFormsHighestOrder] = useState(0);
	const [saveModal, setSaveModal] = useState(false);
	const [localIsReadyToPublish, setLocalIsReadyToPublish] = useState(false);
	const [openTooltip, setOpenTooltip] = useState(0);
	const [startFromTemplate, setStartFromTemplate] = useState();
	const [alertBalloon, setAlertBalloon] = useState(false);
	const [lastStage, setLastStage] = useState();
	const [isApplicationStarted, setIsApplicationStarted] = useState();
	const { hasEditPermissions } = usePermissions();
	const initialColumns = ProcessColumns(columnWidth, openTooltip, setOpenTooltip, setWarningModal);
	const warningModalTitle =
		programStatus === 'Published'
			? FORM_ADDITION_PUBLISHED_WARNING_TITLE
			: FORM_ADDITION_READY_TO_PUBLISH_WARNING_TITLE;
	const warningModalContent =
		programStatus === 'Published'
			? FORM_ADDITION_PUBLISHED_WARNING_MESSAGE
			: FORM_ADDITION_READY_TO_PUBLISH_WARNING_MESSAGE;
	const isReadyToPublish = setupRows?.[0]?.isReadyToPublish || false;

	const applicationProecssStatus = setupRows?.[0]?.status;
	const isPublishable =
		!hasAppForms || !allStagesHaveForms || lastStage.type === 'Submission Stage';

	const handleValidateDate = () => {
		const invalidEntities = checkProcessValidDates(setupRows);
		if (invalidEntities?.length === 0) {
			setSaveModal({ isOpen: true });
		} else {
			handleAlert(
				`The Following Entities have invalid dates: ${invalidEntities?.map((e) => `[${e}]`)}`,
				'error'
			);
		}
	};

	const handlePublish = () => {
		let endpointOptions = {
			id: programID,
			apiToken: apiToken
		};

		publishProcess(endpointOptions)
			.unwrap()
			.then(async () => {
				setSaveModal({ ...saveModal, isLoading: false, isSuccess: true });
				setLocalIsReadyToPublish(true);
			})
			.catch(() => setSaveModal({ ...saveModal, isLoading: false, isError: true }));
	};

	const handleAlert = (message, status) => {
		setAlertBalloon({ isOpen: true, message, status });
		setTimeout(() => {
			setAlertBalloon(false);
		}, 10000);
	};

	useEffect(() => {
		setOuterLoading(true);
		setTimeout(function () {
			setOuterLoading(false);
		}, 200);
	}, [initialColumns]);

	useEffect(() => {
		const isFormModal = searchParams.get('formBuilder');
		isFormModal && setOpenFormModal(isFormModal);

		const isStageCreation = searchParams.get(STAGE_CREATION_PARAM);
		isStageCreation && setOpenStageCreation(isStageCreation);

		const isTemplateModal = searchParams.get('templatesModal');
		isTemplateModal && setStartFromTemplate(isTemplateModal);
	}, [searchParams]);

	// Constants
	const getTreeDataPath = (row) => row.hierarchy;

	useEffect(() => {
		setRefresh(!refresh);

		const individualForms = setupRows?.filter((e) => {
			if (['Submission Stage', 'Evaluation Stage'].includes(e?.type)) {
				setLastStage(e);
			}
			const isForm = e?.type === 'Application Form' || e?.type === 'Review Form';
			if (isForm) {
				return e;
			}
		});
		setFinalData(setupRows);
		setAllIndividualForms(individualForms);
		setIsApplicationStarted(setupRows?.[0]?.appId || false);
	}, [setupRows, searchParams]);

	useEffect(() => {
		let highestNumber = 0;

		allIndividualForms?.forEach((e) => {
			const current = parseInt(e?.order);

			highestNumber = current > highestNumber ? current : highestNumber;
		});
		setFormsHighestOrder(highestNumber);
	}, [allIndividualForms]);

	useEffect(() => {
		let finalHasAppProcess = false;
		let hasApplicationForm = false;
		let allStagesHaveForms = true;
		tableRef &&
			tableRef?.current?.getRowModels().forEach((value) => {
				if (value?.type === APP_TYPE_APPLICATION_PROCESS && value?.appId) {
					finalHasAppProcess = true;
				} else if (value?.type === 'Application Form') {
					hasApplicationForm = true;
				}

				if (value?.hasForms === false) {
					allStagesHaveForms = false;
				}
			});
		setHasAppProcess(finalHasAppProcess);
		setHasAppForms(hasApplicationForm);
		setStagesHaveForms(allStagesHaveForms);
	}, [tableRef, finalData]);

	useEffect(() => {}, [saveModal]);

	const handleConfirm = () => {
		const newParams = { ...searchParams };

		if (warningModal.stageCreation) {
			newParams[STAGE_CREATION_PARAM] = warningModal.stageCreation;
		} else if (warningModal.templatesModal) {
			newParams.templatesModal = warningModal.templatesModal;
			newParams.stageId = warningModal.stageId;
		} else if (warningModal.formBuilder) {
			newParams.formBuilder = warningModal.formBuilder;
			newParams.formId = warningModal.form_id;
			newParams.stageId = warningModal.stageId;
		}
		dispatch(clearMetaInfo());
		setSearchParams(newParams);

		setWarningModal(false);
	};
	return (
		<>
			{!isLoadingSetup && !outerLoading ? (
				<StyledWrapper.FormContainer ref={FormContainer}>
					{(!isReadyToPublish || localIsReadyToPublish) && (
						<AppProcessWarnings
							data={setupRows}
							isReadyToPublish={isReadyToPublish || localIsReadyToPublish}
						/>
					)}
					{isApplicationStarted && (
						<StyledWrapper.FormHeader>
							<LastEdited data={setupRows?.[0]?.lastEdited} editingRow={editingRow} />
							{hasEditPermissions && (
								<StyledWrapper.LastEditedActions>
									{/* <IconButton aria-label="moreOptions">
										<MoreHorizIcon />
									</IconButton> */}
									<StyledWrapper.ReadyToPublishButton
										isReadyToPublish={localIsReadyToPublish || isReadyToPublish}
										status={applicationProecssStatus}
										disabled={isPublishable}
										variant="outlined"
										startIcon={<UpgradeIcon />}
										data-testid={'applicationprocess-publish-button'}
										onClick={handleValidateDate}
									>
										Ready To Publish
									</StyledWrapper.ReadyToPublishButton>
								</StyledWrapper.LastEditedActions>
							)}
						</StyledWrapper.FormHeader>
					)}
					<Collapse in={alertBalloon}>
						<Alert
							severity={alertBalloon?.status}
							action={
								<IconButton
									aria-label="close"
									color={alertBalloon?.status}
									size="small"
									onClick={() => {
										setAlertBalloon(false);
									}}
								>
									<CloseIcon fontSize="inherit" />
								</IconButton>
							}
							sx={{ mb: 2, mt: 2 }}
						>
							{alertBalloon?.message}
						</Alert>
					</Collapse>
					{isApplicationStarted && (
						<Table
							customHeight={!hasAppProcess ? '200px' : '80vh'}
							enableAutoPageSize
							treeData
							getTreeDataPath={getTreeDataPath}
							disableSelectionOnClick
							columns={initialColumns}
							rows={finalData}
							groupingColDef={() => groupingColDef(columnWidth)}
							rowHeight={true}
							onColumnResize={(e) => {
								const newColumnSizes = e?.colDef?.field;
								const width = e?.width;
								setColumnWidth({ ...columnWidth, [newColumnSizes]: width });
							}}
							setOuterApiRef={setTableRef}
						/>
					)}
					{!hasAppProcess && hasEditPermissions && (
						<StyledWrapper.ButtonContainer>
							<StyledWrapper.SetupButton
								variant="contained"
								onClick={() => setStartFromTemplate('application')}
							>
								PICK AN APPLICATION PROCESS TEMPLATE
							</StyledWrapper.SetupButton>
							<StyledWrapper.SetupButton
								variant="contained"
								onClick={() => {
									dispatch(clearMetaInfo());
									setSearchParams({ ...searchParams, [STAGE_CREATION_PARAM]: STAGE_PROCESS });
								}}
							>
								START FROM SCRATCH
							</StyledWrapper.SetupButton>
						</StyledWrapper.ButtonContainer>
					)}
				</StyledWrapper.FormContainer>
			) : (
				<Box sx={{ display: 'flex', margin: '50px 0px', justifyContent: 'center' }}>
					<CircularProgress data-testid={'application-process-loading'} />
				</Box>
			)}

			{openFormModal && (
				<FormBuilderModal
					closeModal={() => {
						dispatch(clearMetaInfo());
						setOpenFormModal(false);
					}}
					totalForms={formsHighestOrder}
					programStatus={programStatus}
				/>
			)}

			{openStageCreation && (
				<StageCreation
					appProcessData={finalData}
					closeModal={() => setOpenStageCreation(false)}
					handleAlertOuter={handleAlert}
					refetch={refetch}
				/>
			)}
			{warningModal && (
				<WarningModal
					open={warningModal}
					title={warningModalTitle}
					content={warningModalContent}
					listItems={FORM_ADDITION_WARNING_LIST}
					confirmationMessage={FORM_ADDITION_CONFIRMATION_MESSAGE}
					handleConfirm={handleConfirm}
					handleClose={() => setWarningModal(false)}
				/>
			)}
			{hasEditPermissions && (
				<DeleteDialog refetch={refetch} appProcessData={finalData} handleAlert={handleAlert} />
			)}

			{saveModal && hasEditPermissions && (
				<SaveDialog
					isOpen={saveModal?.isOpen}
					title={'Mark as Ready to Publish'}
					isReadyToPublish
					loading={saveModal?.isLoading}
					isSuccess={saveModal?.isSuccess}
					isError={saveModal?.isError}
					handleClose={() => {
						setSaveModal(false);
					}}
					handleSave={() => handlePublish()}
				/>
			)}

			{startFromTemplate && (
				<ChooseTemplateDialog
					isOpen={startFromTemplate}
					type={startFromTemplate}
					handleClose={() => {
						setSearchParams({});
						setStartFromTemplate(false);
					}}
					handleAlert={handleAlert}
					currentAppProcess={finalData}
				/>
			)}
		</>
	);
};

export default ApplicationProcess;

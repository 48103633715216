import StyledWrapper from './style';

import { Button, DialogActions, DialogContentText } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
const WarningModal = ({
	title,
	content,
	listItems = [],
	confirmationMessage,
	open,
	handleClose,
	handleConfirm
}) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<>
			<StyledWrapper.CustomDialog
				disableEscapeKeyDown
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
			>
				<StyledWrapper.CustomDialogTitle id="responsive-dialog-title">
					<WarningIcon color={'warning'} />
					{title}
				</StyledWrapper.CustomDialogTitle>
				<StyledWrapper.CustomDialogContent>
					<DialogContentText sx={{ fontWeight: '500' }}>
						{content}
						<br />
						<ul>
							{listItems.map((item, index) => (
								<li key={index} sx={{ listStyleType: 'disc', pl: 2 }}>
									{item}
								</li>
							))}
						</ul>
						{confirmationMessage}
					</DialogContentText>
				</StyledWrapper.CustomDialogContent>
				<DialogActions style={{ justifyContent: 'center' }}>
					<Button size="large" variant="outlined" color="warning" onClick={handleConfirm} autoFocus>
						YES
					</Button>
					<Button size="large" variant="contained" autoFocus onClick={handleClose}>
						NO
					</Button>
				</DialogActions>
			</StyledWrapper.CustomDialog>
		</>
	);
};

export default WarningModal;

import styled from 'styled-components';

import { ListItemButton } from '@mui/material';

import { getEunaOrangeColor } from '../../utils/utilFunctions';

interface ContainerProps {
	customInnerWidth: string;
}

interface NavButtonProps {
	selected: boolean;
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: no-wrap;
	width: ${(props: ContainerProps) => props.customInnerWidth};
	overflow: hidden;
	padding-right: 0px;
	gap: 5px;

	& .individualStage:after {
		width: 14px;
		height: 29px;
		background-color: transparent;
		position: absolute;
		top: -16px;
		left: -12px;
		border-left: 1px solid rgba(0, 0, 0, 0.6);
		border-bottom: 1px solid rgba(0, 0, 0, 0.6);
	}
`;

const NavButton = styled(ListItemButton)`
	max-width: 300px;
	align-items: center;
	padding-left: 0px;
	border-right: ${(props: NavButtonProps) =>
		props.selected ? `2px solid ${getEunaOrangeColor()}` : '0px'};
	& .mui-selected: {
		background-color: transparent;
	}
`;

const NavText = styled.span`
	margin-left: 6px;
	font-size: 14px;
	line-height: 24px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 144px;
`;

const RequiredIndicator = styled.span`
	color: var(--Light-Warning-Main, #ed6c02);
	font-feature-settings: 'liga' off, 'clig' off;

	/* Typography/Body 2 */
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 143%; /* 20.02px */
	letter-spacing: 0.17px;

	padding-right: 8px;
`;

export default {
	Container,
	NavButton,
	NavText,
	RequiredIndicator
};

/**
 * <FormValidator />
 */

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Alert from '@mui/material/Alert';
import xss from 'xss';
import { setFormButtonLoadingHasChanged } from '../../components/FormBuilderLibrary/slice';
import IntlMessages from './language-provider/IntlMessages';

const myxss = new xss.FilterXSS({
	whiteList: {
		u: [],
		br: [],
		b: [],
		i: [],
		ol: ['style'],
		ul: ['style'],
		li: [],
		p: ['style'],
		sub: [],
		sup: [],
		div: ['style'],
		em: [],
		strong: [],
		span: ['style']
	}
});

const FormValidator = (props) => {
	const [errors, setErrors] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		setErrors(props?.errors);
	}, [props]);

	const dismissModal = (e) => {
		e.preventDefault();
		setErrors([]);
	};
	const finalErrors = errors?.map((error, index) => (
		<li key={`error_${index}`} dangerouslySetInnerHTML={{ __html: myxss.process(error) }} />
	));
	useEffect(() => {
		if (finalErrors?.length > 0) {
			dispatch(setFormButtonLoadingHasChanged(false));
		}
	}, [finalErrors]);
	return (
		<div>
			{
				finalErrors?.length > 0 && (
					<Alert style={{ marginBottom: '30px' }} onClose={dismissModal} severity="error">
						This form contains errors. Please see below.
					</Alert>
				)

				// (
				// 	<div className="alert alert-danger validation-error">
				// 		<div className="clearfix">
				// 			<i className="fas fa-exclamation-triangle float-left"></i>
				// 			<ul className="float-left">{finalErrors}</ul>
				// 		</div>
				// 		<div className="clearfix">
				// 			<a className="float-right btn btn-default btn-sm btn-danger" onClick={dismissModal}>
				// 				<IntlMessages id="dismiss" />
				// 			</a>
				// 		</div>
				// 	</div>
				// )
			}
		</div>
	);
};

export default FormValidator;

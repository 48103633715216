import { useState } from 'react';

import Tooltip from '@mui/material/Tooltip';
import PersonIcon from '@mui/icons-material/Person';
import LaunchIcon from '@mui/icons-material/Launch';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';

import StyledWrapperTable from '../../pages/IndividualProgram/style';
import { AssignmentsButton } from '../ActionButtons/ActionButtons';
import SaveDialog from '../SaveDialog/SaveDialog';
import { useAssignReviewMutation } from '../../services/endpoints/reviewEndpoints';
import { getGNURL, getLocalAuth } from '../../utils/environmentUtils';
import { useSelector } from 'react-redux';

const AssignReviewColumns = (
	openTooltip,
	setOpenTooltip,
	noActions,
	chosenReviews,
	setOuterReviewer,
	tableName,
	stageID,
	handleReviewerSelect,
	isBulk
) => {
	let currentReviewer = '';
	const columns = [];
	columns?.push(
		...[
			{
				field: 'name',
				headerName: 'Name',
				renderCell: (cellValues) => {
					return (
						<Tooltip
							arrow
							placement="top"
							width={'200px'}
							title={
								<StyledWrapperTable.CenteredText>
									{`${cellValues?.row?.first_name} ${cellValues?.row?.last_name}`}
								</StyledWrapperTable.CenteredText>
							}
						>
							{tableName === 'Assigned' ? (
								<StyledWrapperTable.CellContainer margin={!cellValues?.formattedValue}>
									<PersonIcon color={'primary'} />

									<StyledWrapperTable.CardRowInfo
										width={'100%'}
										data-testid={`${cellValues?.field}-${cellValues?.id}`}
									>
										{`${cellValues?.row?.first_name} ${cellValues?.row?.last_name}`}
									</StyledWrapperTable.CardRowInfo>
								</StyledWrapperTable.CellContainer>
							) : (
								<StyledWrapperTable.CellContainer margin={!cellValues?.formattedValue}>
									<PersonIcon color={'#0000008a'} />

									<StyledWrapperTable.CardRowInfo
										width={'100%'}
										data-testid={`${cellValues?.field}-${cellValues?.id}`}
									>
										{`${cellValues?.row?.first_name} ${cellValues?.row?.last_name}`}
									</StyledWrapperTable.CardRowInfo>
								</StyledWrapperTable.CellContainer>
							)}
						</Tooltip>
					);
				},
				flex: 1,
				minWidth: 200
			},
			{
				field: 'total_reviews',
				headerName: 'Total Assigned',
				flex: 1,
				renderCell: (cellValues) => {
					return <>{cellValues?.formattedValue || '0'}</>;
				}
			},
			{
				field: 'not_started',
				headerName: 'Not Started',
				flex: 1,
				renderCell: (cellValues) => {
					return <>{cellValues?.formattedValue || '0'}</>;
				}
			},
			{
				field: 'in_progress',
				headerName: 'In Progress',
				flex: 1,
				renderCell: (cellValues) => {
					return <>{cellValues?.formattedValue || '0'}</>;
				}
			},
			{
				field: 'submitted',
				headerName: 'Submitted (since inception)',
				flex: 1,
				renderCell: (cellValues) => {
					return <>{cellValues?.formattedValue || '0'}</>;
				}
			}
		]
	);

	!noActions &&
		columns.push({
			field: 'action',
			headerName: 'Actions',
			description: 'Actions',
			renderCell: (cellValues) => {
				return (
					<div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
						{tableName === 'Reviewer Pool' ? (
							<AssignReviewButton
								isBulk={isBulk}
								stageID={stageID}
								reviewerID={cellValues?.row?.reviewer_id}
								onClick={() => handleReviewerSelect(cellValues?.row)}
							/>
						) : chosenReviews ? (
							<Tooltip title="Remove from assigning list" placement="top">
								<IconButton
									isBulk={isBulk}
									onClick={() => handleReviewerSelect(cellValues?.row)}
									aria-label="Remove from assigning list"
									color="secondary"
								>
									<img src={'/images/remove.svg'}></img>
								</IconButton>
							</Tooltip>
						) : (
							<AssignmentsButton stageID={stageID} item={cellValues?.row} />
						)}
					</div>
				);
			},
			minWidth: 450
		});

	return columns;
};

export default AssignReviewColumns;

export const AssignReviewButton = ({ onClick, isBulk, reviewerID, stageID }) => {
	const [assignReviewer] = useAssignReviewMutation();
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const [saveModal, setSaveModal] = useState(false);
	const handleAssignReviewer = () => {
		const options = {
			uniqueID: stageID?.replace('esi-', ''),
			reviewerID: reviewerID,
			apiToken: apiToken
		};
		assignReviewer(options)
			.unwrap()
			.then(async () => {
				setSaveModal({ ...saveModal, isLoading: false, isSuccess: true });
			})
			.catch(() => setSaveModal({ ...saveModal, isLoading: false, isError: true }));
	};

	return (
		<>
			<Tooltip title="Add to assigning list" placement="top">
				<IconButton
					onClick={(e) => (isBulk ? onClick(e) : setSaveModal({ isOpen: true }))}
					variant="outlined"
					style={{ display: 'flex', borderRadius: 28, MaxWidth: 20 }}
					aria-label="fingerprint"
				>
					<span>
						<img src={'/images/add.svg'} />
					</span>
				</IconButton>
			</Tooltip>

			{saveModal && (
				<SaveDialog
					isOpen={saveModal?.isOpen}
					isYesNo
					title={'Assign Reviewer'}
					loading={saveModal?.isLoading}
					isSuccess={saveModal?.isSuccess}
					isError={saveModal?.isError}
					handleClose={() => {
						setSaveModal(false);
					}}
					handleSave={() => handleAssignReviewer(saveModal?.status)}
				/>
			)}
		</>
	);
};

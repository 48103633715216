import { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { stringLengthMax } from '../../utils/utilFunctions';

import StyledWrapper from './style';

import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import LaunchIcon from '@mui/icons-material/Launch';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import CheckIcon from '@mui/icons-material/Check';

import SubmissionStageIcon from '../../assets/customIcons/submissionStageIcon';
import ReviewStageIcon from '../../assets/customIcons/reviewStageIcon';
import AppFormIcon from '../../assets/customIcons/appFormIcon';

import { getChipColor } from '../../utils/utilFunctions';

const MAX_CHIP_CHARACTERS = 11;

export const isActiveId = (id, activeView) => {
	const url = window.location.href;

	return (
		id === activeView ||
		url.includes(`${id}/details`) ||
		url.includes(`${id}/content`) ||
		url.includes(`${id}-group/details`)
	);
};

// Separated VerticalNavigation out to allow migrating to New Experience
// without having to sprinkle conditional rendering throughout
export default function VerticalNavigationNE({ customInnerWidth, data, navigateTo, setSubTitle }) {
	const location = useLocation();

	const pathArray = location?.pathname?.split('/');
	const activeLink = pathArray[pathArray?.length - 1]?.replaceAll('%20', ' ');

	const annotatedData = data?.map((item) => {
		const isActive = isActiveId(item?.id, activeLink);
		isActive && setSubTitle(item?.title ?? '');
		return {
			...item,
			isActive
		};
	});

	return (
		<StyledWrapper.Container customInnerWidth={customInnerWidth}>
			<List>
				{annotatedData?.map((item) => {
					const itemTitle = item?.title ?? '';
					const indent = item.isChild ? 2 : item.isGrandchild ? 4 : 0;
					const isSubmissionStage = item?.id?.toLowerCase().includes('ssi-');
					const isEvaluationStage = item?.id?.toLowerCase().includes('esi-');

					return item?.isLink ? (
						<VerticalNavLink
							active={item.isActive}
							text={itemTitle}
							status={item?.status}
							onClick={(e) => navigateTo(item?.link || e)}
							isTeam={item?.isTeam}
							isBudgetRequired={item?.isBudgetRequired}
							isBudgetSet={item?.isBudgetSet}
							isBudget={item?.isBudget}
							isGoals={item?.isGoals}
						/>
					) : (
						<StyledWrapper.NavButton
							selected={item.isActive}
							onClick={() => navigateTo(item?.link || item?.id)}
						>
							<ListItemIcon sx={{ width: '80px', justifyContent: 'right' }}>
								<Tooltip
									title={item?.status?.length > MAX_CHIP_CHARACTERS ? item?.status : ''}
									arrow
									placement="top"
								>
									<Chip
										label={item?.status}
										sx={{
											backgroundColor: getChipColor(item?.status),
											color: 'white',
											'& .MuiChip-label': {
												fontFamily: 'Roboto',
												fontSize: '12px',
												fontWeight: '500',
												lineHeight: '20px',
												letterSpacing: '0.14px'
											}
										}}
										size="small"
									/>
								</Tooltip>
							</ListItemIcon>
							<ListItemText
								sx={{
									width: '220px',
									marginLeft: '16px',
									display: 'flex',
									alignItems: 'center',
									pl: indent
								}}
							>
								<div style={{ display: 'flex' }}>
									{isSubmissionStage && (
										<SubmissionStageIcon
											width={24}
											customColor={getChipColor(item?.status)}
											isActive
											isCurrentStage={item?.is_current_stage}
										/>
									)}

									{isEvaluationStage && (
										<ReviewStageIcon
											width={24}
											customColor={getChipColor(item?.status)}
											isActive
											isCurrentStage={item?.is_current_stage}
										/>
									)}

									{!isSubmissionStage && !isEvaluationStage && (
										<AppFormIcon width={24} customColor={getChipColor(item?.status)} isActive />
									)}

									<ListItemNavText text={itemTitle} />
								</div>
							</ListItemText>
						</StyledWrapper.NavButton>
					);
				})}
			</List>
		</StyledWrapper.Container>
	);
}

export const ListItemNavText = ({ text }) => {
	const [isOverflowing, setIsOverflowing] = useState(false);
	const navTextRef = useRef(null);

	useEffect(() => {
		if (navTextRef.current) {
			setIsOverflowing(navTextRef.current.scrollWidth > navTextRef.current.clientWidth);
		}
	}, [text]);

	return (
		<Tooltip title={isOverflowing ? text : ''} arrow placement="top">
			<StyledWrapper.NavText ref={navTextRef}>{text}</StyledWrapper.NavText>
		</Tooltip>
	);
};

export function VerticalNavLink({
	active,
	text,
	onClick,
	isTeam,
	isBudget,
	isBudgetRequired,
	isBudgetSet,
	isGoals
}) {
	return (
		<StyledWrapper.NavButton selected={active} onClick={onClick}>
			<ListItemIcon sx={{ width: '80px', justifyContent: 'right' }}>
				{/* {isBudgetRequired && !isBudgetSet && (
					<StyledWrapper.RequiredIndicator>Required!</StyledWrapper.RequiredIndicator>
				)}
				{isBudgetRequired && isBudgetSet && <CheckIcon color="success" />} */}
			</ListItemIcon>
			<ListItemText
				sx={{
					width: '220px',
					marginLeft: '16px',
					display: 'flex',
					alignItems: 'center'
				}}
			>
				<div style={{ display: 'flex' }}>
					{isTeam && <GroupAddIcon sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />}
					{isBudget && <RequestQuoteIcon sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />}
					{isGoals && <FlagCircleIcon sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />}

					<Tooltip title={text.length > stringLengthMax ? text : ''} arrow placement="top">
						<StyledWrapper.NavText>{text}</StyledWrapper.NavText>
						{(isBudget || isGoals) && (
							<LaunchIcon
								viewBox="0 0 24 24 "
								sx={{ position: 'absolute', marginLeft: '2px', transform: 'scale(0.8)' }}
							/>
						)}
					</Tooltip>
				</div>
			</ListItemText>
		</StyledWrapper.NavButton>
	);
}

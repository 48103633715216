import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import StyledWrapper from './style';

import AssignReviewColumns from './AssignReviewColumns';
import { ItemPageTitle } from '../../components/ItemPageTitle';
import { CustomModal } from '../../components/Modal';
import { Table } from '../Table';
import ReviewStageIcon from '../../assets/customIcons/reviewStageIcon';
import {
	useGetAssignedReviewersQuery,
	useGetReviewerPoolQuery
} from '../../services/endpoints/reviewEndpoints';
import usePaginatedCall from '../../hooks/usePaginatedCall';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/Warning';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import IconButton from '@mui/material/IconButton';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export const EvalAssignmentsModal = ({ openModal, closeModal, item, chosenReviews }) => {
	const stageID = item?.appId?.replace('esi-', '');
	const [searchParams, setSearchParams] = useSearchParams();
	const [open, setOpen] = useState(false);
	const [openTooltip, setOpenTooltip] = useState(0);

	const AssignedColumns = AssignReviewColumns(
		openTooltip,
		setOpenTooltip,
		false,
		chosenReviews,
		() => {},
		'Assigned',
		item?.appId
	);
	const ReviewPoolColumns = AssignReviewColumns(
		openTooltip,
		setOpenTooltip,
		false,
		chosenReviews,
		() => {},
		'Reviewer Pool',
		item?.appId
	);
	const assignedReviewerPagination = usePaginatedCall(useGetAssignedReviewersQuery, {
		stageID: stageID
	});
	const reviewerPoolPagination = usePaginatedCall(useGetReviewerPoolQuery, {
		stageID: stageID,
		filterBaseName: 'REVIEWER_POOL'
	});

	const getPageTitle = () => {
		const pageTitle = (
			<div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
				<ReviewStageIcon />
				{!chosenReviews ? (
					`Assignments of Evaluation Stage: ${item?.title} [${item?.appId}]`
				) : (
					<>
						{`Bulk assign the selected evaluation stage(s):`}{' '}
						{chosenReviews?.map((e, index) => {
							return (
								<>
									{`[${e?.stageId}]`}
									{index !== chosenReviews?.length - 1 && ' and '}
								</>
							);
						})}
					</>
				)}
			</div>
		);
		return pageTitle;
	};

	const handleClose = () => {
		closeModal && closeModal();
		setOpen(false);
	};

	useEffect(() => {
		setOpen(openModal);
	}, [openModal]);

	return (
		<>
			<CustomModal
				width={'100%'}
				height={'100%'}
				isOpen={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<StyledWrapper.OutermostContainer>
					<StyledWrapper.BackIconContainer>
						<IconButton
							onClick={handleClose}
							aria-label="delete"
							size="large"
							style={{ fontSize: '26px', background: '#E0E0E0' }}
						>
							<KeyboardBackspaceIcon style={{ fontSize: '26px' }} />
						</IconButton>
					</StyledWrapper.BackIconContainer>
					<ItemPageTitle removeGrayLine text={getPageTitle() || ''} />
					<StyledWrapper.InnerContainer padding={'0px 55px'} margin={'40px 0px 0px 0px'}>
						<ItemPageTitle text={chosenReviews ? 'Assigning List' : 'Assigned'} />
						<Table
							disableSelectionOnClick
							hideFilters
							getRowId={(cell) => cell?.reviewer_id}
							columns={AssignedColumns}
							rowHeight={true}
							customHeight={'30vh'}
							enableAutoPageSize
							rows={assignedReviewerPagination?.rows?.results || []}
							rowCount={assignedReviewerPagination?.rowCount}
							pagination={assignedReviewerPagination?.pagination}
							pageSize={assignedReviewerPagination?.pageSize}
							paginationMode={assignedReviewerPagination?.paginationMode}
							onPageChange={(e) => assignedReviewerPagination?.onPageChange(e)}
							onPageSizeChange={(e) => assignedReviewerPagination?.onPageSizeChange(e)}
							page={assignedReviewerPagination?.page}
							loading={assignedReviewerPagination?.loading}
							sortingMode={assignedReviewerPagination?.sortingMode}
							onSortModelChange={(e) => assignedReviewerPagination?.onSortModelChange(e)}
							filterModel={assignedReviewerPagination?.filterModel}
							sortModel={assignedReviewerPagination?.sortModel}
						/>
						<ItemPageTitle text={'Reviewers Pool'} />
						<Table
							disableSelectionOnClick
							hideFilters
							getRowId={(cell) => cell?.reviewer_id}
							columns={ReviewPoolColumns}
							rowHeight={true}
							customHeight={'31.2vh'}
							enableAutoPageSize
							rows={reviewerPoolPagination?.rows?.results || []}
							rowCount={reviewerPoolPagination?.rowCount}
							pagination={reviewerPoolPagination?.pagination}
							pageSize={reviewerPoolPagination?.pageSize}
							paginationMode={reviewerPoolPagination?.paginationMode}
							onPageChange={(e) => reviewerPoolPagination?.onPageChange(e)}
							onPageSizeChange={(e) => reviewerPoolPagination?.onPageSizeChange(e)}
							page={reviewerPoolPagination?.page}
							loading={reviewerPoolPagination?.loading}
							sortingMode={reviewerPoolPagination?.sortingMode}
							onSortModelChange={(e) => reviewerPoolPagination?.onSortModelChange(e)}
							filterModel={reviewerPoolPagination?.filterModel}
							sortModel={reviewerPoolPagination?.sortModel}
						/>
					</StyledWrapper.InnerContainer>
				</StyledWrapper.OutermostContainer>
			</CustomModal>

			<StyledWrapper.CustomDialog
				fullScreen={false}
				open={searchParams.get('updateStatus') === 'success'}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
			>
				<StyledWrapper.CustomDialogTitle id="responsive-dialog-title">
					<CheckCircleOutlineIcon color={'success'} />
					{`Updated Reviewer Successfully`}
				</StyledWrapper.CustomDialogTitle>
				<Button
					size="large"
					variant="contained"
					autoFocus
					onClick={() => setSearchParams({})}
					sx={{ maxWidth: '70px', margin: 'auto' }}
				>
					Close
				</Button>
			</StyledWrapper.CustomDialog>

			<StyledWrapper.CustomDialog
				fullScreen={false}
				open={searchParams.get('updateStatus') === 'failed'}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
			>
				<StyledWrapper.CustomDialogTitle id="responsive-dialog-title">
					<ErrorOutlineIcon color={'error'} />
					{'Update Failed!'}
				</StyledWrapper.CustomDialogTitle>
				<Button
					size="large"
					variant="contained"
					autoFocus
					onClick={() => setSearchParams({})}
					sx={{ maxWidth: '70px', margin: 'auto' }}
				>
					Close
				</Button>
			</StyledWrapper.CustomDialog>
		</>
	);
};

const assignedMockData = [
	{
		reviewer: 'Gregory Beck',
		assigned: '25',
		notStarted: '15',
		inProgress: '20',
		submitted: '56',
		id: 1
	},
	{
		reviewer: 'Horace Earneston',
		assigned: '23',
		notStarted: '1',
		inProgress: '22',
		submitted: '23',
		id: 2
	},
	{
		reviewer: 'Santana Clause',
		assigned: '21',
		notStarted: '11',
		inProgress: '10',
		submitted: '21',
		id: 3
	}
];

const poolMockData = [
	{
		reviewer: 'Cindyana Maree',
		assigned: '25',
		notStarted: '15',
		inProgress: '20',
		submitted: '56',
		id: 1,
		isPool: true
	},
	{
		reviewer: 'Tamatoua Rave',
		assigned: '23',
		notStarted: '1',
		inProgress: '22',
		submitted: '23',
		id: 2,
		isPool: true
	},
	{
		reviewer: 'And So On',
		assigned: '21',
		notStarted: '11',
		inProgress: '10',
		submitted: '21',
		id: 3,
		isPool: true
	}
];

import styled from 'styled-components';

const EditBar = styled.div`
	display: flex;
	justify-content: space-between;
`;

const LastEdited = styled.h6`
	color: var(--Light-Text-Secondary, rgba(0, 0, 0, 0.6));
	font-feature-settings: 'liga' off, 'clig' off;

	/* Typography/Body 2 */
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 143%; /* 20.02px */
	letter-spacing: 0.17px;

	margin: 1em;
`;

const LastEditedActions = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 20px;
	margin-top: ${(props) => props?.marginTop};
	gap: 5px;
`;

export default {
	EditBar,
	LastEdited,
	LastEditedActions
};

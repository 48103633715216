import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import ReviewToApplicationColumns from './revToAppColumns';
import { ApplicationToReviewColumns } from './appToRevColumns';
import { ManageReviewerCol, manageReviewGroupDef } from './manageReviewerCol';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { Table } from '../../../components/Table';
import StyledWrapper from '../style';
import { BulkEvalAssignmentsModal } from '../../../components/AssignReviewModal/BulkEvalAssignmentsModal';
import ReviewFormIcon from '../../../assets/customIcons/reviewForm';
import LinkReviewIcon from '../../../assets/customIcons/linkReviewIcon';
import BulkRevToEvalModal from '../../../components/AssignReviewModal/BulkRevToEvalModal';
import { AssignmentsButton } from '../../../components/ActionButtons/ActionButtons';
import { useGetAvailableReviewersQuery } from '../../../services/endpoints/reviewEndpoints';
import {
	useGetRevToAppEmailsQuery,
	useGetManageAssignedRevEmailsQuery,
	useGetAppToRevEmailsQuery
} from '../../../services/endpoints/reviewEndpoints';
import usePaginatedCall from '../../../hooks/usePaginatedCall';
import { useGetAppToRevTable } from '../../../hooks/useGetAppToRevTable';
import { useGetBulkReviewerTable } from '../../../hooks/useGetBulkReviewerTable';
import { usePermissions } from '../../../hooks/usePermissions';

const ManageReviews = () => {
	let { programID } = useParams();
	programID = programID?.replace('p-', '');

	const location = useLocation();
	const currentPath = location.pathname?.split('/');

	const navigate = useNavigate();
	const [alertBalloon, setAlertBalloon] = useState(false);
	const [columnWidth, setColumnWidth] = useState({});
	const [chosenRows, setChosenRows] = useState([]);
	const { hasEditPermissions } = usePermissions();

	const stateRef = useRef();
	stateRef.current = chosenRows;

	const handleRowSelection = (item, isReassigning) => {
		if (isReassigning) {
			let temp = { ...stateRef.current };

			let currentStages = temp[item?.hierarchy?.[0]] || [];
			if (currentStages.includes(item?.stageId)) {
				currentStages = currentStages.filter((stage) => stage !== item.stageId);
			} else {
				currentStages.push(item.stageId);
			}

			temp[item?.hierarchy?.[0]] = currentStages;
			setChosenRows(temp);
		} else {
			const foundIndex = stateRef?.current?.findIndex((e) => e?.reviewer_id === item?.reviewer_id);
			if (foundIndex === -1) {
				setChosenRows([...stateRef?.current, item]);
			} else {
				const newArray = stateRef.current?.filter((e) => e?.reviewer_id !== item?.reviewer_id);
				setChosenRows(newArray);
			}
		}
	};

	const revToAppColumns = ReviewToApplicationColumns(columnWidth, handleRowSelection);

	const [currentView, setCurrentView] = useState(0);
	const [showBulkAssign, setShowBulkAssign] = useState(false);
	const [showBulkAssignRevs, setShowBulkAssignRevs] = useState(false);
	const [currentColumns, setCurrentColumns] = useState(revToAppColumns);

	const [currentKey, setCurrentKey] = useState('revToApp-table');

	const reviewerPoolPagination = usePaginatedCall(useGetAvailableReviewersQuery, {
		filterBaseName: 'PROGRAM_REVIEWS'
	});

	const [finalPagination, setFinalPagination] = useState(reviewerPoolPagination);
	const [noReviewers, setNoReviewers] = useState(false);

	const theme = createTheme({
		palette: {
			secondary: {
				main: '#9C27B0'
			}
		}
	});

	const handleViewChange = () => {
		const currentPage = currentPath[currentPath?.length - 1];
		if (currentPage === 'manage-assigned') {
			setCurrentView(2);
			setFinalPagination({});
			setCurrentKey('manageRevs-table');
		} else if (currentPage === 'assign-by-application') {
			setCurrentView(1);
			setFinalPagination({});
			setCurrentKey('evalToRev-table');
		} else {
			setCurrentView(0);
			setCurrentColumns(revToAppColumns);
			setFinalPagination(reviewerPoolPagination);
			setCurrentKey('revToApp-table');
		}
	};

	const handleAlert = (message, status, dismissing = true) => {
		setAlertBalloon({ isOpen: true, message, status });
		if (dismissing) {
			setTimeout(() => {
				setAlertBalloon(false);
			}, 3000);
		}
	};

	useEffect(() => {
		handleViewChange();
	}, [location, reviewerPoolPagination?.rows]);

	useEffect(() => {
		setNoReviewers(finalPagination?.rows && finalPagination?.rowCount <= 0);
	}, [finalPagination]);

	return noReviewers ? (
		<Stack sx={{ width: '100%', marginTop: '20px' }} spacing={2}>
			<Alert variant="outlined" severity="info">
				Reviewers have not been added to this program.
				<br />
				Once reviewers have been added to this program, they will appear on this page and can be
				assigned to applications.
				<br />
				Users may take up to five minutes to appear in the list after they are added in Grants
				Network.
			</Alert>
		</Stack>
	) : (
		<>
			<Stack sx={{ width: '100%', marginTop: '20px' }} spacing={2}>
				<Alert variant="outlined" severity="info">
					Users may take up to five minutes to appear in the list after they are added in Grants
					Network.
				</Alert>
			</Stack>
			<ThemeProvider theme={theme}>
				<StyledWrapper.Toolbar
					style={{ marginTop: '30px', marginBottom: '20px', marginRight: '20px' }}
				>
					<StyledWrapper.ActionsContainerOutermost>
						<FormControl fullWidth>
							<Select
								id="current-view-select"
								value={currentPath[currentPath?.length - 1]}
								onChange={(e) => {
									let newPath = currentPath;
									newPath.pop();
									newPath.push(e.target.value);
									setChosenRows([]);
									navigate(newPath?.join('/'));
								}}
								sx={{ width: 'fit-content' }}
								size="small"
							>
								<MenuItem value={'assign-by-reviewer'}>Assign Reviewers to Applications</MenuItem>
								<MenuItem value={'assign-by-application'}>
									Assign Applications to Reviewers
								</MenuItem>
								<MenuItem value={'manage-assigned'}>Manage Assigned Reviews</MenuItem>
							</Select>
						</FormControl>
						{currentView === 0 && hasEditPermissions ? (
							<Button
								disabled={chosenRows?.length === 0}
								variant="outlined"
								sx={{ width: '250px', display: 'flex', gap: '6px', textWrap: 'nowrap' }}
								onClick={() => setShowBulkAssignRevs(true)}
							>
								Bulk Assign
								<LinkReviewIcon isActive={chosenRows?.length !== 0} />
								<ReviewFormIcon
									isBlueprint
									isActive={chosenRows?.length !== 0}
									forceOldBlueColor={chosenRows?.length !== 0}
								/>
							</Button>
						) : currentView === 1 && hasEditPermissions ? (
							<Button
								disabled={chosenRows?.length === 0}
								variant="outlined"
								sx={{ width: '220px', display: 'flex', gap: '6px' }}
								onClick={() => setShowBulkAssign(true)}
							>
								Bulk Assign
								<ReviewFormIcon
									customDisabledColor={'rgba(0, 0, 0, 0.26)'}
									isBlueprint
									isActive={chosenRows?.length !== 0}
									forceOldBlueColor={chosenRows?.length !== 0}
								/>
								<LinkReviewIcon isActive={chosenRows?.length !== 0} />
							</Button>
						) : (
							hasEditPermissions && (
								<AssignmentsButton
									item={chosenRows}
									isBulk
									disabled={chosenRows?.length === 0}
									handleAlert={handleAlert}
									alertBalloon={alertBalloon}
								/>
							)
						)}
					</StyledWrapper.ActionsContainerOutermost>
				</StyledWrapper.Toolbar>

				<Collapse in={alertBalloon}>
					<Alert
						variant="outlined"
						severity={alertBalloon?.status}
						action={
							<IconButton
								aria-label="close"
								color={alertBalloon?.status}
								size="small"
								onClick={() => {
									setAlertBalloon(false);
								}}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						}
						sx={{ mb: 2, mt: 2 }}
					>
						{alertBalloon?.message}
					</Alert>
				</Collapse>

				<StyledWrapper.TableContainer>
					{currentKey === 'manageRevs-table' ? (
						<AssignedReviewsTable
							hasEditPermissions={hasEditPermissions}
							handleAlert={handleAlert}
							handleRowSelection={handleRowSelection}
							alertBalloon={alertBalloon}
							setChosenRows={setChosenRows}
							emailType={'Reviewer'}
							emailEndpoint={useGetRevToAppEmailsQuery}
						/>
					) : currentKey === 'evalToRev-table' ? (
						<EvalToRevTable
							hasEditPermissions={hasEditPermissions}
							handleAlert={handleAlert}
							alertBalloon={alertBalloon}
							handleRowSelection={(e) => handleRowSelection(e, true)}
							setChosenRows={setChosenRows}
						/>
					) : (
						<Table
							checkboxSelection={hasEditPermissions}
							isRowSelectable={(params) => params?.row?.reviewer_id}
							onSelectChange={(e) => setChosenRows(e)}
							disableSelectionOnClick
							hideFilters
							key={currentKey}
							getRowId={(cell) => cell?.reviewer_id}
							customRowID={'reviewer_id'}
							columns={currentColumns}
							rowHeight={true}
							enableAutoPageSize
							groupingColDef={() => groupingColDef(columnWidth)}
							onColumnResize={(e) => {
								const newColumnSizes = e?.colDef?.field;
								const width = e?.width;
								setColumnWidth({ ...columnWidth, [newColumnSizes]: width });
							}}
							rows={finalPagination?.rows?.results || []}
							rowCount={finalPagination?.rowCount}
							pagination={finalPagination?.pagination}
							pageSize={finalPagination?.pageSize}
							paginationMode={finalPagination?.paginationMode}
							onPageChange={(e) => finalPagination?.onPageChange(e)}
							onPageSizeChange={(e) => finalPagination?.onPageSizeChange(e)}
							page={finalPagination?.page}
							loading={finalPagination?.loading}
							sortingMode={finalPagination?.sortingMode}
							onSortModelChange={(e) => finalPagination?.onSortModelChange(e)}
							filterModel={finalPagination?.filterModel}
							sortModel={finalPagination?.sortModel}
							emailType={'Reviewer'}
							emailEndpoint={useGetRevToAppEmailsQuery}
						/>
					)}
				</StyledWrapper.TableContainer>
			</ThemeProvider>

			{showBulkAssign && hasEditPermissions && (
				<BulkEvalAssignmentsModal
					chosenStages={chosenRows}
					closeModal={() => setShowBulkAssign(false)}
					openModal={showBulkAssign}
					handleAlert={handleAlert}
				/>
			)}

			{showBulkAssignRevs && hasEditPermissions && (
				<BulkRevToEvalModal
					chosenReviews={chosenRows}
					closeModal={() => setShowBulkAssignRevs(false)}
					openModal={showBulkAssignRevs}
					handleAlert={handleAlert}
				/>
			)}
		</>
	);
};

export default ManageReviews;

const EvalToRevTable = ({
	handleRowSelection,
	alertBalloon,
	setChosenRows,
	hasEditPermissions
}) => {
	const [columnWidth, setColumnWidth] = useState({});
	const appToRevColumns = ApplicationToReviewColumns(columnWidth, handleRowSelection);
	const [reload, setReload] = useState(false);

	const [orderedColumns, setOrderedColumns] = useState(appToRevColumns);
	const updateOrderedColumns = (updatedOrderedColumns) => setOrderedColumns(updatedOrderedColumns);

	const { data, pagination } = useGetAppToRevTable({ sortedByReviewer: false });

	useEffect(() => {
		if (alertBalloon?.status === 'success') {
			setReload(true);
			setTimeout(function () {
				setReload(false);
			}, 10);
		}
	}, [alertBalloon]);

	return (
		<>
			{!reload && (
				<Table
					columns={appToRevColumns}
					rowHeight={52}
					enableAutoPageSize
					rows={data || []}
					rowCount={pagination?.rowCount}
					pagination={pagination?.pagination}
					pageSize={pagination?.pageSize}
					paginationMode={pagination?.paginationMode}
					onPageChange={(e) => pagination?.onPageChange(e)}
					onPageSizeChange={(e) => pagination?.onPageSizeChange(e)}
					page={pagination?.page}
					loading={pagination?.loading}
					sortingMode={pagination?.sortingMode}
					onSortModelChange={(e) => pagination?.onSortModelChange(e)}
					onFilterModelChange={(e) => pagination?.onFilterModelChange(e)}
					checkboxSelection={hasEditPermissions}
					isRowSelectable={(params) =>
						params?.row?.stageId &&
						(params.row.appStatus.includes('In Progress') || params.row.appStatus === 'Not Started')
					}
					onSelectChange={(e) => setChosenRows(e)}
					disableSelectionOnClick
					key={'evalToRev-table'}
					onColumnResize={(e) => {
						const newColumnSizes = e?.colDef?.field;
						const width = e?.width;
						setColumnWidth({ ...columnWidth, [newColumnSizes]: width });
					}}
					filterModel={pagination?.filterModel}
					sortModel={pagination?.sortModel}
					hideTableActions={true}
					isOpenedFilter={true}
					updateOrderedColumns={updateOrderedColumns}
				/>
			)}
		</>
	);
};

const AssignedReviewsTable = ({
	handleRowSelection,
	alertBalloon,
	setChosenRows,
	hasEditPermissions
}) => {
	const [columnWidth, setColumnWidth] = useState({});
	const manageReviewColumns = ManageReviewerCol(columnWidth, handleRowSelection);
	const [reload, setReload] = useState(false);
	const getTreeDataPath = (row) => row?.hierarchy;

	const { data, isLoading, pagination } = useGetBulkReviewerTable();

	useEffect(() => {
		if (alertBalloon?.status === 'success') {
			setReload(true);
			setTimeout(function () {
				setReload(false);
			}, 10);
		}
	}, [alertBalloon]);

	function createHierarchyObject(arr) {
		const hierarchyObject = {};

		arr.forEach((element) => {
			const [hierarchyKey] = element.hierarchy;
			const { stageId } = element;

			if (!hierarchyObject[hierarchyKey]) {
				hierarchyObject[hierarchyKey] = [stageId];
			} else if (!hierarchyObject[hierarchyKey].includes(stageId)) {
				hierarchyObject[hierarchyKey].push(stageId);
			}
		});

		setChosenRows(hierarchyObject);
	}

	return (
		<>
			{!reload && (
				<Table
					columns={manageReviewColumns}
					rowHeight={52}
					enableAutoPageSize
					rows={data || []}
					rowCount={pagination?.rowCount}
					pagination={pagination?.pagination}
					pageSize={pagination?.pageSize}
					paginationMode={pagination?.paginationMode}
					onPageChange={(e) => pagination?.onPageChange(e)}
					onPageSizeChange={(e) => pagination?.onPageSizeChange(e)}
					page={pagination?.page}
					loading={pagination?.loading}
					sortingMode={pagination?.sortingMode}
					onSortModelChange={(e) => pagination?.onSortModelChange(e)}
					onFilterModelChange={(e) => console.log(e)}
					checkboxSelection={hasEditPermissions}
					isRowSelectable={(params) =>
						params?.row?.stageId &&
						(params?.row?.appStatus.includes('In Progress') ||
							params?.row?.appStatus === 'Not Started')
					}
					onSelectChange={(e) => createHierarchyObject(e)}
					hideFilters
					disableSelectionOnClick
					key={'manageRevs-table'}
					treeData={true}
					getTreeDataPath={getTreeDataPath}
					groupingColDef={() => manageReviewGroupDef(columnWidth)}
					onColumnResize={(e) => {
						const newColumnSizes = e?.colDef?.field;
						const width = e?.width;
						setColumnWidth({ ...columnWidth, [newColumnSizes]: width });
					}}
					filterModel={pagination?.filterModel}
					sortModel={pagination?.sortModel}
					emailType={'Reviewer'}
					emailEndpoint={useGetManageAssignedRevEmailsQuery}
				/>
			)}
		</>
	);
};

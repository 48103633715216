import { useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { truncateString, stringLengthMax } from '../../utils/utilFunctions';

import StyledWrapper from './style';

import Tooltip from '@mui/material/Tooltip';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';

import AppProcessIcon from '../../assets/customIcons/applicantProcess.jsx';
import LaunchIcon from '@mui/icons-material/Launch';
import ReviewContent from '../../assets/customIcons/reviewFormIcon';
import SubmissionStageIcon from '../../assets/customIcons/submissionStageIcon';
import ReviewFormIcon from '../../assets/customIcons/reviewForm.jsx';
import ReviewStageIcon from '../../assets/customIcons/reviewStageIcon.jsx';
import AppFormIcon from '../../assets/customIcons/appFormIcon';
import ResizePanelContainer from '../ResizePanelContainer/ResizePanelContainer';
import { getChipColor } from '../../utils/utilFunctions';

export default function VerticalNavigation({ data, customInnerWidth, baseURL }) {
	const location = useLocation();
	const navigate = useNavigate();
	const mainContainer = useRef();

	const pathArray = location?.pathname?.split('/');
	const mainPath = baseURL || pathArray?.slice(0, -1);
	const activeLink = pathArray[pathArray?.length - 1]?.replaceAll('%20', ' ');

	const [expanded, setExpanded] = useState('all');

	const goToTab = (page) => {
		if (page?.includes('http')) {
			window.open(page, '_blank');
		} else {
			navigate(`${baseURL || mainPath?.join('/')}/${page.toLowerCase()}${location?.search}`);
		}
	};

	return (
		<ResizePanelContainer panelStyle={{ width: '270px' }}>
			<StyledWrapper.Container
				customInnerWidth={customInnerWidth}
				ref={mainContainer}
				expanded={expanded === `Application`}
			>
				{data?.map((item) => {
					const url = window.location.href;
					const isActive = item?.id === activeLink || url.includes(`${item?.id}/details`);
					let itemTitle = item?.title ? item.title : '';

					return item?.children ? (
						<StyledWrapper.DropdownContainer
							expanded={expanded === item?.title || expanded === 'all'}
							TransitionProps={{ unmountOnExit: true }}
							className={'applicationProcess'}
							initialDropdown={true}
							id={'initial-dropdowncontainer'}
							activeOverview={isActive}
						>
							<StyledWrapper.CustomAccordionSummary
								aria-controls={`applicationProcess-content`}
								id={`applicationProcess-header`}
								sx={{
									maxWidth: '100%',
									minHeight: '24px!important',
									paddingBottom: expanded && '10px!important'
								}}
							>
								{expanded ? (
									<StyledWrapper.MinusIcon onClick={() => setExpanded(false)} />
								) : (
									<StyledWrapper.PlusIcon onClick={() => setExpanded(item?.title)} />
								)}
								<AppProcessIcon
									isActive={true}
									customColor={getChipColor(item?.status)}
									isCurrentStage={item?.is_current_stage}
								/>
								<Tooltip
									title={itemTitle.length > stringLengthMax ? itemTitle : ''}
									arrow
									placement="top"
								>
									<StyledWrapper.NavLink onClick={() => goToTab(item?.link || item?.id)}>
										{truncateString(itemTitle)}
									</StyledWrapper.NavLink>
								</Tooltip>
							</StyledWrapper.CustomAccordionSummary>

							<AccordionDetails
								id={'accordian-details'}
								sx={{
									display: 'flex',
									width: 'fit-content',
									flexDirection: 'column',
									gap: '5px',
									marginLeft: '20px;'
								}}
							>
								{item?.children?.map((e, index) =>
									e?.forms ? (
										<FormsDropdown
											status={e?.status}
											currentStage={e?.is_current_stage}
											key={`${index}-${e?.title}`}
											isStage={true}
											lastChild={data[1]?.children?.length - 1 === index}
											item={e}
											active={activeLink}
											text={e?.title}
											onClick={goToTab}
										/>
									) : (
										<VerticalNavLink
											status={e?.status}
											currentStage={e?.is_current_stage}
											key={`${index}-${e?.title}`}
											active={
												url.includes(`${item?.id}`) ||
												activeLink === e?.id?.toLowerCase() ||
												activeLink === e?.title?.toLowerCase()
											}
											text={e?.title}
											onClick={(x) => goToTab(e?.link || x)}
											external={false}
										/>
									)
								)}
							</AccordionDetails>
						</StyledWrapper.DropdownContainer>
					) : (
						<VerticalNavLink
							status={item?.status}
							currentStage={item?.is_current_stage}
							active={
								// url.includes(`${item?.id}`) ||
								activeLink === item?.id?.toLowerCase() || activeLink === itemTitle.toLowerCase()
							}
							text={item?.title}
							onClick={(e) => goToTab(item?.link || e)}
							mainLink
							external={item?.link.includes('http')}
						/>
					);
				})}
			</StyledWrapper.Container>
		</ResizePanelContainer>
	);
}

export function VerticalNavLink({
	active,
	text,
	onClick,
	isForm,
	isChild,
	isReview,
	isReviewForm,
	mainLink,
	status,
	external
}) {
	const [openTooltip, setOpenTooltip] = useState(0);

	return (
		<StyledWrapper.linkContainer
			//ADDS LEFT NAVIGATION LINES
			// className={`${isChild && 'childElement'}`}
			isChild={isChild}
			active={active}
		>
			{/* {!mainLink && <StyledWrapper.PlainCircleIcon />} */}
			{isForm && !isReviewForm && (
				<AppFormIcon isActive={true} customColor={getChipColor(status)} />
			)}
			{isForm && isReviewForm && (
				<ReviewFormIcon isActive={true} customColor={getChipColor(status)} />
			)}
			{isReview && <ReviewContent isActive={true} customColor={getChipColor(status)} />}
			{!isForm && !isReview && !mainLink && (
				<SnippetFolderIcon
					sx={{ marginRight: '5px' }}
					isActive={true}
					customColor={getChipColor(status)}
				/>
			)}
			<Tooltip title={text.length > stringLengthMax ? text : ''} arrow placement="top">
				<StyledWrapper.NavLink onClick={onClick} active={active}>
					{truncateString(text)}{' '}
					{external && (
						<LaunchIcon
							viewBox="0 0 24 24 "
							sx={{ position: 'absolute', marginLeft: '2px', transform: 'scale(0.8)' }}
						/>
					)}
				</StyledWrapper.NavLink>
			</Tooltip>
		</StyledWrapper.linkContainer>
	);
}

export function FormsDropdown({
	item,
	active,
	text,
	onClick,
	isStage,
	lastChild,
	status,
	currentStage
}) {
	const [expanded, setExpanded] = useState(true);
	const [openTooltip, setOpenTooltip] = useState(0);
	const url = window.location.href;
	const isActive = item?.stage_id === active || url.includes(`${item?.stage_id}/details`);

	return (
		<StyledWrapper.DropdownContainer
			expanded={expanded}
			TransitionProps={{ unmountOnExit: true }}
			// className={'individualStage'}
			lastChild={lastChild}
			id={'second-dropdownContainer'}
			active={isActive}
		>
			<AccordionSummary
				aria-controls={`${text}-content`}
				id={`${text}-header`}
				// className="stageDropdown"
				sx={{
					minHeight: '24px!important',
					paddingBottom: expanded && '10px!important'
				}}
			>
				{expanded ? (
					<StyledWrapper.MinusIcon
						onClick={() => {
							setExpanded(false);
						}}
					/>
				) : (
					<StyledWrapper.PlusIcon
						onClick={() => {
							setExpanded(true);
						}}
					/>
				)}
				{item?.stage_id?.toLowerCase().includes('ssi-') ? (
					<SubmissionStageIcon
						isActive={true}
						customColor={getChipColor(status)}
						isCurrentStage={currentStage}
					/>
				) : (
					<ReviewStageIcon
						isActive={true}
						customColor={getChipColor(status)}
						isCurrentStage={currentStage}
					/>
				)}
				<Tooltip title={text.length > stringLengthMax ? text : ''} arrow placement="top">
					<StyledWrapper.NavLink
						onClick={() => onClick(item?.link || item?.stage_id)}
						active={isActive}
					>
						{truncateString(text)}
					</StyledWrapper.NavLink>
				</Tooltip>
			</AccordionSummary>

			<AccordionDetails
				className={`${isStage && !lastChild && 'isStage'}`}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '5px',
					marginLeft: item?.stage_id?.includes('esi-') ? '20px' : '52px;'
				}}
			>
				{item?.forms?.map((e, index) => {
					return (
						<>
							{!e?.review ? (
								<VerticalNavLink
									status={e?.status}
									currentStage={e?.is_current_stage}
									key={`${index}-${e?.title}`}
									isForm={true}
									isReviewForm={e?.id.toLowerCase().includes('rfm-')}
									isChild={true}
									lastChild={item?.forms?.length - 1 === index}
									active={url.includes(`${e?.id}`) || active === e?.id}
									text={e?.title}
									onClick={() => onClick(e?.link || e?.id)}
									external={false}
								/>
							) : (
								<ReviewsDropdown
									status={e?.status}
									key={`${index}-${e?.title}`}
									isForm={false}
									isChild={true}
									lastChild={item?.forms?.length - 1 === index}
									item={e?.review}
									active={active}
									text={e?.title}
									formId={e?.id}
									masterLink={e?.link || e?.id}
									onClick={(x) => onClick(x)}
								/>
							)}
						</>
					);
				})}
			</AccordionDetails>
		</StyledWrapper.DropdownContainer>
	);
}

export function ReviewsDropdown({
	item,
	active,
	text,
	onClick,
	lastChild,
	formId,
	masterLink,
	status
}) {
	const [expanded, setExpanded] = useState(text);
	const [openTooltip, setOpenTooltip] = useState(0);
	const url = window.location.href;
	const isActive = `${formId}-group` === active || url.includes(`${formId}-group/details`);

	return (
		<StyledWrapper.DropdownContainer
			expanded={expanded === `${text}`}
			TransitionProps={{ unmountOnExit: true }}
			isChild={true}
			lastChild={lastChild}
			// className={'childElement'}
			active={isActive}
		>
			<AccordionSummary
				aria-controls={`${text}-content`}
				id={`${text}-header`}
				sx={{
					minHeight: '24px!important',
					paddingBottom: expanded && '10px!important'
				}}
			>
				{expanded ? (
					<StyledWrapper.MinusIcon onClick={() => setExpanded(false)} />
				) : (
					<StyledWrapper.PlusIcon onClick={() => setExpanded(text)} />
				)}
				<ReviewFormIcon isActive={true} customColor={getChipColor(status)} />

				<Tooltip title={text.length > stringLengthMax ? text : ''} arrow placement="top">
					<StyledWrapper.NavLink onClick={() => onClick(masterLink)} active={isActive}>
						{truncateString(text)}
					</StyledWrapper.NavLink>
				</Tooltip>
			</AccordionSummary>
			<AccordionDetails
				sx={{ display: 'flex', flexDirection: 'column', gap: '5px', marginLeft: '50px' }}
			>
				{item?.map((e, index) => (
					<VerticalNavLink
						status={e?.status}
						currentStage={e?.is_current_stage}
						key={`${index}-${e?.title}`}
						isReview={true}
						active={
							url.includes(`${e?.id}`) ||
							active === e?.id?.toLowerCase() ||
							active === e?.title?.toLowerCase()
						}
						isChild={true}
						text={e?.title}
						onClick={() => onClick(e?.link || e?.id)}
						external={false}
					/>
					// onClick(e?.link || e?.id)
				))}
			</AccordionDetails>
		</StyledWrapper.DropdownContainer>
	);
}

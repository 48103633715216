import { useMemo } from 'react';

interface Application {
	form_stage_id: {
		unique_identifier: string;
	};
	all_stages: {
		unique_identifier: string;
		forms: {
			unique_identifier: string;
		}[];
	}[];
}

export const useIsFormInCurrentStage = (data: Application, formUniqueId: string): boolean => {
	return useMemo(() => {
		const currentStageId = data?.form_stage_id?.unique_identifier;

		const currentStage = data?.all_stages?.find(
			(stage) => stage.unique_identifier === currentStageId
		);

		return currentStage?.forms?.some((form) => form.unique_identifier === formUniqueId) || false;
	}, [data, formUniqueId]);
};

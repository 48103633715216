import { useMemo } from 'react';
import { ApplicationStatus } from '../../constants/common';

interface RowItem {
	isWithinDateRange: boolean;
	all_stages_submitted: boolean;
	type: 'Application Form' | string;
	is_current_stage: boolean;
}

export const useFormButtonConditions = ({
	item,
	status,
	userAccountTypeID,
	completelyDisabled,
	hasEditPermissions
}: {
	item: RowItem;
	status: ApplicationStatus;
	userAccountTypeID: number;
	completelyDisabled: boolean;
	hasEditPermissions: boolean;
}) => {
	return useMemo(
		() => ({
			isEditable:
				(item?.isWithinDateRange || status?.includes('reopened') || status?.includes('returned')) &&
				(status?.includes('not started') || status?.includes('draft')) &&
				(item?.all_stages_submitted || item?.type === 'Application Form') &&
				status !== 'completed' &&
				!completelyDisabled &&
				item?.is_current_stage &&
				userAccountTypeID !== 5,

			canComplete:
				status?.includes('draft') &&
				!completelyDisabled &&
				item?.is_current_stage &&
				(item?.all_stages_submitted || item?.type === 'Application Form') &&
				userAccountTypeID !== 5,

			canReopen:
				status === 'completed' &&
				!completelyDisabled &&
				hasEditPermissions &&
				userAccountTypeID !== 5
		}),
		[item, status, userAccountTypeID, completelyDisabled, hasEditPermissions]
	);
};

import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { MultiFormViewer } from '../../../components/MultiFormViewer';
import { useGetIndividualApplicationQuery } from '../../../services/endpoints/programsEndpoints';
import {
	useGetFormInstanceSchemaQuery,
	useGetIndividualApplicationStatusQuery
} from '../../../services/endpoints/formBuilderEndpoints';
import { setFormData, clearFormSchema } from '../../../components/FormBuilderLibrary/slice';
import ID from '../../../components/FormBuilder/UUID';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { getLocalAuth } from '../../../utils/environmentUtils';
import {
	PROGRAM_PREFIX,
	APPLICATION_PREFIX,
	SSI_PREFIX,
	ESI_PREFIX
} from '../../../constants/entityPrefixes';
import { checkInRange, getLastEdited } from '../utils';

const ApplicationFormContent = ({ formID, isNewExperience }) => {
	const _ = require('lodash');
	const location = useLocation();
	let { programID } = useParams();
	const prevPathname = useRef(location.pathname);
	programID = programID?.replace(PROGRAM_PREFIX, '');
	const dispatch = useDispatch();
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const path = window.location.pathname;
	const pathSegments = path.split('/');
	const [searchParams] = useSearchParams();
	const formType = searchParams.get('formtype') || '';
	const iFrame = searchParams?.get('iFrame') || '';
	const appID = pathSegments
		.find((segment) => segment.includes(APPLICATION_PREFIX))
		?.replace(APPLICATION_PREFIX, '');
	const stageId = pathSegments
		.find((segment) => segment.includes(SSI_PREFIX) || segment.includes(ESI_PREFIX))
		?.split('-')?.[1];

	const {
		data: apiFormData,
		isLoading: isLoadingFormInstanceSchema,
		isFetching: isFetchingFormInstanceSchema
	} = useGetFormInstanceSchemaQuery({
		uniqueID: formID?.split('-')?.[1],
		apiToken: apiToken
	});

	const { data: applicationStatus, isLoading: isLoadingIndividualApplicationStatus } =
		useGetIndividualApplicationStatusQuery({
			application_id: appID,
			apiToken: apiToken
		});

	const { data: individualApplicationData, isLoading: isLoadingIndividualApplication } =
		useGetIndividualApplicationQuery({
			uniqueID: appID,
			apiToken,
			iFrame,
			formType
		});

	const [readOnly, setReadOnly] = useState(location?.state?.isEditing ? false : true);
	const [loadingCancel, setLoadingCancel] = useState(false);
	const [formSchema, setFormSchema] = useState();
	const [formAnswers, setFormAnswers] = useState([]);
	const [isWithinRange, setIsWithinRange] = useState(false);
	const [isCurrentStage, setIsCurrentStage] = useState(false);

	useEffect(() => {
		setLoadingCancel(true);
		setFormSchema();
		dispatch(clearFormSchema());
		setReadOnly(location?.state?.isEditing ? false : true);

		if (prevPathname.current !== location.pathname) {
			setTimeout(() => {
				prevPathname.current = location.pathname;
			}, 1000);
		}
	}, [location]);

	useEffect(() => {
		if (individualApplicationData) {
			setIsCurrentStage(individualApplicationData.form_stage_id?.unique_identifier === stageId);
		}
	}, [individualApplicationData, stageId]);

	useEffect(() => {
		if (apiFormData && applicationStatus) {
			const isReturned = applicationStatus?.status?.includes('returned');
			const isReopened = apiFormData?.status?.includes('reopened');
			const isWithinDateRange = checkInRange(
				individualApplicationData,
				apiFormData?.form?.stage,
				isReturned || isReopened
			);
			setIsWithinRange(isWithinDateRange);

			// ADD APPLICATION CORE INFO DATA
			if (apiFormData?.form?.purpose === 'Core Information') {
				const originalFormSchema = apiFormData.form.form_schema;
				const formCopy = _.cloneDeep(originalFormSchema);
				const applicationElement = formCopy?.find((e) =>
					e?.custom_options?.includes('completely-disabled')
				);
				applicationElement['chip'] = applicationStatus?.status || 'Not Started';

				const originalFormAnswers = apiFormData.field_values;
				const formAnswers = _.cloneDeep(originalFormAnswers);
				const applicationAnswer = formAnswers?.find(
					(e) => e?.name === applicationElement?.field_name
				);
				const applicationId = `[${APPLICATION_PREFIX}${appID}]`;
				if (applicationAnswer) {
					applicationAnswer['value'] = applicationId;
				} else {
					const createdApplicationAnswer = {
						portal_user_field_value_id: ID.uuid(),
						name: applicationElement?.field_name,
						custom_name: applicationElement?.field_name,
						rating: null,
						value: applicationId,
						portal_user_form: applicationElement?.form,
						field: applicationElement?.field_id
					};
					formAnswers.push(createdApplicationAnswer);
				}

				setFormSchema(formCopy);
				dispatch(setFormData([1, formAnswers]));
				setFormAnswers(formAnswers);
			} else {
				setFormSchema(apiFormData?.form?.form_schema);
				dispatch(setFormData([1, apiFormData?.field_values]));
				setFormAnswers(apiFormData?.field_values);
			}
		}
		setLoadingCancel(false);
	}, [apiFormData, loadingCancel, applicationStatus, individualApplicationData]);

	const showSpinner = prevPathname.current !== location.pathname && isFetchingFormInstanceSchema;

	const isLoading =
		isLoadingFormInstanceSchema ||
		isLoadingIndividualApplication ||
		isLoadingIndividualApplicationStatus;

	if (isLoading || loadingCancel || showSpinner) {
		return (
			<Box
				sx={{
					display: 'flex',
					width: '100%',
					height: '100%',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<CircularProgress />
			</Box>
		);
	}

	if (!formSchema || formSchema?.length === 0) {
		return (
			<Box
				sx={{
					display: 'flex',
					width: '100%',
					height: '100%',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				No Data Available
			</Box>
		);
	}

	const user = apiFormData?.form?.updated_by_user || { firstName: '', lastName: '' };

	const lastEdited = getLastEdited(
		user,
		apiFormData?.form.created_on,
		apiFormData?.form?.last_updated_on
	);

	return (
		<MultiFormViewer
			formAnswers={[null, formAnswers] || []}
			form={[formSchema] || []}
			apiFormData={apiFormData || []}
			readOnly={readOnly}
			programID={programID}
			formID={formID}
			disableActionButtons={!isWithinRange || !isCurrentStage}
			disableFooterButtons={true}
			hideActionButtons={true}
			setReadOnly={setReadOnly}
			formInstructions={apiFormData?.form?.instructions}
			status={apiFormData?.status}
			application_status={applicationStatus?.status}
			newExperience={isNewExperience}
			lastEdited={lastEdited}
			complete={apiFormData?.complete}
			isDataLoading={isLoadingFormInstanceSchema || isFetchingFormInstanceSchema}
		/>
	);
};

export default ApplicationFormContent;

import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const ApplicationsTemplate = ({ programTitle, activeFilters, tableData }) => {
	const styles = StyleSheet.create({
		page: {
			flexDirection: 'column',
			backgroundColor: '#E4E4E4'
		},
		headerSection: {
			margin: 10,
			padding: 10,
			flexDirection: 'column'
		},
		mb5: {
			marginBottom: '5%'
		},
		mb2: {
			marginBottom: '2%'
		},
		table: {
			width: '100%'
		},
		filterText: {
			fontSize: 10
		},
		row: {
			display: 'flex',
			flexDirection: 'row',
			borderTop: '1px solid #EEE',
			paddingTop: 8,
			paddingBottom: 8,
			fontSize: 10
		},
		header: {
			borderTop: 'none'
		},
		col1: {
			width: '20%',
			marginLeft: '2%'
		},
		col2: {
			width: '20%'
		},
		col3: {
			width: '20%'
		},
		col4: {
			width: '15%'
		},
		col5: {
			width: '15%'
		},
		col6: {
			width: '10%'
		}
	});

	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<View style={styles.headerSection}>
					<Text style={styles.mb5}>Applications for Program: {programTitle}</Text>
					<Text style={styles.mb2}>Filters Applied: {activeFilters.length === 0 && 'None'}</Text>
					{activeFilters.map((filter) => (
						<Text style={styles.filterText} key={filter.columnName}>
							{filter.columnName} {filter.operator} {filter.filterValue}
						</Text>
					))}
				</View>
				<View style={styles.table}>
					<View style={[styles.row, styles.header]}>
						<Text style={styles.col1}>Project Title</Text>
						<Text style={styles.col2}>Submitter</Text>
						<Text style={styles.col3}>Application Status</Text>
						<Text style={styles.col4}>Start Date</Text>
						<Text style={styles.col5}>End Date</Text>
						<Text style={styles.col6}>Budget</Text>
					</View>
					{tableData.map((row, index) => (
						<View style={styles.row} key={index}>
							<Text style={styles.col1}>{row.projectTitle}</Text>
							<Text style={styles.col2}>{row.submitter}</Text>
							<Text style={styles.col3}>{row.status}</Text>
							<Text style={styles.col4}>{row.startDate}</Text>
							<Text style={styles.col5}>{row.endDate}</Text>
							<Text style={styles.col6}>{row.budget}</Text>
						</View>
					))}
				</View>
			</Page>
		</Document>
	);
};

export default ApplicationsTemplate;

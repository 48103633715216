import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useGetIndividualApplicationQuery } from '../services/endpoints/programsEndpoints';

import { saveVariable } from '../components/BaseLayout/slice';

import { getEntityPrefix } from '../utils/utilFunctions';
import { getPortalURL, getLocalAuth } from '../utils/environmentUtils';

export const useGetAppProcessNav = (appID) => {
	const [searchParams] = useSearchParams();
	const dispatch = useDispatch();

	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const [navData, setNavData] = useState([]);
	const [activeStageComplete, setActiveStageComplete] = useState(false);
	const [budgetRequirementMet, setBudgetRequirementMet] = useState(true);
	const [activeStageCompletedForms, setActiveStageCompletedForms] = useState([]);
	const [allStagesSubmitted, setAllStagesSubmitted] = useState(false);

	const formType = searchParams.get('formtype') || '';
	const iFrame = searchParams.get('iFrame') || '';
	const uniqueID = appID?.split('-')?.[1];

	const { data, isLoading, refetch, isFetching, error } = useGetIndividualApplicationQuery(
		{
			uniqueID,
			apiToken,
			formType,
			iFrame
		},
		{ refetchOnMountOrArgChange: true }
	);

	const createNewExperienceNavData = (data) => {
		const app = error?.status === 404 ? {} : data;
		const applicationPrefix = getEntityPrefix({ type: 'application-process', isInstance: true });

		let allSubmitted = true;
		let hasPrevSubmissionForm = false;
		let navInfo = [
			{
				id: 'project team',
				title: 'Team Members',
				link: `${applicationPrefix}${app?.unique_identifier}/Project Team`,
				isLink: true,
				isTeam: true
			}
		];

		app?.all_stages?.every((stageInstance) => {
			const stagePrefix = getEntityPrefix({
				type:
					stageInstance?.stage?.stage_type === 'application'
						? 'submission-stage'
						: 'evaluation-stage',
				isInstance: true
			});

			if (
				stageInstance?.stage?.stage_type === 'application' &&
				stageInstance?.status !== 'Submitted'
			) {
				allSubmitted = false;
				hasPrevSubmissionForm = true;
			}

			let all_stages_submitted = null;
			if (stageInstance?.stage?.stage_type === 'evaluation') {
				all_stages_submitted = allSubmitted;
				allSubmitted = !hasPrevSubmissionForm ? true : allSubmitted;
				hasPrevSubmissionForm = false;
			}

			const isActiveStage =
				app?.form_stage_id?.unique_identifier === stageInstance?.unique_identifier;

			navInfo.push({
				id: `${stagePrefix}${stageInstance?.unique_identifier}`,
				title: `${stageInstance?.stage?.stage_name}`,
				link: `${applicationPrefix}${app?.unique_identifier}/${stagePrefix}${stageInstance?.unique_identifier}/details`,
				all_stages_submitted: all_stages_submitted,
				status: stageInstance?.status,
				is_current_stage: isActiveStage
			});

			stageInstance?.master_forms?.forEach((formInstance) => {
				const masterPrefix = getEntityPrefix({
					type: formInstance?.form_type === 'application' ? 'application-form' : 'review-form',
					isMaters: true
				});

				// Check Review Container Status
				const masterFormStatus = () => {
					let completed = 0;
					let not_started = 0;

					const filteredForms = stageInstance?.forms?.filter(
						(reviewForm) => reviewForm?.form?.form_id === formInstance?.form_id
					);

					filteredForms?.forEach((reviewForm) => {
						if (reviewForm?.status === 'Completed') {
							completed += 1;
						} else if (reviewForm?.status === 'Marked Complete') {
							completed += 1;
						} else if (reviewForm?.status === 'Not Started') {
							not_started += 1;
						}
					});

					if (filteredForms?.length === 0) {
						return 'Unassigned';
					} else if (filteredForms?.length === not_started) {
						return 'Not Started';
					} else if (filteredForms?.length === completed) {
						return 'Completed';
					} else {
						return stageInstance?.status;
					}
				};

				navInfo.push({
					id: `${masterPrefix}${formInstance?.unique_identifier}`,
					title: `${formInstance?.label}`,
					link: `${applicationPrefix}${app?.unique_identifier}/${stagePrefix}${stageInstance?.unique_identifier}/${masterPrefix}${formInstance?.unique_identifier}-group/details`,
					status: masterFormStatus(),
					isChild: true
				});
			});

			const completeFormIds = [];

			stageInstance?.forms?.forEach((formInstance) => {
				if (formInstance?.complete) {
					completeFormIds.push(formInstance?.unique_identifier);
				}

				const formPrefix = getEntityPrefix({
					type:
						formInstance?.form?.form_type === 'application' ? 'application-form' : 'review-form',
					isInstance: true
				});

				navInfo.push({
					id: `${formPrefix}${formInstance?.unique_identifier}`,
					title: `${formInstance?.form?.label}`,
					link: `${applicationPrefix}${app?.unique_identifier}/${stagePrefix}${stageInstance?.unique_identifier}/${formPrefix}${formInstance?.unique_identifier}/content`,
					status: formInstance?.complete ? 'Completed' : formInstance?.status,
					isChild: stageInstance?.master_forms?.length === 0,
					isGrandchild: stageInstance?.master_forms?.length > 0
				});
			});

			const isComplete =
				stageInstance?.status === 'Completed' || stageInstance?.status === 'Submitted';

			if (isActiveStage && !isComplete) {
				// Set flag that an unsubmitted stage can be submitted
				setActiveStageComplete(
					completeFormIds.length > 0 && completeFormIds.length === stageInstance?.forms?.length
				);
				setActiveStageCompletedForms(completeFormIds);
			}

			// stop adding stages if current is incomplete; returning false terminates iterable
			return isComplete;
		});

		if (app?.budgetID) {
			// Set flag that indicates budget requirement is met to prevent stage submit being blocked
			setBudgetRequirementMet(app?.isBudgetSet);

			navInfo?.push({
				title: 'Budget',
				link: `${getPortalURL()}/${
					app?.has_application_edit_permission ? '#/index/editBudget' : '#/peerBudget'
				}/${app?.budgetID}`,
				isLink: true,
				isBudget: true,
				isBudgetRequired: !!app?.budgetID,
				isBudgetSet: app?.isBudgetSet
			});
		}

		navInfo?.push({
			title: 'Goals',
			link: `${getPortalURL()}/${
				app?.has_application_edit_permission ? '#/index/submissionGoals' : '#/peerGoals'
			}/${app?.gn_external_applications}`,
			isLink: true,
			isGoals: true
		});

		// Check to ensure all applicant submissions are complete
		// This corrects an issue where the every method can't see beyond the first incomplete submissions it sees
		// And if that's an evaluation stage, then the Submit Application button doesn't enable/disable properly
		const submissions = app?.all_stages
			?.filter((stageInstance) => {
				return stageInstance?.stage?.stage_type === 'application';
			})
			.map((stageInstance) => {
				const isActiveStage =
					app?.form_stage_id?.unique_identifier === stageInstance?.unique_identifier;
				const isComplete =
					stageInstance?.status === 'Completed' || stageInstance?.status === 'Submitted';
				return {
					isActiveStage,
					isComplete
				};
			});

		if (submissions?.length > 0) {
			setAllStagesSubmitted(submissions.every((submission) => submission.isComplete));
		}

		return navInfo;
	};

	const createNavData = (data) => {
		const app = error?.status === 404 ? {} : data;
		const ApplicationPrefix = getEntityPrefix({ type: 'application-process', isInstance: true });

		let allSubmitted = true;
		let hasPrevSubmissionForm = false;
		let navInfo = [
			{
				title: `Application [${ApplicationPrefix}${app?.unique_identifier}]`,
				id: `${ApplicationPrefix}${app?.unique_identifier}`,
				link: `${ApplicationPrefix}${app?.unique_identifier}/details`,
				status: app?.status,
				is_current_stage: app?.form_stage_id === '',
				current_stage_id: app?.form_stage_id?.stage_id,
				children: app?.all_stages?.map((e) => {
					const StagePrefix = getEntityPrefix({
						type: e?.stage?.stage_type === 'application' ? 'submission-stage' : 'evaluation-stage',
						isInstance: true
					});

					if (e?.stage?.stage_type === 'application' && e?.status !== 'Submitted') {
						allSubmitted = false;
						hasPrevSubmissionForm = true;
					}

					let all_stages_submitted = null;
					if (e?.stage?.stage_type === 'evaluation') {
						all_stages_submitted = allSubmitted;
						allSubmitted = !hasPrevSubmissionForm ? true : allSubmitted;
						hasPrevSubmissionForm = false;
					}

					return {
						title: `${e?.stage?.stage_name}`,
						stage_id: `${StagePrefix}${e?.unique_identifier}`,
						link: `${ApplicationPrefix}${app?.unique_identifier}/${StagePrefix}${e?.unique_identifier}/details`,
						all_stages_submitted: all_stages_submitted,
						status: e?.status,
						is_current_stage: app?.form_stage_id?.unique_identifier === e?.unique_identifier,
						forms:
							e?.master_forms?.length > 0
								? e?.master_forms?.map((f) => {
										const MasterPrefix = getEntityPrefix({
											type:
												f?.form?.form_type === 'application' ? 'application-form' : 'review-form',
											isMaters: true
										});
										const review_content = e?.forms?.filter((i) => i?.form?.form_id === f?.form_id);

										// Check Review Container Status
										const masterFormStatus = () => {
											let completed = 0;
											let not_started = 0;

											const filteredForms = e?.forms?.filter(
												(reviewForm) => reviewForm?.form?.form_id === f?.form_id
											);

											filteredForms?.forEach((reviewForm) => {
												if (reviewForm?.status === 'Completed') {
													completed += 1;
												} else if (reviewForm?.status === 'Not Started') {
													not_started += 1;
												}
											});

											if (filteredForms?.length === 0) {
												return 'Unassigned';
											} else if (filteredForms?.length === not_started) {
												return 'Not Started';
											} else if (filteredForms?.length === completed) {
												return 'Completed';
											} else {
												return e?.status;
											}
										};

										return {
											id: `${MasterPrefix}${f?.unique_identifier}`,
											title: `${f?.label}`,
											link: `${ApplicationPrefix}${app?.unique_identifier}/${StagePrefix}${e?.unique_identifier}/${MasterPrefix}${f?.unique_identifier}-group/details`,
											status: masterFormStatus(),
											review: review_content?.map((i) => {
												const FormPrefix = getEntityPrefix({
													type:
														i?.form?.form_type === 'application'
															? 'application-form'
															: 'review-form',
													isInstance: true
												});

												const reviewerName = `${i?.reviewer?.first_name || ''} ${
													i?.reviewer?.last_name || ''
												}`;

												return {
													id: `${FormPrefix}${i?.unique_identifier}`,
													title: `Review by ${reviewerName}`,
													all_stages_submitted: all_stages_submitted,
													link: `${ApplicationPrefix}${app?.unique_identifier}/${StagePrefix}${e?.unique_identifier}/${MasterPrefix}${f?.unique_identifier}-group/${FormPrefix}${i?.unique_identifier}/content`,
													status: i?.status
												};
											})
										};
								  })
								: e?.forms?.map((f) => {
										const FormPrefix = getEntityPrefix({
											type:
												f?.form?.form_type === 'application' ? 'application-form' : 'review-form',
											isInstance: true
										});

										return {
											id: `${FormPrefix}${f?.unique_identifier}`,
											title: `${f?.form?.label}`,
											link: `${ApplicationPrefix}${app?.unique_identifier}/${StagePrefix}${e?.unique_identifier}/${FormPrefix}${f?.unique_identifier}/content`,
											status: f?.status
										};
								  })
					};
				})
			},
			{ title: 'Project Team', link: `${ApplicationPrefix}${app?.unique_identifier}/Project Team` }
		];

		if (app?.budgetID) {
			navInfo?.push({
				title: 'Budget',
				link: `${getPortalURL()}/${
					app?.has_application_edit_permission ? '#/index/editBudget' : '#/peerBudget'
				}/${app?.budgetID}`
			});
		}

		navInfo?.push({
			title: 'Goals',
			link: `${getPortalURL()}/${
				app?.has_application_edit_permission ? '#/index/submissionGoals' : '#/peerGoals'
			}/${app?.gn_external_applications}`
		});

		return navInfo;
	};

	const isPortal = iFrame === 'true';
	const isReviewer = formType === 'review';
	const isTeamMember = data?.is_submitter || data?.is_part_of_team;
	const isNewExperience = isPortal && !isReviewer && isTeamMember;

	useEffect(() => {
		if (isNewExperience) {
			setNavData(createNewExperienceNavData(data));
		} else {
			setNavData(createNavData(data));
		}
	}, [data, error]);

	useEffect(() => {
		let all_stages_and_forms = [];
		data?.all_stages?.forEach((e) => {
			const StagePrefix = getEntityPrefix({
				type: e?.stage?.stage_type === 'application' ? 'submission-stage' : 'evaluation-stage',
				isInstance: true
			});
			all_stages_and_forms?.push({
				name: e?.stage?.stage_name,
				id: `${StagePrefix}${e?.unique_identifier}`
			});

			e?.forms?.forEach((f) => {
				const FormPrefix = getEntityPrefix({
					type: f?.form?.form_type === 'application' ? 'application-form' : 'review-form',
					isInstance: true
				});
				const reviewer = f.reviewer;
				all_stages_and_forms?.push({
					name: f?.form?.label,
					id: `${FormPrefix}${f?.unique_identifier}`,
					reviewer: reviewer.first_name && `${reviewer?.first_name} ${reviewer?.last_name}`
				});
			});

			e?.master_forms?.forEach((f) => {
				const FormPrefix = getEntityPrefix({
					type: f?.form?.form_type === 'application' ? 'application-form' : 'review-form',
					isMaters: true
				});
				all_stages_and_forms?.push({
					name: f?.label,
					id: `${FormPrefix}${f?.unique_identifier}`
				});
			});
		});

		dispatch(saveVariable(['current_application', all_stages_and_forms]));

		const isAppCreator = data?.is_submitter;
		dispatch(saveVariable(['is_current_app_creator', isAppCreator]));

		const isTeamMember = data?.is_part_of_team;
		dispatch(saveVariable(['is_part_of_team'], isTeamMember));
	}, [data]);

	useEffect(() => {
		refetch({ force: true, skip: false });
	}, [searchParams]);

	return {
		data: navData,
		projectTitle: data?.project_title,
		isLoading: isLoading,
		activeStageComplete,
		activeStageCompletedForms,
		budgetRequirementMet,
		refetch,
		isFetching,
		isNewExperience,
		allStagesSubmitted
	};
};

import { useState, useEffect } from 'react';
import StyledWrapper from './style';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/Warning';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { MARK_APPLICATION_AS_READY_TO_PUBLISH } from '../../constants/appProcessEditWarningMessage';
export default function SaveDialog({
	title,
	isOpen,
	handleClose,
	handleSuccessClose,
	handleSave,
	isLoading,
	isSuccess,
	isError,
	isReadyToPublish,
	isYesNo
}) {
	const [open, setOpen] = useState(isOpen);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const successMessage = isReadyToPublish ? `Marked for Publication` : `Saved Successfully`;
	const errorMessage = isReadyToPublish ? `Publishing Failed` : `Saving Failed!`;

	const displayTitle = isReadyToPublish || isYesNo ? title : `Saving ${title}`;
	const confirmationMessage = isReadyToPublish
		? `Are you sure you want to continue? You will still be able to make changes to the solicitation after publishing, but you will no longer be able to mark fields as required.`
		: `Are you sure?`;
	const buttonText = isReadyToPublish ? `Ready to Publish` : isYesNo ? `Yes` : `Save`;
	useEffect(() => {
		setOpen(isOpen);
	}, [isOpen]);

	return (
		<div>
			<StyledWrapper.CustomDialog
				disableEscapeKeyDown
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
			>
				{isLoading ? (
					<Box sx={{ display: 'flex' }}>
						<CircularProgress />
					</Box>
				) : isSuccess ? (
					<>
						<StyledWrapper.CustomDialogTitle id="responsive-dialog-title">
							<CheckCircleOutlineIcon color={'success'} />
							{successMessage}
						</StyledWrapper.CustomDialogTitle>
						<StyledWrapper.CustomDialogContent>
							<DialogContentText sx={{ fontWeight: '500' }}>
								{MARK_APPLICATION_AS_READY_TO_PUBLISH}
							</DialogContentText>
						</StyledWrapper.CustomDialogContent>
						<Button
							size="large"
							variant="contained"
							autoFocus
							onClick={handleSuccessClose || handleClose}
							sx={{ maxWidth: '70px', margin: 'auto' }}
						>
							Close
						</Button>
					</>
				) : isError ? (
					<>
						<StyledWrapper.CustomDialogTitle id="responsive-dialog-title">
							<ErrorOutlineIcon color={'error'} />
							{errorMessage}
						</StyledWrapper.CustomDialogTitle>
						<Button
							size="large"
							variant="contained"
							autoFocus
							onClick={handleClose}
							sx={{ maxWidth: '70px', margin: 'auto' }}
						>
							Close
						</Button>
					</>
				) : (
					<>
						<StyledWrapper.CustomDialogTitle id="responsive-dialog-title">
							<WarningIcon color={'warning'} />
							{displayTitle}
						</StyledWrapper.CustomDialogTitle>
						<StyledWrapper.CustomDialogContent>
							<DialogContentText sx={{ fontWeight: '500' }}>
								{confirmationMessage}
							</DialogContentText>
						</StyledWrapper.CustomDialogContent>
						<DialogActions style={{ justifyContent: 'center' }}>
							<Button
								size="large"
								variant="outlined"
								color="warning"
								onClick={handleSave}
								autoFocus
							>
								{buttonText}
							</Button>
							<Button size="large" variant="contained" autoFocus onClick={handleClose}>
								Cancel
							</Button>
						</DialogActions>
					</>
				)}
			</StyledWrapper.CustomDialog>
		</div>
	);
}

import React from 'react';
import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML } from '../utils';

import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { getElementIcon } from '../utils';
import StyledWrapper from './style';

export const MUIPhoneNumber = React.forwardRef((props, ref) => {
	const { name, defaultValue, disabled, isFormBuilder } = props;

	const parsedLabel = React.useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = React.useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);

	const styleForNotFormBuilder = !isFormBuilder
		? { width: '100%', marginTop: '16px' }
		: { width: '100%' };

	return (
		<StyledWrapper.FormControlNoBorder sx={{ width: '100%' }}>
			<StyledWrapper.CustomLabel>
				<>
					{isFormBuilder && getElementIcon(props.data?.key)}
					{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
					{isFormBuilder && (
						<div>
							{props.data?.unique_identifier
								? `(ff-${props.data?.unique_identifier})`
								: `(tmp-${props.data?.temp_order})`}
						</div>
					)}
					{props?.data?.required && <StyledWrapper.AsteriskSpan> *</StyledWrapper.AsteriskSpan>}
				</>
			</StyledWrapper.CustomLabel>

			<TextField
				error={props?.data?.error}
				required={props?.data?.required}
				id="outlined-error-helper-text"
				name={name}
				inputRef={ref}
				disabled={disabled}
				defaultValue={defaultValue}
				style={styleForNotFormBuilder}
				type="string"
				InputLabelProps={{ shrink: isFormBuilder || undefined }}
			/>
			<FormHelperText error={props?.data?.error}>
				{props?.data?.error
					? props?.data?.error?.error
					: parsedHelperText?.[0]
					? parsedHelperText
					: props?.data?.help_message}
			</FormHelperText>
		</StyledWrapper.FormControlNoBorder>
	);
});
MUIPhoneNumber.displayName = 'MUI_PhoneNumber';
Registry.register('MUI_PhoneNumber', MUIPhoneNumber);

import styled from 'styled-components';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

const CustomDialog = styled(Dialog)`
	& .MuiDialog-paper {
		padding: 30px;
	}
`;

const CustomDialogTitle = styled(DialogTitle)`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 15px;
`;

const CustomDialogContent = styled(DialogContent)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	text-align: center;
`;

export default {
	CustomDialog,
	CustomDialogTitle,
	CustomDialogContent
};

export const STAGE_PROCESS = 'process';
export const STAGE_APPLICATION = 'application';
export const STAGE_EVALUATION = 'evaluation';
export const STAGE_CREATION_PARAM = 'stageCreation';

export const APP_TYPE_APPLICATION_PROCESS = 'APPLICATION PROCESS';
export const APP_TYPE_SUBMISSION_STAGE = 'Submission Stage';
export const APP_TYPE_EVALUATION_STAGE = 'Evaluation Stage';

export type AppType =
	| typeof APP_TYPE_APPLICATION_PROCESS
	| typeof APP_TYPE_SUBMISSION_STAGE
	| typeof APP_TYPE_EVALUATION_STAGE;
export type StageCreation = typeof STAGE_PROCESS | typeof STAGE_APPLICATION;
